import axios from 'axios'

const API_URL = '/api/users/'

//Add new user
const createUser = async (userData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.post(
        API_URL + 'users/',
        userData,
        headersConfig
    )
    return response.data
}

const updateUser = async (userData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.put(
        API_URL + userData.userID,
        userData,
        headersConfig
    )

    return response.data
}

//Get Users
const getUsers = async (userCreds) => {
    try {
        const headersConfig = {
            headers: {
                Authorization: `Bearer ${userCreds.token}`,
            },
        }

        const response = await axios.get(API_URL, headersConfig, userCreds)

        console.log(
            '*************** return user response **********************'
        )

        return response.data
    } catch (error) {
        console.log(
            'usersService getUsers response: ',
            error.response,
            error.response.status
        )
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback

        if (error.response.status === 401) {
            throw new Error('User unauthorized. Please log in.')
        }

        throw new Error('usersService(getUsers): ' + error.response.data)
    }
}

//Delete User
//Do not permanently delete user just flag as suspended
const deleteUser = async (userID, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.delete(API_URL + userID, headersConfig)

    return response.data
}

const usersService = {
    createUser,
    getUsers,
    deleteUser,
    updateUser,
}

export default usersService
