import axios from 'axios'

const API_URL = '/api/customers/locations/'

//Add new customer
const createCustomerLoc = async (custlocData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    console.log('Service New custloc: ', custlocData, API_URL)
    const response = await axios.post(API_URL, custlocData, headersConfig)
    return response.data
}

const updateCustomerLoc = async (custlocData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    //console.log('Call put server update cust loc', custlocData)

    const response = await axios.put(
        API_URL + custlocData.custID,
        custlocData,
        headersConfig
    )
    return response.data
}

//Get customers
const getCustomersLoc = async (token) => {
    try {
        const headersConfig = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }

        const response = await axios.get(API_URL, headersConfig)
        //console.log('getcustloccusts', response.data)
        return response.data
    } catch (error) {
        console.log('custlocService getCustomersLoc response: ', error.response)

        if (error.response.status === 401) {
            throw new Error(error.response.statusText + ' Please Log In')
        }

        throw new Error(
            'custlocService(getCustomersLoc): ' + error.response.data.message
        )
    }
}

//Delete customer
const deleteCustomerLoc = async (custLocID, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    //console.log('Delete Service cust loc custlocid: ', custLocID)
    const response = await axios.delete(API_URL + custLocID, headersConfig)

    return response.data
}

const custlocService = {
    createCustomerLoc,
    getCustomersLoc,
    deleteCustomerLoc,
    updateCustomerLoc,
}

export default custlocService
