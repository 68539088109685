import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { register, reset } from '../features/auth/authSlice'
import { VaildateForm } from '../features/globalFunctions'

const Register = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        password2: '',
    })

    const { name, email, password, password2 } = formData

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user, isError, isSuccess, message } = useSelector(
        (state) => state.auth
    )

    useEffect(() => {
        console.log('Register useEffect Start')
        if (isError) {
            console.log('Register USERS useEffect error: ', message)
            navigate('/')
            toast.error(message)
            return
        }

        console.log('Register useEffect after error check')

        if (isSuccess || user) {
            console.log('Register useEffect isSuccess || user ')
            navigate('/')
        }

        console.log('Register useEffect after success and user check')

        dispatch(reset())
    }, [user, isError, isSuccess, message, navigate, dispatch])

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (password !== password2) {
            toast.error('Passwords do not match')
        } else {
            const userData = {
                name,
                email,
                password,
            }

            //Sends userData to authSlice -> import { register, reset } from '../features/auth/authSlice'
            dispatch(register(userData))
        }
    }

    //#region Edit User Form Validation
    const [boolRegisterUserFormValidated, setRegisterUserFormValidated] =
        useState(false)

    const valUserNameRef = useRef()
    const valUserEmailRef = useRef()
    const valUserPasswordRef = useRef()
    const valUserConfirmPasswordRef = useRef()

    const vaildateRegisterNewUserForm = (clicked = false) => {
        setRegisterUserFormValidated(
            VaildateForm(
                [
                    {
                        ref: valUserNameRef,
                        text: 'No Special Characters',
                        form: 'Register New Fran valUserNameRef',
                    },
                    {
                        ref: valUserEmailRef,
                        text: 'Enter Valid Email',
                        form: 'Register New Fran valUserEmailRef',
                    },
                    {
                        ref: valUserPasswordRef,
                        text: 'Password required',
                        form: 'Register New Fran valUserPasswordRef',
                    },
                    {
                        ref: valUserConfirmPasswordRef,
                        text: 'Comfirm Password required',
                        form: 'New User valUserConfirmPasswordRef',
                    },
                ],
                clicked
            )
        )
    }

    // useEffect(() => {
    //     //REdraw on validation change
    //     vaildateServiceForm()
    //     //Do validation on ROCombobox changes Service Rep ID
    // }, [txtServiceRepID])
    //#endregion

    return (
        <>
            <div className='loginContainer content border border-primary bg-light mt-3'>
                <section className='heading'>
                    <h1>Register</h1>
                    <p>Please create a account</p>
                </section>

                <section className='form'>
                    <form onSubmit={onSubmit}>
                        <div className='form-group'>
                            <input
                                type='text'
                                className='form-control'
                                id='name'
                                name='name'
                                value={name}
                                placeholder='Enter your name'
                                onChange={(e) => {
                                    onChange(e)
                                    vaildateRegisterNewUserForm()
                                }}
                                ref={valUserNameRef}
                                title='User Name'
                                pattern='[a-zA-Z0-9@ (),.!&/\\\-_]+'
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <input
                                type='email'
                                className='form-control'
                                id='email'
                                name='email'
                                value={email}
                                placeholder='Enter your email'
                                onChange={(e) => {
                                    onChange(e)
                                    vaildateRegisterNewUserForm()
                                }}
                                ref={valUserEmailRef}
                                title='User Email'
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <input
                                type='password'
                                className='form-control'
                                id='password'
                                name='password'
                                value={password}
                                placeholder='Enter your password'
                                onChange={(e) => {
                                    onChange(e)
                                    vaildateRegisterNewUserForm()
                                }}
                                ref={valUserPasswordRef}
                                title='User Password'
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <input
                                type='password'
                                className='form-control'
                                id='password2'
                                name='password2'
                                value={password2}
                                placeholder='Confirm password'
                                onChange={(e) => {
                                    onChange(e)
                                    vaildateRegisterNewUserForm()
                                }}
                                ref={valUserConfirmPasswordRef}
                                title='User Confirm Password'
                                required
                            />
                        </div>
                        <div className='form-group'>
                            {boolRegisterUserFormValidated ? (
                                <button
                                    type='submit'
                                    className='btn btn-success btn-block'
                                >
                                    Submit
                                </button>
                            ) : (
                                <button
                                    type='button'
                                    className='btn btn-danger btn-block'
                                    onClick={() =>
                                        vaildateRegisterNewUserForm(true)
                                    }
                                >
                                    Submit
                                </button>
                            )}
                        </div>
                    </form>
                </section>
            </div>
        </>
    )
}

export default Register
