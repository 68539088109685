import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import custloccontactService from './custloccontactsService'

const initialState = {
    custloccontacts: [],
    isErrorLocContacts: false,
    isSuccessLocContacts: false,
    isLoadingLocContacts: false,
    custloccontmessage: '',
}

// Create New Cust Contact
export const createCustomerLocContact = createAsyncThunk(
    'custloccont/create',
    async (custloccontData, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token

            console.log(
                'custloccontactSlice(createCustomerLocContact):',
                custloccontData
            )

            const retVal = await custloccontactService.createCustomerLocContact(
                custloccontData,
                token
            )

            // console.log(
            //     'custloccontactSlice(createCustomerLocContact) retVal:',
            //     retVal
            // )

            return retVal
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            // console.log(
            //     'custloccontactSlice(createCustomerLocContact) Error:',
            //     message
            // )
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update Cust Contact
export const updateCustomerLocContact = createAsyncThunk(
    'custloccont/update',
    async (custloccontData, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token
            // console.log(
            //     'custloccontSlice: custloccont / update: ',
            //     custloccontData
            // )
            return await custloccontactService.updateCustomerLocContact(
                custloccontData,
                token
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Get customer contacts
export const getCustomerLocContacts = createAsyncThunk(
    'custloccont/getAll',
    async (_, thunkAPI) => {
        try {
            let token = ''
            if (thunkAPI.getState() != null) {
                token = thunkAPI.getState().auth.user.token
            }
            if (token) {
                //console.log('Send thunkAPI token to server')
                return await custloccontactService.getCustomerLocContacts(token)
            }
            return thunkAPI.rejectWithValue('No user token found')
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delete customer contact
export const deleteCustomerLocContact = createAsyncThunk(
    'custloccont/delete',
    async (id, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token
            return await custloccontactService.deleteCustomerLocContact(
                id,
                token
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const custloccontactSlice = createSlice({
    name: 'custloccont',
    initialState,
    reducers: {
        resetLocContacts: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(createCustomerLocContact.pending, (state) => {
                state.isLoadingLocContacts = true
                state.isSuccessLocContacts = false
                state.isErrorLocContacts = false
            })
            .addCase(createCustomerLocContact.fulfilled, (state, action) => {
                state.isLoadingLocContacts = false
                state.isSuccessLocContacts = true
                state.isErrorLocContacts = false
                //This is what redux helps to do. Change immutable values
                //console.log('createCustomerLocContact', action.payload)
                state.custloccontacts.push(action.payload)
            })
            .addCase(createCustomerLocContact.rejected, (state, action) => {
                state.isLoadingLocContacts = false
                state.isErrorLocContacts = true
                //This is what redux helps to do. Change immutable values
                state.custloccontmessage = action.payload
            })
            .addCase(updateCustomerLocContact.pending, (state) => {
                state.isLoadingLocContacts = true
                state.isSuccessLocContacts = false
                state.isErrorLocContacts = false
            })
            .addCase(updateCustomerLocContact.fulfilled, (state, action) => {
                state.isLoadingLocContacts = false
                state.isSuccessLocContacts = true
                state.isErrorLocContacts = false
                //This is what redux helps to do. Change immutable values
                state.custloccontacts = state.custloccontacts.map(
                    (custloccont) => {
                        if (
                            custloccont.CustLocID === action.payload.CustLocID
                        ) {
                            if (
                                custloccont.CustLocContactID ===
                                action.payload.CustLocContactID
                            ) {
                                return action.payload
                            } else {
                                if (
                                    action.payload.CustLocContactPrimary !== 1
                                ) {
                                    return {
                                        ...custloccont,
                                    }
                                } else {
                                    return {
                                        ...custloccont,
                                        CustLocContactPrimary: 0,
                                    }
                                }
                            }
                        } else {
                            return custloccont
                        }
                    }
                )
            })
            .addCase(updateCustomerLocContact.rejected, (state, action) => {
                state.isLoadingLocContacts = false
                state.isErrorLocContacts = true
                //This is what redux helps to do. Change immutable values
                state.custloccontmessage = action.payload
            })
            .addCase(getCustomerLocContacts.pending, (state) => {
                state.isLoadingLocContacts = true
                state.isErrorLocContacts = false
            })
            .addCase(getCustomerLocContacts.fulfilled, (state, action) => {
                state.isLoadingLocContacts = false
                state.isSuccessLocContacts = true
                state.isErrorLocContacts = false
                //This is what redux helps to do. Change immutable values
                if (!action.payload) {
                    // console.log(
                    //     'getCustomersLocContacts(custloccontactSlice) action payload null!!!'
                    // )
                    //Do nothing stay with zero array
                } else {
                    state.custloccontacts = action.payload
                }
            })
            .addCase(getCustomerLocContacts.rejected, (state, action) => {
                state.isLoadingLocContacts = false
                state.isErrorLocContacts = true
                //This is what redux helps to do. Change immutable values

                state.custcontmessage = action.payload
            })
            .addCase(deleteCustomerLocContact.pending, (state) => {
                //console.log('deleteCustomerLocContact PENDING')
                state.isLoadingLocContacts = true
                state.isSuccessLocContacts = false
                state.isErrorLocContacts = false
            })
            .addCase(deleteCustomerLocContact.fulfilled, (state, action) => {
                // console.log(
                //     'deleteCustomerLocContact FULFILLED id: ',
                //     action.payload
                // )
                state.isLoadingContacts = false
                state.isSuccessContacts = true
                state.isErrorLocContacts = false
                //Need to alter state and trigger to remove from UI
                state.custloccontacts = state.custloccontacts.filter(
                    (custloccont) =>
                        custloccont.CustLocContactID !== action.payload.id
                )
            })
            .addCase(deleteCustomerLocContact.rejected, (state, action) => {
                //console.log('deleteCustomerLocContact REJECTED')
                state.isLoadingLocContacts = false
                state.isErrorLocContacts = true
                //This is what redux helps to do. Change immutable values
                state.custloccontmessage = action.payload
            })
    },
})

export const { resetLocContacts } = custloccontactSlice.actions
export default custloccontactSlice.reducer
