import axios from 'axios'

const API_URL = '/api/customers/locations/services/'

//Add new service
const createService = async (serviceData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.post(API_URL, serviceData, headersConfig)
    console.log('serviceService(createService)  response: ', response)
    return response.data
    //  catch (error) {
    //     console.log('serviceService createService response: ', error.response)
    //     //This seems like more verbose error feedback
    //     //This seems like more verbose error feedback
    //     //This seems like more verbose error feedback
    //     //This seems like more verbose error feedback
    //     //This seems like more verbose error feedback
    //     throw new Error('serviceService(createService): ' + error.response.data)
    // }
}

const updateService = async (serviceData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    console.log('updateService(servicesServices) serviceData: ', serviceData)

    const response = await axios.put(
        API_URL + serviceData.serviceID,
        serviceData,
        headersConfig
    )

    return response.data
}

//Get Services
const getServices = async (userCreds) => {
    try {
        const headersConfig = {
            headers: {
                Authorization: `Bearer ${userCreds.token}`,
            },
        }

        const response = await axios.get(API_URL, headersConfig, userCreds)

        return response.data
    } catch (error) {
        console.log('servicesService getServices response: ', error.response)

        if (error.response.status === 401) {
            throw new Error(error.response.statusText + ' Please Log In')
        }

        throw new Error(
            'servicesService(getServices): ' + error.response.data.message
        )
    }
}

//Delete Service
const deleteService = async (serviceID, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.delete(API_URL + serviceID, headersConfig)

    return response.data
}

const servicesService = {
    createService,
    getServices,
    deleteService,
    updateService,
}

export default servicesService
