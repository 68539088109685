import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, reset } from '../features/auth/authSlice'
import { VaildateForm } from '../features/globalFunctions'

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })

    const { email, password } = formData

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user, isError, isSuccess, message } = useSelector(
        (state) => state.auth
    )

    useEffect(() => {
        console.log('Logged in user: ', user)

        if (isError) {
            console.log('Error Logged in user: ', message)
            toast.error(message)
            return
        }

        if (isSuccess || user) {
            console.log('Logged in user to dashboard: ', user)
            navigate('/dashboard')
        }

        dispatch(reset())
    }, [user, isError]) //[user, isError, isSuccess, message, navigate, dispatch])

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const userLoginData = {
            email,
            password,
        }

        dispatch(login(userLoginData))
    }

    //#region Edit User Form Validation
    const [boolLoginUserFormValidated, setLoginUserFormValidated] =
        useState(false)

    const valUserEmailRef = useRef()
    const valUserPasswordRef = useRef()

    const vaildateLoginUserForm = (clicked = false) => {
        setLoginUserFormValidated(
            VaildateForm(
                [
                    {
                        ref: valUserEmailRef,
                        text: 'Enter Valid Email',
                        form: 'Register New Fran valUserEmailRef',
                    },
                    {
                        ref: valUserPasswordRef,
                        text: 'Password required',
                        form: 'Register New Fran valUserPasswordRef',
                    },
                ],
                clicked
            )
        )
    }

    //#endregion

    return (
        <>
            <div className='loginContainer content border border-primary bg-light mt-3'>
                <section className='heading'>
                    <h1>Login</h1>
                    <p>Login</p>
                </section>
                <section className='form'>
                    <form onSubmit={onSubmit}>
                        <div className='form-group'>
                            <input
                                type='email'
                                className='form-control'
                                id='email'
                                name='email'
                                value={email}
                                placeholder='Enter your email'
                                onChange={(e) => {
                                    onChange(e)
                                    vaildateLoginUserForm()
                                }}
                                ref={valUserEmailRef}
                                title='User Email'
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <input
                                type='password'
                                className='form-control'
                                id='password'
                                name='password'
                                value={password}
                                placeholder='Enter your password'
                                onChange={(e) => {
                                    onChange(e)
                                    vaildateLoginUserForm()
                                }}
                                ref={valUserPasswordRef}
                                title='User Password'
                                required
                            />
                        </div>
                        <div className='form-group'>
                            {boolLoginUserFormValidated ? (
                                <button
                                    type='submit'
                                    className='btn btn-success btn-block'
                                >
                                    Submit
                                </button>
                            ) : (
                                <button
                                    type='button'
                                    className='btn btn-danger btn-block'
                                    onClick={() => vaildateLoginUserForm(true)}
                                >
                                    Submit
                                </button>
                            )}
                        </div>
                    </form>
                </section>
            </div>
        </>
    )
}

export default Login
