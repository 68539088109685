import iconPath from '../features/iconsLib'
import myUUid from '../features/myUUid'
import ROIcon from './ROIcon'

const icons = []

for (const icon in iconPath) {
    icons.push(icon)
}

//Component to show all icons
const ShowROIcons = () => {
    return icons.map((icon) => {
        return (
            <i
                key={myUUid()}
                style={{
                    float: 'left',
                }}
                className='mt-5 mx-2'
            >
                <ROIcon
                    icon={icon}
                    size={64}
                    className='icoSVG'
                    tooltip={icon}
                    authlvl={99}
                />
                <br />
                <small className='custChatDateTime'>{icon}</small>
            </i>
        )
    })
}

export default ShowROIcons
