import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { toast } from 'react-toastify'
import { getEquipment, resetequip } from '../features/equip/equipSlice'
import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'
import ROComboBox from '../components/ROComboBox'
import { VaildateForm } from '../features/globalFunctions'

//Import Customer Redux State/Global Var and Axiox database calls
import { createEquipment } from '../features/equip/equipSlice.js'
import ContentBox from '../components/ContentBox'
import EquipItem from '../components/EquipItem'

const Equipment = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user, message, isError } = useSelector((state) => state.auth)

    console.log('*** Equip Main Draw ***')

    //New Equipment
    const [showNewEquip, setShowNewEquip] = useState(false)

    const [txtEquipName, setTxtEquipName] = useState('')
    const [txtEquipCode, setTxtEquipCode] = useState('')
    const [txtEquipSerialNr, setTxtEquipSerialNr] = useState('')
    const [txtEquipPrice, setTxtEquipPrice] = useState('')
    const [txtEquipCategory, setTxtEquipCategory] = useState('')

    //Add New Equipment
    const AddNewEquipment = () => {
        const newEquip = {
            franID: user.franid,
            equipcode: txtEquipCode,
            equipname: txtEquipName,
            equipserialnr: txtEquipSerialNr,
            equiplastprice: txtEquipPrice,
            equipcategory: txtEquipCategory,
            equipimageurl: '',
            equipnotes: '',
            equipflags: '',
        }

        setTxtEquipCode('')
        setTxtEquipName('')
        setTxtEquipSerialNr('')
        setTxtEquipPrice('')
        setTxtEquipCategory('')
        setShowNewEquip(false)

        dispatch(createEquipment(newEquip))
    }

    //Get State equipment from redux
    const { equipment, isEquipError, isEquipSuccess, equipmessage } =
        useSelector((state) => state.equipment)

    //Categories
    let arrCategories = []
    if (equipment) {
        arrCategories = new Set(equipment.map((equip) => equip.EquipCategory))
        console.log('setCategories: ', arrCategories)
        arrCategories = Array.from(arrCategories)
        arrCategories = arrCategories.map((item, i) => {
            return { Text: item }
        })
        console.log('arrCategories: ', arrCategories)
    }

    console.log('Equipment before useEffect Check')

    useEffect(() => {
        console.log('Equipment useEffect Check1')

        if (isError) {
            console.log('Equipment USERS useEffect error: ', message)
            toast.error(message)
            navigate('/')
            return
        }

        if (isEquipError) {
            console.log('Equipment useEffect error: ', equipmessage)
            toast.error(equipmessage)
            navigate('/')
            return
        }
        console.log('Equipment useEffect Check2')

        if (!user || !user._id || !user.token) {
            dispatch(logout())
            dispatch(reset())
            navigate('/')
            return
        }
        console.log('Equipment useEffect Check3')

        try {
            console.log('Get All Data from servers')
            dispatch(getEquipment())
        } catch (error) {
            console.log('Equipment.jsx Dispatch(all) Error: ', error)
            toast.error(error)
            return () => dispatch(reset())
        }

        console.log('Equipment useEffect Check4')

        return () => dispatch(reset())
    }, [navigate, user, dispatch, isError, isEquipError])

    console.log('Equipment after useEffect Check')

    //Collapse All
    //Just a state variable to call to trigger a rerender for collapse change
    const [getCollapseAll, setCollapseAll] = useState(false)

    const CollapseAll = () => {
        setCollapseAll(true)
    }

    useEffect(() => {
        console.log('Use Effect Collapse All')
        if (getCollapseAll) {
            setCollapseAll(false)
        }
    }, [getCollapseAll])

    //Search Equipment
    const [equipmentSearchData, setEquipmentSearchData] = useState([])
    const [searchResults, setSearchResults] = useState([])
    let [searchString, setSearchString] = useState('')
    const [searchFound, setSearchFound] = useState(false)

    const SearchEquipment = (_searchstr) => {
        setSearchString(_searchstr)

        //console.log('search : ', _searchstr)

        if (_searchstr === '') {
            setSearchResults(equipment)
            setSearchFound(true)
        } else {
            _searchstr = _searchstr.toLowerCase()

            let tempResults = []

            if (equipment) {
                tempResults = equipment.filter((item) => {
                    return (
                        String(item.EquipCode)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        String(item.EquipName)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        String(item.EquipLastPrice)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        String(item.EquipCategory)
                            .toLowerCase()
                            .includes(_searchstr)
                    )
                })
            }

            if (tempResults === null || tempResults.length === 0) {
                setSearchResults(equipment)
                setSearchFound(false)
            } else {
                setSearchResults(tempResults)
                setSearchFound(true)
            }
        }
    }

    //Only update/redraw if search results changed. Or else it will redraw on each letter type even if results didn't change
    //used to check if customers is populated and to only search once on init
    if (
        equipment != null &&
        equipment.length > 0 &&
        JSON.stringify(equipmentSearchData) !== JSON.stringify(equipment)
    ) {
        //console.log('more search')
        setEquipmentSearchData(equipment)
        SearchEquipment(searchString)
    }

    //#region New Equipment Form Validation
    const [boolEquipmentFormValidated, setEquipmentFormValidated] =
        useState(false)

    const valEquipCodeRef = useRef()
    const valEquipNameRef = useRef()
    const valEquipPriceRef = useRef()
    const valEquipCategoryRef = useRef()

    const vaildateEquipmentNewEquipForm = (clicked = false) => {
        if (!showNewEquip) return

        if (showNewEquip) {
            setEquipmentFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valEquipCodeRef,
                            text: 'No Special Characters',
                            form: 'New Equipment valEquipCodeRef',
                        },
                        {
                            ref: valEquipNameRef,
                            text: 'No Special Characters',
                            form: 'New Equipment valEquipNameRef',
                        },
                        {
                            ref: valEquipPriceRef,
                            text: 'Enter valid price',
                            form: 'New Equipment valEquipPriceRef',
                        },
                        {
                            ref: valEquipCategoryRef,
                            text: 'Equiment Category Required',
                            form: 'New Equipment valEquipCategoryRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    // useEffect(() => {
    //     //REdraw on validation change
    //     vaildateServiceForm()
    //     //Do validation on ROCombobox changes Service Rep ID
    // }, [txtServiceRepID])
    //#endregion

    return (
        <>
            <section className='pageHead container clearfix'>
                <div className='pageFunctions row header sticky-top py-3'>
                    {/* <h6>Welcome {user && user.name}</h6> */}
                    <div className='col-4'>
                        <h4>
                            Your Equipment &nbsp;
                            {/* New Customer */}
                            <ROIcon
                                icon={showNewEquip ? 'ROicoClose' : 'ROicoPlus'}
                                size={24}
                                className='icoSVG'
                                textbefore={showNewEquip ? 'Cancel' : ' '}
                                tooltip={
                                    showNewEquip
                                        ? 'Cancel'
                                        : 'Add New Equipment'
                                }
                                onClickCallback={() =>
                                    setShowNewEquip((prev) => !prev)
                                }
                            />
                        </h4>
                    </div>
                    <div className='interfSearch align-middle col-4 me-auto'>
                        <input
                            id='search'
                            placeholder='Enter your search here'
                            value={searchString}
                            onChange={(e) => SearchEquipment(e.target.value)}
                        />
                        {searchFound ? (
                            ''
                        ) : (
                            <p className='danger'>
                                Search doens't match any equipment
                            </p>
                        )}
                    </div>
                    <div className='align-middle col-auto'>
                        <ROIcon
                            icon='ROicoUp'
                            size={24}
                            className='icoSVG'
                            tooltip='Collapse All'
                            onClickCallback={() => CollapseAll()}
                        />
                        <ROIcon
                            icon='ROicoHelp'
                            size={24}
                            className='icoSVG'
                            tooltip='Help'
                        />
                    </div>

                    {showNewEquip ? (
                        <>
                            <div className='container p-2 pt-0 bg-light rounded-2'>
                                Add New Equipment:
                                <div className='row' /*EquipInfo Expanded*/>
                                    <div className='ROmaintxt col-3'>
                                        <input
                                            className='txtBox'
                                            value={txtEquipCode}
                                            placeholder='Product Code'
                                            onChange={(e) => {
                                                setTxtEquipCode(e.target.value)
                                                vaildateEquipmentNewEquipForm()
                                            }}
                                            ref={valEquipCodeRef}
                                            title='Equipment Code'
                                            pattern='[a-zA-Z0-9@ (),.!&/\\\-_]+'
                                            required
                                        />
                                    </div>
                                    <div className='col-auto me-auto px-0'>
                                        {' '}
                                        <>
                                            {boolEquipmentFormValidated ? (
                                                <ROIcon
                                                    icon='ROicoCheck'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Add Equipment'
                                                    onClickCallback={() =>
                                                        AddNewEquipment()
                                                    }
                                                />
                                            ) : (
                                                <ROIcon
                                                    icon='ROicoCheck'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Add Equipment'
                                                    onClickCallback={() =>
                                                        vaildateEquipmentNewEquipForm(
                                                            true
                                                        )
                                                    }
                                                    usefill={true}
                                                    color1='#ff0000'
                                                />
                                            )}

                                            <ROIcon
                                                icon='ROicoClose'
                                                size={24}
                                                className='icoSVG'
                                                tooltip='Cancel'
                                                onClickCallback={() =>
                                                    vaildateEquipmentNewEquipForm(
                                                        true
                                                    )
                                                }
                                            />
                                        </>
                                    </div>
                                </div>
                                <div className='row' /*EquipInfo Expanded*/>
                                    <div className='ROnormaltxt col-6 fs-5'>
                                        {' '}
                                        <input
                                            className='txtBox'
                                            value={txtEquipName}
                                            placeholder='Product Name'
                                            onChange={(e) => {
                                                setTxtEquipName(e.target.value)
                                                vaildateEquipmentNewEquipForm()
                                            }}
                                            ref={valEquipNameRef}
                                            title='Equipment Name'
                                            pattern='[a-zA-Z0-9@ (),.!&/\\\-_]+'
                                            required
                                        />
                                    </div>
                                    <div className='ROnormaltxt col-2 fs-5'>
                                        {' '}
                                        <input
                                            className='txtBox'
                                            value={txtEquipPrice}
                                            placeholder='Latest Price'
                                            onChange={(e) => {
                                                setTxtEquipPrice(
                                                    e.target.value.replace(
                                                        /[^0-9\.]+/g,
                                                        ''
                                                    )
                                                )
                                                vaildateEquipmentNewEquipForm()
                                            }}
                                            ref={valEquipPriceRef}
                                            title='Equipment Price'
                                            pattern='^[0-9]\d*(\.\d+)?$'
                                            required
                                        />
                                    </div>
                                    <div className='ROnormaltxt col-4 fs-5'>
                                        {' '}
                                        <ROComboBox
                                            dataArray={arrCategories}
                                            placeholdertext='Category'
                                            myValueCallback={
                                                setTxtEquipCategory
                                            }
                                            ref={valEquipCategoryRef}
                                            required={true}
                                            validationCallBack={() =>
                                                vaildateEquipmentNewEquipForm()
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </section>
            <div className='pageContent container'>
                {searchResults.map((equip, i) => {
                    return (
                        <EquipItem
                            key={myUUid()}
                            equip={equip}
                            arrCategories={arrCategories}
                            collapse={getCollapseAll}
                            containerBackColor={
                                (i + 1) % 2 === 0 ? 'bg-white' : 'bg-secondary'
                            }
                        />
                    )
                })}
            </div>
        </>
    )
}

export default Equipment
