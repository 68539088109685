import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { toast } from 'react-toastify'
import ROIcon from '../components/ROIcon'
import ShowROIcons from '../components/ShowROIcons'

const Dashboard = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user, message, isError } = useSelector((state) => state.auth)

    useEffect(() => {
        console.log('DASHBOARD: ', user)

        if (isError) {
            console.log('Customers USERS useEffect error: ', message)
            navigate('/')
            toast.error(message)
            return
        }

        if (!user || !user._id || !user.token) {
            console.log('User error!!! log out: ', user)
            dispatch(logout())
            dispatch(reset())
            navigate('/')
            return
        }
    }, [navigate, user, dispatch, isError])

    return (
        <>
            <section className='pageHead container'>
                <h4>
                    Welcome {user && user.name}
                    <ROIcon
                        icon='marknew'
                        size={64}
                        usefill={true}
                        style={{
                            animation: 'rotation 15s infinite linear',
                        }}
                    />
                </h4>
                <h4>ROvantage Dashboard</h4>
                <section className='pageFunctions container'></section>
            </section>
            <div className='pageContent container'>
                <ShowROIcons />
            </div>
        </>
    )
}

export default Dashboard
