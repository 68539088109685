import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import equipcustlocService from './equipcustlocService'

const initialState = {
    equipcustloc: [],
    isEquipCustLocError: false,
    isEquipCustLocSuccess: false,
    isEquipCustLocLoading: false,
    equipcustlocmessage: '',
}

// Create New Equipment on location
export const createEquipCustLoc = createAsyncThunk(
    'equipcustloc/create',
    async (equipcustlocData, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token

            return await equipcustlocService.createEquipCustLoc(
                equipcustlocData,
                token
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update Equipment on location
export const updateEquipCustLoc = createAsyncThunk(
    'equipcustloc/update',
    async (equipcustlocData, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token
            return await equipcustlocService.updateEquipCustLoc(
                equipcustlocData,
                token
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Get location equipment
export const getEquipCustLoc = createAsyncThunk(
    'equipcustloc/getAll',
    async (_, thunkAPI) => {
        try {
            let userCred = ''

            if (thunkAPI.getState() != null) {
                userCred = thunkAPI.getState().auth.user
            }

            if (userCred.token) {
                const returnData = await equipcustlocService.getEquipCustLoc(
                    userCred
                )

                console.log(
                    'getEquipCustLoc: ',
                    JSON.stringify(getEquipCustLoc)
                )

                return returnData
            }

            return thunkAPI.rejectWithValue('No user token found')
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            console.log('thunkAPI GetEquipCustLoc error: ', message, error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delete equipment on location
export const deleteEquipCustLoc = createAsyncThunk(
    'equipcustloc/delete',
    async (id, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token
            return await equipcustlocService.deleteEquipCustLoc(id, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const equipcustlocSlice = createSlice({
    name: 'equipcustloc',
    initialState,
    reducers: {
        resetequipcustloc: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(createEquipCustLoc.pending, (state) => {
                state.isEquipCustLocSuccess = false
                state.isEquipCustLocLoading = true
                state.isEquipCustLocError = false
            })
            .addCase(createEquipCustLoc.fulfilled, (state, action) => {
                state.isEquipCustLocLoading = false
                state.isEquipCustLocSuccess = true
                state.isEquipCustLocError = false
                if (!state.equipcustloc) {
                    state.equipcustloc = []
                }
                state.equipcustloc.push(action.payload)
            })
            .addCase(createEquipCustLoc.rejected, (state, action) => {
                state.isEquipCustLocLoading = false
                state.isEquipCustLocError = true
                console.log(
                    'createEquipCustLoc.rejected action.payload: ',
                    action.payload
                )
                state.equipcustlocmessage = action.payload
            })
            .addCase(updateEquipCustLoc.pending, (state) => {
                state.isEquipCustLocSuccess = false
                state.isEquipCustLocLoading = true
                state.isEquipCustLocError = false
            })
            .addCase(updateEquipCustLoc.fulfilled, (state, action) => {
                state.isEquipCustLocLoading = false
                state.isEquipCustLocSuccess = true
                state.isEquipCustLocError = false
                state.equipcustloc = state.equipcustloc.map((equipcustloc) => {
                    if (
                        equipcustloc.EquipCustLocID ===
                        action.payload.EquipCustLocID
                    ) {
                        return action.payload
                    } else {
                        return equipcustloc
                    }
                })
            })
            .addCase(updateEquipCustLoc.rejected, (state, action) => {
                state.isEquipCustLocLoading = false
                state.isEquipCustLocError = true
                //This is what redux helps to do. Change immutable values
                state.equipcustlocmessage = action.payload
            })
            .addCase(getEquipCustLoc.pending, (state) => {
                state.isEquipCustLocLoading = true
                state.isEquipCustLocError = false
            })
            .addCase(getEquipCustLoc.fulfilled, (state, action) => {
                state.isEquipCustLocLoading = false
                state.isEquipCustLocSuccess = true
                state.isEquipCustLocError = false
                //This is what redux helps to do. Change immutable values
                state.equipcustloc = action.payload
            })
            .addCase(getEquipCustLoc.rejected, (state, action) => {
                state.isEquipCustLocLoading = false
                state.isEquipCustLocError = true
                //This is what redux helps to do. Change immutable values
                state.equipcustlocmessage = action.payload
            })
            .addCase(deleteEquipCustLoc.pending, (state) => {
                state.isEquipCustLocSuccess = false
                state.isEquipCustLocLoading = true
                state.isEquipCustLocError = false
            })
            .addCase(deleteEquipCustLoc.fulfilled, (state, action) => {
                state.isEquipCustLocLoading = false
                state.isEquipCustLocSuccess = true
                state.isEquipCustLocError = false
                state.equipcustloc = state.equipcustloc.filter(
                    (equipcustloc) =>
                        equipcustloc.EquipCustLocID !== action.payload.id
                )
            })
            .addCase(deleteEquipCustLoc.rejected, (state, action) => {
                state.isEquipCustLocLoading = false
                state.isEquipCustLocError = true
                //This is what redux helps to do. Change immutable values
                state.equipcustlocmessage = action.payload
            })
    },
})

export const { resetequipcustloc } = equipcustlocSlice.actions
export default equipcustlocSlice.reducer
