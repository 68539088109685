import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import equipService from './equipService'

const initialState = {
    equipment: [],
    isEquipError: false,
    isEquipSuccess: false,
    isEquipLoading: false,
    equipmessage: '',
}

// Create New Equipment
export const createEquipment = createAsyncThunk(
    'equip/create',
    async (equipData, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token

            return await equipService.createEquipment(equipData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update Equipment
export const updateEquipment = createAsyncThunk(
    'equip/update',
    async (equipData, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token
            console.log('equipSlice: equip / update: ', equipData)
            return await equipService.updateEquipment(equipData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Get fran equipment
export const getEquipment = createAsyncThunk(
    'equip/getAll',
    async (_, thunkAPI) => {
        try {
            let userCred = ''

            //console.log('equipSlice getEquipment Check1')

            //console.log('getEquipment')
            if (thunkAPI.getState() != null) {
                userCred = thunkAPI.getState().auth.user
            }

            //console.log('equipSlice getEquipment Check2')

            if (userCred.token) {
                //console.log('equipSlice getEquipment Check3')

                const returnData = await equipService.getEquipment(userCred)
                // console.log(
                //     'equipSlice getEquipment Check4 returnData: ',
                //     returnData
                // )

                return returnData
            }

            return thunkAPI.rejectWithValue('No user token found')
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            console.log('thunkAPI GetEquip error: ', message, error)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delete equipment
export const deleteEquipment = createAsyncThunk(
    'equip/delete',
    async (id, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token
            return await equipService.deleteEquipment(id, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const equipSlice = createSlice({
    name: 'equip',
    initialState,
    reducers: {
        resetequip: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(createEquipment.pending, (state) => {
                state.isEquipSuccess = false
                state.isEquipLoading = true
                state.isEquipError = false
            })
            .addCase(createEquipment.fulfilled, (state, action) => {
                state.isEquipLoading = false
                state.isEquipSuccess = true
                state.isEquipError = false
                if (!state.equipment) {
                    state.equipment = []
                }
                state.equipment.push(action.payload)
            })
            .addCase(createEquipment.rejected, (state, action) => {
                state.isEquipLoading = false
                state.isEquipError = true
                state.equipmessage = action.payload
            })
            .addCase(updateEquipment.pending, (state) => {
                state.isEquipSuccess = false
                state.isEquipLoading = true
                state.isEquipError = false
            })
            .addCase(updateEquipment.fulfilled, (state, action) => {
                state.isEquipLoading = false
                state.isEquipSuccess = true
                state.isEquipError = false
                state.equipment = state.equipment.map((equip) => {
                    if (equip.EquipID === action.payload.EquipID) {
                        return action.payload
                    } else {
                        return equip
                    }
                })
            })
            .addCase(updateEquipment.rejected, (state, action) => {
                state.isEquipLoading = false
                state.isEquipError = true
                //This is what redux helps to do. Change immutable values
                state.equipmessage = action.payload
            })
            .addCase(getEquipment.pending, (state) => {
                state.isEquipLoading = true
                state.isEquipError = false
            })
            .addCase(getEquipment.fulfilled, (state, action) => {
                state.isEquipLoading = false
                state.isEquipSuccess = true
                state.isEquipError = false
                //This is what redux helps to do. Change immutable values
                state.equipment = action.payload
            })
            .addCase(getEquipment.rejected, (state, action) => {
                state.isEquipLoading = false
                state.isEquipError = true
                //This is what redux helps to do. Change immutable values
                state.equipmessage = action.payload
            })
            .addCase(deleteEquipment.pending, (state) => {
                state.isEquipSuccess = false
                state.isEquipLoading = true
                state.isEquipError = false
            })
            .addCase(deleteEquipment.fulfilled, (state, action) => {
                state.isEquipLoading = false
                state.isEquipSuccess = true
                state.isEquipError = false
                state.equipment = state.equipment.filter(
                    (equip) => equip.EquipID !== action.payload.id
                )
            })
            .addCase(deleteEquipment.rejected, (state, action) => {
                state.isEquipLoading = false
                state.isEquipError = true
                //This is what redux helps to do. Change immutable values
                state.equipmessage = action.payload
            })
    },
})

export const { resetequip } = equipSlice.actions
export default equipSlice.reducer
