import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import partsService from './partsService'

const initialState = {
    parts: [],
    isPartsError: false,
    isPartsSuccess: false,
    isPartsLoading: false,
    partsmessage: '',
}

// Create New Part
export const createPart = createAsyncThunk(
    'part/create',
    async (partData, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token

            return await partsService.createPart(partData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update Part
export const updatePart = createAsyncThunk(
    'part/update',
    async (partData, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token
            return await partsService.updatePart(partData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Get fran part
export const getParts = createAsyncThunk('part/getAll', async (_, thunkAPI) => {
    try {
        let userCred = ''

        //console.log('getPartr Auth Check1: ', userCred)

        if (thunkAPI.getState() != null) {
            userCred = thunkAPI.getState().auth.user
        }

        //console.log('getPartr Auth Check2: ', userCred)

        if (userCred.token) {
            const returnData = await partsService.getParts(userCred)
            console.log('getPartr Auth Check3 returnData: ', returnData)

            return returnData
        }

        console.log('getPartr Auth Check4: ', userCred)

        return thunkAPI.rejectWithValue('No user token found')
    } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        console.log('thunkAPI GetParts error: ', message, error)
        return thunkAPI.rejectWithValue(message)
    }
})

// Delete part
export const deletePart = createAsyncThunk(
    'part/delete',
    async (id, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token
            return await partsService.deletePart(id, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const partsSlice = createSlice({
    name: 'part',
    initialState,
    reducers: {
        resetparts: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(createPart.pending, (state) => {
                state.isPartsSuccess = false
                state.isPartsError = false
                state.isPartsLoading = true
            })
            .addCase(createPart.fulfilled, (state, action) => {
                state.isPartsLoading = false
                state.isPartsError = false
                state.isPartsSuccess = true
                if (!state.parts) {
                    state.parts = []
                }
                state.parts.push(action.payload)
            })
            .addCase(createPart.rejected, (state, action) => {
                state.isPartsLoading = false
                state.isPartsError = true
                state.partsmessage = action.payload
            })
            .addCase(updatePart.pending, (state) => {
                state.isPartsSuccess = false
                state.isPartsLoading = true
                state.isPartsError = false
            })
            .addCase(updatePart.fulfilled, (state, action) => {
                state.isPartsLoading = false
                state.isPartsSuccess = true
                state.isPartsError = false
                state.parts = state.parts.map((part) => {
                    if (part.PartID === action.payload.PartID) {
                        return action.payload
                    } else {
                        return part
                    }
                })
            })
            .addCase(updatePart.rejected, (state, action) => {
                state.isPartsLoading = false
                state.isPartsError = true
                //This is what redux helps to do. Change immutable values
                state.partsmessage = action.payload
            })
            .addCase(getParts.pending, (state) => {
                state.isPartsLoading = true
                state.isPartsError = false
            })
            .addCase(getParts.fulfilled, (state, action) => {
                state.isPartsLoading = false
                state.isPartsError = false
                state.isPartsSuccess = true
                //This is what redux helps to do. Change immutable values
                state.parts = action.payload
            })
            .addCase(getParts.rejected, (state, action) => {
                state.isPartsLoading = false
                state.isPartsError = true
                console.log(
                    'partsSlice getParts.rejected: ',
                    state.isPartsError,
                    action.payload
                )
                //This is what redux helps to do. Change immutable values
                state.partsmessage = action.payload
            })
            .addCase(deletePart.pending, (state) => {
                state.isPartsSuccess = false
                state.isPartsLoading = true
                state.isPartsError = false
            })
            .addCase(deletePart.fulfilled, (state, action) => {
                state.isPartsLoading = false
                state.isPartsSuccess = true
                state.isPartsError = false
                state.parts = state.parts.filter(
                    (part) => part.PartID !== action.payload.id
                )
            })
            .addCase(deletePart.rejected, (state, action) => {
                state.isPartsLoading = false
                state.isPartsError = true
                //This is what redux helps to do. Change immutable values
                state.partsmessage = action.payload
            })
    },
})

export const { resetparts } = partsSlice.actions
export default partsSlice.reducer
