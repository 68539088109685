import React from 'react'
import RepServiceOverdue from './RepServiceOverdue'

const AllReports = () => {
    console.log('*** AllReports Draw ****')
    return (
        <>
            {/* <div>All Reports</div> */}
            <div>
                <RepServiceOverdue />
            </div>
        </>
    )
}

export default AllReports
