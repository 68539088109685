import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import ROIcon from '../components/ROIcon'

const Header = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)

    const onLogout = () => {
        localStorage.removeItem('user')
        dispatch(logout())
        dispatch(reset())
        navigate('/')
    }
    return (
        <>
            <div className='header container-fluid pb-2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-auto'>
                            <ROIcon
                                icon='ROicoLogo'
                                usefill={true}
                                size={256}
                                height={75}
                                className='img-fluid'
                                to='/'
                                authlvl={99}
                            />
                        </div>
                        <div className='col me-auto'>
                            <div className='row topmenu '>
                                <div className='col-auto ms-auto'>
                                    <nav className='navbar navbar-expand-lg py-1'>
                                        <div
                                            className='collapse navbar-collapse '
                                            id='navbarNav'
                                        >
                                            <ul className='navbar-nav small '>
                                                {user ? (
                                                    <>
                                                        <li className='nav-item'>
                                                            Welcome{' '}
                                                            {user && user.name}
                                                        </li>
                                                        <li className='nav-item'>
                                                            <ROIcon
                                                                icon='ROicoSettings'
                                                                size={24}
                                                                className='icoSVG'
                                                                tooltip='Settings'
                                                                textafter='Account'
                                                            />
                                                        </li>
                                                        <li className='nav-item'>
                                                            <ROIcon
                                                                icon='ROicoOut'
                                                                size={24}
                                                                className='icoSVG'
                                                                authlvl={99}
                                                                textafter='Logout'
                                                                to='/'
                                                                onClickCallback={
                                                                    onLogout
                                                                }
                                                            />
                                                        </li>
                                                    </>
                                                ) : (
                                                    <>
                                                        <li className='nav-item mt-2 pt-0'>
                                                            {/* <Link
                                                                className='nav-link mt-2 pt-0'
                                                                to='/login'
                                                            > */}
                                                            <ROIcon
                                                                icon='ROicoIn'
                                                                size={24}
                                                                className='icoSVG'
                                                                textafter='Login'
                                                                to='/login'
                                                                authlvl={99}
                                                            />
                                                            {/* </Link> */}
                                                        </li>
                                                        <li className='nav-item mt-2 pt-0'>
                                                            {/* <Link
                                                                className='nav-link mt-2 pt-0'
                                                                to='/register'
                                                            > */}
                                                            <ROIcon
                                                                icon='ROicoStar'
                                                                size={24}
                                                                className='icoSVG'
                                                                textafter='Register'
                                                                to='/register'
                                                                authlvl={99}
                                                            />
                                                            {/* Register */}
                                                            {/* </Link> */}
                                                        </li>
                                                    </>
                                                )}
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                <div className='row bottommenu'>
                                    <div className='col-auto'>
                                        <nav className='navbar navbar-expand-lg py-1'>
                                            <Link
                                                className='navbar-brand'
                                                to='/'
                                            ></Link>
                                            <button
                                                className='navbar-toggler'
                                                type='button'
                                                data-bs-toggle='collapse'
                                                data-bs-target='#navbarNav'
                                                aria-controls='navbarNav'
                                                aria-expanded='false'
                                                aria-label='Toggle navigation'
                                            >
                                                <span className='navbar-toggler-icon'></span>
                                            </button>
                                            <div
                                                className='collapse navbar-collapse'
                                                id='navbarNav'
                                            >
                                                <ul className='navbar-nav'>
                                                    <li className='nav-item'>
                                                        <ROIcon
                                                            icon='ROicoStar'
                                                            size={24}
                                                            className='icoSVG'
                                                            to='/'
                                                            textafter='Home'
                                                            authlvl={99}
                                                        />
                                                    </li>
                                                    <li className='nav-item'>
                                                        <ROIcon
                                                            icon='ROicoStar'
                                                            size={24}
                                                            className='icoSVG'
                                                            to='/'
                                                            textafter='Pricing'
                                                        />
                                                    </li>
                                                    <li className='nav-item'>
                                                        <ROIcon
                                                            icon='ROicoStar'
                                                            size={24}
                                                            className='icoSVG'
                                                            to='/'
                                                            textafter='Add-ons'
                                                        />
                                                    </li>
                                                    <li className='nav-item'>
                                                        <ROIcon
                                                            icon='ROicoSupport'
                                                            size={24}
                                                            className='icoSVG'
                                                            to='/'
                                                            textafter='Support'
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-auto'>{/* <p>Last</p> */}</div>
                    </div>
                </div>
            </div>

            {/* <div className='header container-fluid'>
                <div className='container'>
                    <nav className='navbar navbar-expand-lg'>
                        <div className='container-fluid'>
                            <Link className='navbar-brand' to='/'></Link>
                            <button
                                className='navbar-toggler'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#navbarNav'
                                aria-controls='navbarNav'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                            >
                                <span className='navbar-toggler-icon'></span>
                            </button>
                            <div
                                className='collapse navbar-collapse'
                                id='navbarNav'
                            >
                                <ul className='navbar-nav'>
                                    <li className='nav-item'>
                                        <Link className='nav-link mt-3' to='/'>
                                            <ROIcon
                                                icon='ROicoStar'
                                                size={24}
                                                className='icoSVG'
                                            />
                                            Home
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link className='nav-link mt-3' to='/'>
                                            <ROIcon
                                                icon='ROicoStar'
                                                size={24}
                                                className='icoSVG'
                                            />
                                            Pricing
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link className='nav-link mt-3' to='/'>
                                            <ROIcon
                                                icon='ROicoStar'
                                                size={24}
                                                className='icoSVG'
                                            />
                                            Add-ons
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link className='nav-link mt-3' to='/'>
                                            <ROIcon
                                                icon='ROicoSupport'
                                                size={24}
                                                className='icoSVG'
                                            />
                                            Support
                                        </Link>
                                    </li>

                                    {user ? (
                                        <>
                                            <div className='subheader'>
                                                <li className='nav-item'>
                                                    <Link
                                                        className='nav-link mt-4 pt-0'
                                                        to='/login'
                                                        onClick={onLogout}
                                                    >
                                                        <ROIcon
                                                            icon='ROicoOut'
                                                            size={24}
                                                            className='icoSVG'
                                                        />
                                                        Logout
                                                    </Link>
                                                </li>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <li className='nav-item'>
                                                <Link
                                                    className='nav-link mt-4 pt-0'
                                                    to='/login'
                                                >
                                                    <ROIcon
                                                        icon='ROicoIn'
                                                        size={24}
                                                        className='icoSVG'
                                                    />
                                                    Login
                                                </Link>
                                            </li>
                                            <li className='nav-item mx-2'>
                                                <Link
                                                    className='nav-link mt-4 pt-0'
                                                    to='/register'
                                                >
                                                    <ROIcon
                                                        icon='ROicoStar'
                                                        size={24}
                                                        className='icoSVG'
                                                    />
                                                    Register
                                                </Link>
                                            </li>
                                        </>
                                    )}
                                </ul>
                                {user ? (
                                    <p className='position-absolute top-0 end-0'>
                                        Welcome {user && user.name}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </nav>
                </div>
            </div> */}

            {/* Bottom logged in bar */}
            {user ? (
                <>
                    <div className='subHeader sticky-top'>
                        <div className='container'>
                            <nav className='navbar navbar-expand-lg py-1 whitetxt'>
                                <div className='container-fluid'>
                                    <button
                                        className='navbar-toggler'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#navbarNav'
                                        aria-controls='navbarNav'
                                        aria-expanded='false'
                                        aria-label='Toggle navigation'
                                    >
                                        <span className='navbar-toggler-icon'></span>
                                    </button>
                                    <div
                                        className='collapse navbar-collapse'
                                        id='navbarNav'
                                    >
                                        <ul className='navbar-nav'>
                                            <li className='nav-item'>
                                                <ROIcon
                                                    icon='ROicoDash'
                                                    size={24}
                                                    className='icoSVG'
                                                    textafter='Dashboard'
                                                    authlvl={10}
                                                    to='/dashboard'
                                                />
                                            </li>
                                            <li className='nav-item'>
                                                <ROIcon
                                                    icon='ROicoCust'
                                                    size={24}
                                                    className='icoSVG'
                                                    textafter=' Customers'
                                                    authlvl={15}
                                                    to='/customers'
                                                />
                                            </li>
                                            <li className='nav-item'>
                                                <ROIcon
                                                    icon='ROicoEquip'
                                                    size={24}
                                                    className='icoSVG'
                                                    authlvl={10}
                                                    to='/equipment'
                                                    textafter='Equipment'
                                                />
                                            </li>
                                            <li className='nav-item'>
                                                <ROIcon
                                                    icon='ROicoParts'
                                                    size={24}
                                                    className='icoSVG'
                                                    authlvl={10}
                                                    to='/parts'
                                                    textafter='Parts'
                                                />
                                            </li>
                                            <li className='nav-item'>
                                                <ROIcon
                                                    icon='ROicoReport'
                                                    size={24}
                                                    className='icoSVG'
                                                    authlvl={10}
                                                    to='/reports'
                                                    textafter='Reports'
                                                />
                                            </li>
                                            <li className='nav-item'>
                                                <ROIcon
                                                    icon='ROicoUsers'
                                                    size={24}
                                                    className='icoSVG'
                                                    authlvl={10}
                                                    to='/users'
                                                    textafter='Users'
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </>
            ) : (
                ''
            )}
        </>
    )
}

export default Header
