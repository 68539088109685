import axios from 'axios'

const API_URL = '/api/customers/'

//Add new customer
const createCustomer = async (custData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.post(API_URL, custData, headersConfig)
    return response.data
}

const updateCustomer = async (custData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    //console.log('Update Customer: ', custData)

    const response = await axios.put(
        API_URL + custData.custID,
        custData,
        headersConfig
    )

    return response.data
}

//Get customers
const getCustomers = async (userCreds) => {
    try {
        const headersConfig = {
            headers: {
                Authorization: `Bearer ${userCreds.token}`,
            },
        }

        const response = await axios.get(API_URL, headersConfig, userCreds)

        return response.data
    } catch (error) {
        console.log('custService getCustomers response: ', error.response)

        if (error.response.status === 401) {
            throw new Error(error.response.statusText + ' Please Log In')
        }

        throw new Error(
            'custService(getCustomers): ' + error.response.data.message
        )
    }
}

//Delete customer
const deleteCustomer = async (custID, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    //console.log('Delete Service custid: ', custID)
    const response = await axios.delete(API_URL + custID, headersConfig)

    return response.data
}

const custService = {
    createCustomer,
    getCustomers,
    deleteCustomer,
    updateCustomer,
}

export default custService
