import { useEffect, useState, useRef, forwardRef, useCallback } from 'react'
import myUUid from '../features/myUUid'

const ROComboBox = forwardRef(
    (
        {
            dataArray = [], // {Text: 'Item Text', (optional)ID: 'Id of item to return'}
            classname = 'ROdropdown',
            textVal = '',
            placeholdertext = '',
            title = placeholdertext,
            myValueCallback,
            myIDCallback,
            clearTextOnSelect = false,
            required = false,
            validationCallBack,
            allowCustomValue = true,
        },
        ref
    ) => {
        let passedItem = null

        //If user is not allowed to add custom text and only choose from list. Either show selection or nothing on lose focus or redraw
        if (
            !allowCustomValue &&
            textVal != '' &&
            dataArray &&
            dataArray.length > 0
        ) {
            console.log('ROComboBox dataArray: ', dataArray)
            passedItem = dataArray.find((item) => item.Text === textVal)
        }

        const [txtText, setTxtText] = useState(textVal)
        const [showdropdown, setShowDropDown] = useState(false)
        const [curObj, setCurObj] = useState(passedItem)

        console.log('*** DRAW curObj ****: ', curObj ? curObj : null)
        console.log(
            ' curObj: ',
            curObj,
            ' txtText(state): ',
            txtText,
            ' textVal: ',
            textVal
        )

        const componentRef = useRef()

        //Filter Categories by Text Typed
        let filtered = dataArray

        if (txtText !== '') {
            filtered = dataArray.filter((cat) =>
                String(cat.Text)
                    .toLowerCase()
                    .includes(String(txtText).toLowerCase())
            )

            //console.log('Combo txtText: ' + txtText, filtered)
        }

        const clickDropDownItem = (obj) => {
            setCurObj(obj)

            if (myValueCallback) {
                myValueCallback(obj.Text)
            }

            if (myIDCallback && obj.ID) {
                console.log('Send ' + obj.Text + ' ID: ', obj.ID)
                myIDCallback(obj.ID)
            } else {
                console.log('NOT!!! Send ' + obj.Text + ' ID: ', obj.ID)
            }

            if (clearTextOnSelect) {
                setTxtText('')
                setCurObj(null)
                textVal = ''
            } else {
                setTxtText(obj.Text)
            }

            if (validationCallBack) {
                console.log('CALL VALIDATION CHECK!!!!')
                validationCallBack()
            }

            showDropDown(false)
        }

        const changeDropDown = (e) => {
            if (myValueCallback) {
                console.log('myValueCallback: ', e.target.value)
                myValueCallback(e.target.value)
            }

            setTxtText(e.target.value)
            textVal = e.target.value

            if (!showdropdown) {
                setShowDropDown((prev) => !prev)
            }

            if (validationCallBack) {
                validationCallBack()
            }
        }

        const showDropDown = (show = false) => {
            if (showdropdown !== show) {
                setShowDropDown((prev) => !prev)
            }
        }

        const handleOutsideClick = (e) => {
            // console.log(
            //     'componentRef: ',
            //     componentRef,
            //     '  e.target: ',
            //     e.target,
            //     ' curObj: ',
            //     curObj,
            //     ' txtText: ',
            //     txtText,
            //     ' textVal: ',
            //     textVal
            // )

            console.log(
                'componentRef: ',
                componentRef,
                '  e.target: ',
                e.target
            )

            if (
                componentRef.current &&
                !componentRef.current.contains(e.target)
            ) {
                console.log(
                    'CLICK OUTSIDE ROCombobox allowCustom Value: ',
                    allowCustomValue
                )
                setShowDropDown(false)

                if (!allowCustomValue) {
                    if (curObj) {
                        setTxtText(curObj.Text)
                        textVal = curObj.Text
                    } else {
                        console.log('NO curObj found zero string ROCombo')
                        setTxtText('')
                        textVal = ''
                    }
                }

                // if (curObj && txtText && txtText === '' && !allowCustomValue) {
                //     console.log(
                //         'RO COMBOBox handleOutsideClick curObj: ',
                //         curObj
                //     )
                //     setTxtText(curObj.Text)
                // } else if (!allowCustomValue && !curObj) {
                //     console.log('RO COMBOBox NO CUSTOM VALUE!!!')
                //     setTxtText('')
                //     textVal = ''
                // }
                // } else if (allowCustomValue) {
                //     setTxtText(textVal)
                // }
            }

            if (validationCallBack) {
                validationCallBack()
            }
        }

        useEffect(() => {
            document.addEventListener('mousedown', handleOutsideClick)

            //console.log('ROCOMBO Useeffect')

            return () => {
                document.removeEventListener('mousedown', handleOutsideClick)
            }
        }, [curObj]) //curObj

        // useEffect(() => {
        //     console.log('USE EFFECT TEXTVAL!!!!')
        //     setTxtText(textVal)

        //     if (
        //         curObj &&
        //         textVal &&
        //         txtText &&
        //         txtText !== '' &&
        //         curObj.Text !== textVal
        //     ) {
        //         console.log(' SET CUROBJ NULL!!!')
        //         setCurObj(null)
        //     }
        // }, [textVal])

        return (
            <>
                <div ref={componentRef} className={classname}>
                    <input
                        className='txtBox p-0 m-0'
                        value={txtText}
                        placeholder={placeholdertext}
                        title={title}
                        onChange={(e) => {
                            changeDropDown(e)
                        }}
                        onFocus={() => showDropDown(true)}
                        onBlur={(e) => handleOutsideClick(e)}
                        ref={ref}
                        required={required}
                    />

                    {showdropdown ? (
                        <div className='dropdown-content'>
                            {filtered.map((data, i) => {
                                return (
                                    <div
                                        className='dropdown-item'
                                        key={myUUid()}
                                        onClick={() => clickDropDownItem(data)}
                                    >
                                        {data.Text}
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </>
        )
    }
)

export default ROComboBox
