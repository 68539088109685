import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from './components/Header'
import Home from './pages/Home'
import Dashboard from './pages/Dashboard'
import Customers from './pages/Customers'
import Login from './pages/Login'
import Register from './pages/Register'
import Equipment from './pages/Equipment'
import Parts from './pages/Parts'
import Users from './pages/Users'
import Reports from './pages/Reports'

function App() {
    return (
        <>
            <Router>
                <div className='globalContainer container-fluid'>
                    <Header />
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/dashboard' element={<Dashboard />} />
                        <Route path='/customers' element={<Customers />} />
                        <Route path='/register' element={<Register />} />
                        <Route path='/equipment' element={<Equipment />} />
                        <Route path='/parts' element={<Parts />} />
                        <Route path='/users' element={<Users />} />
                        <Route path='/reports' element={<Reports />} />
                    </Routes>
                </div>
            </Router>
            <ToastContainer />
        </>
    )
}

export default App
