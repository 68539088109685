import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { toast } from 'react-toastify'
import ROIcon from '../components/ROIcon'

const Home = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user, isError, message } = useSelector((state) => state.auth)

    const onLogout = () => {
        console.log('Home LogOut Clicked')
        localStorage.removeItem('user')
        dispatch(logout())
        dispatch(reset())
        navigate('/')
        return
    }

    useEffect(() => {
        console.log('Home called: ', user)

        if (isError) {
            console.log('Customers USERS useEffect error: ', message)
            navigate('/')
            toast.error(message)
            return
        }

        if (!user || !user._id || !user.token) {
            console.log('User error!!! log out: ', user)
            dispatch(logout())
            dispatch(reset())
            navigate('/')
            return
        }
    }, [isError])

    return (
        <>
            <section className='welcomeBox'>
                <div className='row mt-5'>
                    <div className='col-md-5 m-auto'>
                        <div className='card card-body text-center'>
                            <h1>
                                <ROIcon
                                    icon='marknew'
                                    size={128}
                                    usefill={true}
                                    style={{
                                        animation:
                                            'rotation 15s infinite linear',
                                    }}
                                />
                            </h1>
                            <h2>Welcome to ROvantage</h2>
                            {!user ? (
                                <>
                                    <p>Create an account or login</p>
                                    <Link
                                        to='/register'
                                        className='btn btn-primary btn-block mb-2'
                                    >
                                        Register
                                    </Link>
                                    <Link
                                        to='/login'
                                        className='btn btn-success btn-block'
                                    >
                                        Login
                                    </Link>
                                </>
                            ) : (
                                <ROIcon
                                    icon='ROicoOut'
                                    size={24}
                                    className='icoSVG'
                                    textafter='Logout'
                                    to='/'
                                    onClickCallback={onLogout}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home
