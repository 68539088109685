import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import myUUid from '../features/myUUid'
import ContentBox from './ContentBox'
import ROIcon from './ROIcon'
import AllServicesItem from './AllServicesItem'
import { VaildateForm } from '../features/globalFunctions'

//Import Redux State/Global Var and Axiox database calls
import {
    updateEquipCustLoc,
    deleteEquipCustLoc,
} from '../features/equipcustloc/equipcustlocSlice.js'

import ROComboBox from './ROComboBox'
import { formatShortDate } from '../features/formatTime'
import { getServiceInterval } from '../features/globalFunctions'

const EquipCustLocItem = ({
    equipcustlocitem,
    equipitem,
    arrCategories,
    containerBackColor,
    collapse,
    _expanedState,
    _editState,
}) => {
    const dispatch = useDispatch()

    if (equipitem) {
        //console.log(`*** Equip  ${equipitem.EquipName}  Draw ***`)
    }

    //Default Values
    containerBackColor = null ? 'bg-secondary' : containerBackColor
    _expanedState = null ? false : _expanedState //Expanded or not
    _editState = null ? false : _editState //Editing item or not

    //Get State location equipment from redux
    const { equipcustloc } = useSelector((state) => state.equipcustloc)

    //Default States
    const [toggleState, setToggleState] = useState(_expanedState)
    const [editState, setEditState] = useState(_editState)
    const [confirmDelete, setDeleteState] = useState(false)
    const [txtEquipRepID, setTxtEquipRepID] = useState(
        equipcustlocitem ? equipcustlocitem.EquipCustLocSalesID : -1
    )

    const txtEquipIntervalTypeRef = useRef()

    //Edit Equipment on Loction
    //Form edit values needed so react UI can show user typing and used as global vars for db updates
    const [txtEquipCustLocSerialNR, setTxtEquipCustSerialNR] = useState(
        equipcustlocitem ? equipcustlocitem.EquipCustLocSerialNR : ''
    )

    const [txtEquipCustLocRoomPlace, setTxtEquipCustRoomPlace] = useState(
        equipcustlocitem ? equipcustlocitem.EquipCustLocRoomPlace : ''
    )

    const [
        txtEquipCustLocDaysTillNextService,
        setTxtEquipCustDaysTillNextService,
    ] = useState(
        equipcustlocitem
            ? equipcustlocitem.EquipCustLocDaysTillNextService /
                  equipcustlocitem.EquipCustLocServiceIntervalType
            : ''
    )

    const [
        txtEquipCustLocServiceIntervalType,
        setTxtEquipCustServiceIntervalType,
    ] = useState(
        equipcustlocitem ? equipcustlocitem.EquipCustLocServiceIntervalType : ''
    )
    const editEquipCustLoc = (edit = true) => {
        if (!edit) {
            setEditState((prev) => !prev)
            setTxtEquipCustDaysTillNextService(
                equipcustlocitem.EquipCustLocDaysTillNextService
                    ? equipcustlocitem.EquipCustLocDaysTillNextService /
                          equipcustlocitem.EquipCustLocServiceIntervalType
                    : ''
            )
            setTxtEquipCustRoomPlace(
                equipcustlocitem.EquipCustLocRoomPlace
                    ? equipcustlocitem.EquipCustLocRoomPlace
                    : ''
            )
            setTxtEquipCustSerialNR(
                equipcustlocitem.EquipCustLocSerialNR
                    ? equipcustlocitem.EquipCustLocSerialNR
                    : ''
            )
            setTxtEquipCustServiceIntervalType(
                equipcustlocitem.EquipCustLocServiceIntervalType
                    ? equipcustlocitem.EquipCustLocServiceIntervalType
                    : ''
            )
            return
        }

        if (editState) {
            setTxtEquipCustDaysTillNextService(
                equipcustlocitem.EquipCustLocDaysTillNextService
                    ? equipcustlocitem.EquipCustLocDaysTillNextService /
                          equipcustlocitem.EquipCustLocServiceIntervalType
                    : ''
            )
            setTxtEquipCustRoomPlace(
                equipcustlocitem.EquipCustLocRoomPlace
                    ? equipcustlocitem.EquipCustLocRoomPlace
                    : ''
            )
            setTxtEquipCustSerialNR(
                equipcustlocitem.EquipCustLocSerialNR
                    ? equipcustlocitem.EquipCustLocSerialNR
                    : ''
            )
            setTxtEquipCustServiceIntervalType(
                equipcustlocitem.EquipCustLocServiceIntervalType
                    ? equipcustlocitem.EquipCustLocServiceIntervalType
                    : ''
            )

            const updEquipCustLoc = {
                equipcustlocID: equipcustlocitem.EquipCustLocID,
                custlocID: equipcustlocitem.CustLocID,
                equipID: equipcustlocitem.EquipID,
                equipcustlocRepID: txtEquipRepID,
                equipcustlocserialnr: txtEquipCustLocSerialNR,
                //equipcustlocdays: txtEquipCustLocDaysTillNextService,
                //equipcustlocintervaltype: txtEquipCustLocServiceIntervalType,
                equipcustlocdays:
                    txtEquipCustLocDaysTillNextService *
                    txtEquipIntervalTypeRef.current.value,
                equipcustlocintervaltype: parseInt(
                    txtEquipIntervalTypeRef.current.value
                ),
                equipcustlocroomplace: txtEquipCustLocRoomPlace,
                equipcustlocnotes: '',
                equipfcustloclags: '',
            }

            console.log('updEquipCustLoc: ', updEquipCustLoc)

            dispatch(updateEquipCustLoc(updEquipCustLoc))
            //updateCustomerDetails(CustID)
            //refreshCustCallback()
        }

        setEditState((prev) => !prev)
    }

    //Delete Equipment
    const confirmDeleteEquipCustLoc = (setState) => {
        if (confirmDelete && setState) {
            console.log(
                'Delete!!!! EquipCustLocID: ',
                equipcustlocitem.EquipCustLocID
            )
            deleteEquipCustLocById(equipcustlocitem.EquipCustLocID)
        }
        setDeleteState(setState)
    }

    const deleteEquipCustLocById = (_id) => {
        console.log('Delete!!!! Equip_ID: ', _id)
        dispatch(deleteEquipCustLoc(_id))
        confirmDeleteEquipCustLoc(false)
        //refreshCustCallback()
    }

    // const getServiceInterval = (data) => {
    //     let txtInterval = ''
    //     if (data.EquipCustLocServiceIntervalType > 30) {
    //         txtInterval = 'Year'
    //     } else if (data.EquipCustLocServiceIntervalType > 7) {
    //         txtInterval = 'Months'
    //     } else if (data.EquipCustLocServiceIntervalType > 1) {
    //         txtInterval = 'Weeks'
    //     } else {
    //         txtInterval = 'Days'
    //     }

    //     return (
    //         equipcustlocitem.EquipCustLocDaysTillNextService /
    //             equipcustlocitem.EquipCustLocServiceIntervalType +
    //         ' ' +
    //         txtInterval +
    //         ' Interval'
    //     )
    // }

    let arrRoomPlace = []
    if (equipcustloc) {
        arrCategories = new Set(
            equipcustloc.map((equip) => equip.EquipCustLocRoomPlace)
        )
        //console.log('equipcustlocitem equipcustloc: ', equipcustloc)
        arrRoomPlace = Array.from(arrCategories)
        arrRoomPlace = arrRoomPlace.map((equipcustloc) => {
            return {
                Text: equipcustloc,
            }
        })

        //console.log('arrRoomPlace: ', arrRoomPlace)
    }

    //Get State users / staff from redux
    const { franusers, isFUsersError, isFUsersSuccess, franusersmessage } =
        useSelector((state) => state.franusers)

    let arrSalesReps = []
    if (franusers) {
        arrSalesReps = new Set(
            franusers.map((reps) => {
                return { name: reps.name, ID: reps._id }
            })
        )
        arrSalesReps = Array.from(arrSalesReps)
        arrSalesReps = arrSalesReps.map((reps) => {
            return {
                Text: reps.name,
                ID: reps.ID,
            }
        })
    }

    const getRepName = (id) => {
        let retVal = arrSalesReps.find((rep) => {
            console.log('Rep id: ', rep, id)
            if (rep.ID === id) {
                console.log('Choosen Rep id: ', rep)
                return rep.Text
            }
        })

        console.log('getRepName: ', retVal)

        if (retVal) {
            return retVal.Text
        } else {
            return ''
        }
    }

    if (collapse && toggleState) {
        setToggleState(false)
        collapse = false
    }

    //#region Edit Location Equipment Form Validation
    const [
        boolCustLocEditEquipFormValidated,
        setCustLocEditEquipFormValidated,
    ] = useState(false)

    const valEquipSalesRef = useRef()
    const valEquipSerialNrRef = useRef()
    const valEquipRoomPlaceRef = useRef()
    const valEquipServiceIntervalValRef = useRef()

    const vaildateCustLocEditEquipForm = (clicked = false) => {
        if (!editState) return

        if (editState) {
            setCustLocEditEquipFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valEquipSalesRef,
                            text: 'Sales Rep Required',
                            form: 'Edit Customer Location Equipment valEquipSalesRef',
                        },
                        {
                            ref: valEquipSerialNrRef,
                            text: 'No Special Characters',
                            form: 'Edit Customer Location Equipment valEquipSerialNrRef',
                        },
                        {
                            ref: valEquipRoomPlaceRef,
                            text: 'Room/Place Required',
                            form: 'Edit Customer Location Equipment valEquipRoomPlaceRef',
                        },
                        {
                            ref: valEquipServiceIntervalValRef,
                            text: 'Only Integer Values',
                            form: 'Edit Customer Location Equipment valEquipServiceIntervalValRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    // useEffect(() => {
    //     //REdraw on validation change
    //     vaildateServiceForm()
    //     //Do validation on ROCombobox changes Service Rep ID
    // }, [txtServiceRepID])
    //#endregion

    return (
        <ContentBox
            classTag={
                toggleState
                    ? `custBox content border border-light p-0 m-0 mb-1 rounded-2 ${containerBackColor}`
                    : `custBox content border border-light p-0 m-0 mb-1 rounded-2 ${containerBackColor}`
            }
        >
            {toggleState ? (
                /*Toggled On/True EXPANDED State*/
                /*Toggled On/True EXPANDED State*/
                //#region Expanded

                <div className='container p-2 pt-0 bg-primary text-white rounded-2'>
                    <div className='row fs-5' /*EquipInfo Expanded*/>
                        {/*Toggle Button */}
                        <div className='col-auto px-1'>
                            <ROIcon
                                icon='ROicoUp'
                                className='icoSVG'
                                usefill='true'
                                color1='#ffffff'
                                onClickCallback={() =>
                                    setToggleState((prev) => !prev)
                                }
                            />
                        </div>
                        {/*Big Equipment Icon */}
                        <div className='col-auto px-1'>
                            <ROIcon
                                icon='ROicoEquip'
                                className='icoSVG'
                                usefill='true'
                                color1='#ffffff'
                                size={85}
                            />
                        </div>
                        <div className='col-6'>
                            <div className='row'>
                                {/*Equipment Code and Discription*/}
                                <div className='fw-bold col-auto'>
                                    {equipitem ? equipitem.EquipCode : ''}
                                    <span className='fw-normal'>
                                        &nbsp; - &nbsp;
                                        {equipitem ? equipitem.EquipName : ''}
                                    </span>
                                </div>
                                {/*Interface Buttons*/}
                                <div className='col-auto me-auto px-0'>
                                    <div>
                                        {editState ? (
                                            <>
                                                {boolCustLocEditEquipFormValidated ? (
                                                    <ROIcon
                                                        icon='ROicoCheck'
                                                        size={24}
                                                        className='icoSVG'
                                                        usefill='true'
                                                        color1='#ffffff'
                                                        tooltip='Update Equipment'
                                                        onClickCallback={() =>
                                                            editEquipCustLoc()
                                                        }
                                                    />
                                                ) : (
                                                    <ROIcon
                                                        icon='ROicoCheck'
                                                        size={24}
                                                        className='icoSVG'
                                                        usefill='true'
                                                        color1='#ff0000'
                                                        tooltip='Update Equipment'
                                                        onClickCallback={() =>
                                                            vaildateCustLocEditEquipForm(
                                                                true
                                                            )
                                                        }
                                                    />
                                                )}

                                                <ROIcon
                                                    icon='ROicoClose'
                                                    size={24}
                                                    className='icoSVG'
                                                    usefill='true'
                                                    color1='#ffffff'
                                                    tooltip='Cancel'
                                                    onClickCallback={() =>
                                                        editEquipCustLoc(false)
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <ROIcon
                                                icon='ROicoEdit'
                                                size={24}
                                                className='icoSVG'
                                                usefill='true'
                                                color1='#ffffff'
                                                tooltip='Edit Equipment'
                                                onClickCallback={() =>
                                                    editEquipCustLoc()
                                                }
                                            />
                                        )}

                                        {!editState ? (
                                            <>
                                                {confirmDelete ? (
                                                    <ROIcon
                                                        icon='ROicoDel'
                                                        size={24}
                                                        className='icoSVG danger'
                                                        usefill='true'
                                                        color1='#ff0000'
                                                        tooltip='Delete Equipment'
                                                        onClickCallback={() =>
                                                            confirmDeleteEquipCustLoc(
                                                                true
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    ' '
                                                )}
                                                {!confirmDelete ? (
                                                    <ROIcon
                                                        icon='ROicoDel'
                                                        size={24}
                                                        className='icoSVG'
                                                        usefill='true'
                                                        color1='#ffffff'
                                                        tooltip='Delete Equipment'
                                                        authlvl={5}
                                                        onClickCallback={() =>
                                                            confirmDeleteEquipCustLoc(
                                                                true
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {!editState ? (
                                            confirmDelete ? (
                                                <ROIcon
                                                    icon='ROicoClose'
                                                    size={24}
                                                    className='icoSVG'
                                                    usefill='true'
                                                    color1='#ffffff'
                                                    tooltip='Cancel Delete'
                                                    onClickCallback={() =>
                                                        confirmDeleteEquipCustLoc(
                                                            false
                                                        )
                                                    }
                                                />
                                            ) : (
                                                ''
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/*Equipment Details*/}
                            <div className='row fs-6'>
                                {/*Serial Nr*/}
                                <div className='col-4'>
                                    {editState ? (
                                        <input
                                            className='txtBox'
                                            value={txtEquipCustLocSerialNR}
                                            placeholder={
                                                equipcustlocitem
                                                    ? equipcustlocitem.EquipCustLocSerialNR
                                                    : ''
                                            }
                                            onChange={(e) => {
                                                setTxtEquipCustSerialNR(
                                                    e.target.value
                                                )
                                                vaildateCustLocEditEquipForm()
                                            }}
                                            ref={valEquipSerialNrRef}
                                            title='Equipment Serial Number'
                                            pattern='[a-zA-Z0-9@ (),.!&/\\\-_]+'
                                        />
                                    ) : equipcustlocitem ? (
                                        '# ' +
                                        equipcustlocitem.EquipCustLocSerialNR
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {/*Room Location*/}
                                <div className='col-4'>
                                    {editState ? (
                                        <ROComboBox
                                            dataArray={arrRoomPlace}
                                            placeholdertext={'Room / Place'}
                                            textVal={txtEquipCustLocRoomPlace}
                                            myValueCallback={
                                                setTxtEquipCustRoomPlace
                                            }
                                            ref={valEquipRoomPlaceRef}
                                            required={true}
                                            validationCallBack={() =>
                                                vaildateCustLocEditEquipForm()
                                            }
                                        />
                                    ) : // <input
                                    //     className='txtBox'
                                    //     value={txtEquipCustLocRoomPlace}
                                    //     placeholder={
                                    //         equipcustloc
                                    //             ? equipcustloc.EquipCustLocRoomPlace
                                    //             : ''
                                    //     }
                                    //     onChange={(e) =>
                                    //         setTxtEquipCustRoomPlace(e.target.value)
                                    //     }
                                    // />
                                    equipcustlocitem ? (
                                        equipcustlocitem.EquipCustLocRoomPlace
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {/*Interval*/}
                                <div className='col-4'>
                                    {editState ? (
                                        <div className='row'>
                                            <div className='col-5 px-1 fs-6'>
                                                <input
                                                    className='txtBox'
                                                    value={
                                                        txtEquipCustLocDaysTillNextService
                                                    }
                                                    placeholder='Service'
                                                    onChange={(e) => {
                                                        setTxtEquipCustDaysTillNextService(
                                                            e.target.value
                                                                .replace(
                                                                    /\D/g,
                                                                    ''
                                                                )
                                                                .slice(0, 3)
                                                        )
                                                        vaildateCustLocEditEquipForm()
                                                    }}
                                                    ref={
                                                        valEquipServiceIntervalValRef
                                                    }
                                                    title='Service Interval'
                                                    pattern='[0-9]+'
                                                    required
                                                />
                                            </div>

                                            <div className='col-4 px-1 fs-6'>
                                                <select
                                                    id='IntervalType'
                                                    className='txtBox'
                                                    name='Interval Type'
                                                    value={
                                                        txtEquipCustLocServiceIntervalType
                                                    }
                                                    ref={
                                                        txtEquipIntervalTypeRef
                                                    }
                                                    onChange={(e) =>
                                                        setTxtEquipCustServiceIntervalType(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value='1'>
                                                        Days
                                                    </option>
                                                    <option value='7'>
                                                        Weeks
                                                    </option>
                                                    <option value='30'>
                                                        Months
                                                    </option>
                                                    <option value='365'>
                                                        Years
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    ) : equipcustlocitem ? (
                                        getServiceInterval(equipcustlocitem)
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                            {/* Sales Rep ID */}
                            <div className='col-auto fs-6 me-auto '>
                                <ROIcon
                                    icon='ROicoUser'
                                    className='icoSVG'
                                    tooltip='Sales Person'
                                    usefill='true'
                                    color1='#ffffff'
                                />
                                {editState ? (
                                    <ROComboBox
                                        dataArray={arrSalesReps}
                                        placeholdertext={'Search Sales Rep'}
                                        textVal={getRepName(txtEquipRepID)}
                                        myIDCallback={setTxtEquipRepID}
                                        ref={valEquipSalesRef}
                                        required={true}
                                        validationCallBack={() =>
                                            vaildateCustLocEditEquipForm()
                                        }
                                        allowCustomValue={false}
                                    />
                                ) : equipcustlocitem ? (
                                    franusers.map((userItem) => {
                                        if (
                                            userItem._id ===
                                            equipcustlocitem.EquipCustLocSalesID
                                        ) {
                                            return (
                                                userItem.name +
                                                ' (' +
                                                userItem.email +
                                                ')'
                                            )
                                        }
                                    })
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        {/*Toggle Icon*/}
                        {/* <div className='col-auto px-2'> */}
                        {/*Expande/Collapse Icon*/}
                        {/* {editState ? (
                                ''
                            ) : (
                                <ROIcon
                                    icon='ROicoTup'
                                    className='icoSVG'
                                    usefill='true'
                                    color1='#ffffff'
                                    onClickCallback={() =>
                                        setToggleState((prev) => !prev)
                                    }
                                />
                            )} */}

                        {/* </div> */}
                    </div>
                    <div className='row p-2' /*EquipInfo Expanded*/>
                        <AllServicesItem
                            containerBackColor=''
                            equipcustlocitem={equipcustlocitem}
                        />
                    </div>
                </div>
            ) : (
                //#endregion
                /*Toggled Off/False COMPACT State */
                /*Toggled Off/False COMPACT State*/
                //#region Compact
                <div
                    className='container bg-primary text-white p-1 rounded-2'
                    onClick={() => setToggleState((prev) => !prev)}
                >
                    <div
                        className='row fs-5 align-items-center px-2' /*EquipInfo Compact*/
                    >
                        <div className='col-auto px-1'>
                            <ROIcon
                                icon='ROicoDown'
                                className='icoSVG'
                                usefill='true'
                                color1='#ffffff'
                                // onClickCallback={changeToggleState}
                            />
                        </div>
                        <div className='fw-bold col-5 pl-2'>
                            <ROIcon
                                icon='ROicoEquip'
                                usefill='true'
                                color1='#ffffff'
                                size={24}
                                className='icoSVG'
                            />
                            {equipitem ? equipitem.EquipCode : ''}

                            <span className='fw-normal'>
                                &nbsp; - &nbsp;
                                {equipitem ? equipitem.EquipName : ''}
                            </span>
                        </div>
                        <div className='col-2 pl-2 fs-6 '>
                            {equipcustlocitem.EquipCustLocRoomPlace
                                ? equipcustlocitem.EquipCustLocRoomPlace
                                : ''}
                        </div>
                        <div className='col-2 pl-2 fs-6'>
                            {getServiceInterval(equipcustlocitem)}
                        </div>
                        <div className='col-2 pl-2 fs-6 fw-bolder'>
                            <ROIcon
                                icon='ROicoService'
                                className='icoSVG'
                                usefill='true'
                                color1='#ffffff'
                                tooltip='Last Service Date'
                            />
                            {equipcustlocitem.EquipCustLocLastServiceDate
                                ? formatShortDate(
                                      equipcustlocitem.EquipCustLocLastServiceDate
                                  )
                                : ''}
                        </div>
                    </div>
                </div>
            )}
        </ContentBox>
    )
}

export default EquipCustLocItem
