import axios from 'axios'

const API_URL = '/api/customers/locations/equipment/'

//Add new Equipment on location
const createEquipCustLoc = async (equipcustlocData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.post(API_URL, equipcustlocData, headersConfig)
    console.log('equipcustlocService(createEquipCustLoc)  response: ', response)
    return response.data
}

//Update Equipment on location
const updateEquipCustLoc = async (equipcustlocData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.put(
        API_URL + equipcustlocData.equipcustlocID,
        equipcustlocData,
        headersConfig
    )

    return response.data
}

//Get Equipment for location
const getEquipCustLoc = async (userCreds) => {
    try {
        const headersConfig = {
            headers: {
                Authorization: `Bearer ${userCreds.token}`,
            },
        }

        const response = await axios.get(API_URL, headersConfig, userCreds)

        //console.log('getEquipCustLoc response: ', response)

        return response.data
    } catch (error) {
        console.log(
            'equipcustlocService getEquipCustLoc response: ',
            error.response
        )
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback
        //This seems like more verbose error feedback

        if (error.response.status === 401) {
            throw new Error(error.response.statusText + ' Please Log In')
        }

        throw new Error(
            'equipcustlocService(getEquipCustLoc): ' + error.response.data
        )
    }
}

//Delete Equipment on location
const deleteEquipCustLoc = async (equipcustlocID, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.delete(API_URL + equipcustlocID, headersConfig)

    return response.data
}

const equipcustlocService = {
    createEquipCustLoc,
    getEquipCustLoc,
    deleteEquipCustLoc,
    updateEquipCustLoc,
}

export default equipcustlocService
