import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { toast } from 'react-toastify'
import { UserRoles } from '../features/globalVars'
import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'
import ROComboBox from '../components/ROComboBox'
import { VaildateForm } from '../features/globalFunctions'

//Import Franchise User Redux State/Global Var and Axiox database calls
import {
    createUser,
    getUsers,
    deleteUser,
    resetusers,
} from '../features/users/usersSlice'
import UserItem from '../components/UserItem'

const Users = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        tel: '',
        usertype: 99,
        password: '',
        password2: '',
    })

    const { name, email, tel, usertype, password, password2 } = formData

    const { user } = useSelector((state) => state.auth)

    const [showNewUser, setShowNewUser] = useState(false)

    //Get State users from redux
    const { franusers, isFUsersError, isFUsersSuccess, franusersmessage } =
        useSelector((state) => state.franusers)

    useEffect(() => {
        //Error Handeling Check 22 Nov 2022
        if (isFUsersError) {
            console.log('Users useEffect error: ', franusersmessage)
            navigate('/')
            toast.error(franusersmessage)
            return
        }

        if (!user || !user._id || !user.token) {
            dispatch(logout())
            dispatch(reset())
            navigate('/')
            return
        }

        try {
            console.log('Get All Data from servers')
            dispatch(getUsers())
        } catch (error) {
            console.log('Template.jsx Dispatch(all) Error: ', error)
            toast.error(error)
            return () => dispatch(reset())
        }
    }, [navigate, user, dispatch, isFUsersError])
    //Error Handeling Check 22 Nov 2022 added isFUsersError use effect check

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const comboBoxSetRole = (value) => {
        setFormData((prevState) => ({
            ...prevState,
            usertype: value,
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (password !== password2) {
            toast.error('Passwords do not match')
        } else {
            const userData = {
                name,
                email,
                tel,
                franid: user.franid,
                usertype,
                password,
            }

            //Sends userData to authSlice -> import { register, reset } from '../features/auth/authSlice'
            dispatch(createUser(userData))

            clearForm()
        }
    }

    const clearForm = () => {
        setFormData({
            name: '',
            email: '',
            tel: '',
            usertype: 99,
            password: '',
            password2: '',
        })

        setShowNewUser(false)
    }

    //Delete User
    const [confirmDelete, setDeleteState] = useState(false)
    const [deleteUserObj, setDeleteUserObj] = useState({})

    const confirmDeleteUser = (setState) => {
        if (
            confirmDelete &&
            setState &&
            deleteUserObj.usertype > 1 &&
            deleteUserObj._id !== user._id
        ) {
            console.log('Delete!!!! UserID: ', deleteUserObj._id)
            deleteUserById(deleteUserObj._id)
        } else if (deleteUserObj._id === user._id) {
            console.log('Cannot Delete Self')
        } else if (deleteUserObj.usertype <= 1) {
            console.log('Cannot Delete Owner')
        }
        setDeleteState(setState)
    }

    const deleteUserById = (_id) => {
        console.log('Delete!!!! User_ID: ', _id)
        dispatch(deleteUser(_id))
        setDeleteUserObj({})
        confirmDeleteUser(false)
    }

    const deleteUserCall = (userToDelete) => {
        console.log('deleteUserCall: ', userToDelete)
        setDeleteUserObj(userToDelete)
        confirmDeleteUser(true)
    }

    //User Roles
    let arrRoles = []
    if (UserRoles) {
        arrRoles = Array.from(UserRoles)
        console.log('arrRoles: ', arrRoles)
        arrRoles = arrRoles.map((item, i) => {
            return { Text: item.title, ID: item.authlvl }
        })

        arrRoles.shift()
        arrRoles.pop()

        //console.log('arrCategories: ', arrRoles)
    }

    //Just a state variable to call to trigger a rerender for collapse change
    const [getCollapseAll, setCollapseAll] = useState(false)

    const CollapseAll = () => {
        setCollapseAll(true)
    }

    useEffect(() => {
        console.log('Use Effect Collapse All')
        if (getCollapseAll) {
            setCollapseAll(false)
        }
    }, [getCollapseAll])

    //#region Edit User Form Validation
    const [boolUserFormValidated, setUserFormValidated] = useState(false)

    const valUserNameRef = useRef()
    const valUserEmailRef = useRef()
    const valUserContactNumRef = useRef()
    const valUserPasswordRef = useRef()
    const valUserConfirmPasswordRef = useRef()
    const valUserRoleRef = useRef()

    const vaildateNewUserForm = (clicked = false) => {
        if (!showNewUser) return

        if (showNewUser) {
            setUserFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valUserNameRef,
                            text: 'No Special Characters',
                            form: 'New User valUserNameRef',
                        },
                        {
                            ref: valUserEmailRef,
                            text: 'Enter Valid Email',
                            form: 'New User valUserEmailRef',
                        },
                        {
                            ref: valUserContactNumRef,
                            text: 'No Special Characters',
                            form: 'New User valUserContactNumRef',
                        },
                        {
                            ref: valUserPasswordRef,
                            text: 'Password required',
                            form: 'New User valUserPasswordRef',
                        },
                        {
                            ref: valUserConfirmPasswordRef,
                            text: 'Comfirm Password required',
                            form: 'New User valUserConfirmPasswordRef',
                        },
                        {
                            ref: valUserRoleRef,
                            text: 'User Role Required',
                            form: 'New User valUserRoleNumRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    // useEffect(() => {
    //     //REdraw on validation change
    //     vaildateServiceForm()
    //     //Do validation on ROCombobox changes Service Rep ID
    // }, [txtServiceRepID])
    //#endregion

    return (
        <>
            <section className='pageHead container'>
                <div className='pageFunctions row header sticky-top py-3'>
                    <div className='col-4'>
                        <h4>
                            Your Users&nbsp;
                            <ROIcon
                                icon={showNewUser ? 'ROicoClose' : 'ROicoPlus'}
                                size={24}
                                className='icoSVG'
                                textbefore={showNewUser ? 'Cancel' : ' '}
                                tooltip={showNewUser ? 'Cancel' : 'Add User'}
                                onClickCallback={() =>
                                    setShowNewUser((prev) => !prev)
                                }
                            />
                        </h4>
                    </div>
                    <div className='interfSearch align-middle col-4 me-auto'></div>
                    <div className='align-middle col-auto'>
                        <ROIcon
                            icon='ROicoUp'
                            size={24}
                            className='icoSVG'
                            tooltip='Collapse All'
                            onClickCallback={() => CollapseAll()}
                        />
                        <ROIcon
                            icon='ROicoHelp'
                            size={24}
                            className='icoSVG'
                            tooltip='Help'
                        />
                    </div>

                    {showNewUser ? (
                        <div className='content border border-primary bg-light'>
                            <section className='form'>
                                <form onSubmit={onSubmit}>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='name'
                                            name='name'
                                            value={name}
                                            placeholder='Enter user name'
                                            onChange={(e) => {
                                                onChange(e)
                                                vaildateNewUserForm()
                                            }}
                                            ref={valUserNameRef}
                                            title='User Name'
                                            pattern='[a-zA-Z0-9@ (),.!&/\\\-_]+'
                                            required
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <input
                                            type='email'
                                            className='form-control'
                                            id='email'
                                            name='email'
                                            value={email}
                                            placeholder='Enter user email'
                                            onChange={(e) => {
                                                onChange(e)
                                                vaildateNewUserForm()
                                            }}
                                            ref={valUserEmailRef}
                                            title='User Email'
                                            required
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <input
                                            type='password'
                                            className='form-control'
                                            id='password'
                                            name='password'
                                            value={password}
                                            placeholder='Enter user password'
                                            onChange={(e) => {
                                                onChange(e)
                                                vaildateNewUserForm()
                                            }}
                                            ref={valUserPasswordRef}
                                            title='User Password'
                                            required
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <input
                                            type='password'
                                            className='form-control'
                                            id='password2'
                                            name='password2'
                                            value={password2}
                                            placeholder='Confirm password'
                                            onChange={(e) => {
                                                onChange(e)
                                                vaildateNewUserForm()
                                            }}
                                            ref={valUserConfirmPasswordRef}
                                            title='User Confirm Password'
                                            required
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='tel'
                                            name='tel'
                                            value={tel}
                                            placeholder='Enter contact number'
                                            onChange={(e) => {
                                                onChange(e)
                                                vaildateNewUserForm()
                                            }}
                                            ref={valUserContactNumRef}
                                            title='Contact Number'
                                            pattern='[0-9 +()-]+'
                                            required
                                        />
                                    </div>
                                    <div className='form-group py-2 m-0'>
                                        <ROComboBox
                                            dataArray={arrRoles}
                                            classname='ROdropdownFull p-0 m-0'
                                            placeholdertext='User Role'
                                            myIDCallback={comboBoxSetRole}
                                            ref={valUserRoleRef}
                                            required={true}
                                            validationCallBack={() =>
                                                vaildateNewUserForm()
                                            }
                                            allowCustomValue={false}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        {boolUserFormValidated ? (
                                            <button
                                                type='submit'
                                                className='btn btn-success btn-block'
                                            >
                                                Submit
                                            </button>
                                        ) : (
                                            <button
                                                type='button'
                                                className='btn btn-danger btn-block'
                                                onClick={() =>
                                                    vaildateNewUserForm(true)
                                                }
                                            >
                                                Submit
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </section>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </section>

            <div className='pageContent container'>
                {franusers.map((userObj, i) => {
                    if (!userObj.usersuspended && userObj.usertype > 0)
                        return (
                            <div>
                                <UserItem
                                    userData={userObj}
                                    arrRoles={arrRoles}
                                    containerBackColor={
                                        (i + 1) % 2 === 0
                                            ? 'bg-white'
                                            : 'bg-secondary'
                                    }
                                    collapse={getCollapseAll}
                                />
                            </div>
                        )
                })}
            </div>
        </>
    )
}

export default Users
