import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import custReducer from '../features/cust/custSlice'
import custlocReducer from '../features/custloc/custlocSlice'
import custloccontReducer from '../features/custloccontacts/custloccontactsSlice'
import equipReducer from '../features/equip/equipSlice'
import partsReducer from '../features/parts/partsSlice'
import equipcustlocReducer from '../features/equipcustloc/equipcustlocSlice'
import servicesReducer from '../features/services/servicesSlice'
import usersReducer from '../features/users/usersSlice'

//Slices or global states (store) to keep track of server request statuses like: isError,isSuccess,isLoading
export const store = configureStore({
    reducer: {
        auth: authReducer,
        customers: custReducer,
        loccustomers: custlocReducer,
        loccustcontacts: custloccontReducer,
        equipment: equipReducer,
        parts: partsReducer,
        equipcustloc: equipcustlocReducer,
        service: servicesReducer,
        franusers: usersReducer,
    },
})
