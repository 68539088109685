//Not sure if this is a good idea or not.
//A Main Template component container which all other components as created in.
//At the moment a kinda global backup plan if we need to add something to the whole app

// **** Main Template component container ****
const ContentBox = (props) => {
    let { classTag = 'content' } = props

    return <div className={classTag}>{props.children}</div>
}

export default ContentBox
