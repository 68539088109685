import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { toast } from 'react-toastify'
import { getParts, resetparts } from '../features/parts/partsSlice'
import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'
import ROComboBox from '../components/ROComboBox'
import { VaildateForm } from '../features/globalFunctions'

//Import Customer Redux State/Global Var and Axiox database calls
import { createPart } from '../features/parts/partsSlice.js'
import ContentBox from '../components/ContentBox'
import PartItem from '../components/PartItem'

const Parts = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user, isError, message } = useSelector((state) => state.auth)

    console.log('*** Parts Main Draw ***')

    //New Part state varibales
    const [showNewPart, setShowNewPart] = useState(false)
    const [txtPartName, setTxtPartName] = useState('')
    const [txtPartCode, setTxtPartCode] = useState('')
    const [txtPartPrice, setTxtPartPrice] = useState('')
    const [txtPartCategory, setTxtPartCategory] = useState('')

    //Add New Part
    const AddNewPart = () => {
        const newPart = {
            franID: user.franid,
            partcode: txtPartCode,
            partname: txtPartName,
            partserialnr: '',
            partlastprice: txtPartPrice,
            partcategory: txtPartCategory,
            partimageurl: '',
            partnotes: '',
            partflags: '',
        }

        setTxtPartCode('')
        setTxtPartName('')
        setTxtPartPrice('')
        setTxtPartCategory('')
        setShowNewPart(false)

        dispatch(createPart(newPart))
    }

    //Get State parts from redux
    const { parts, isPartsError, partsmessage } = useSelector(
        (state) => state.parts
    )

    //Categories
    let arrCategories = []
    if (parts) {
        arrCategories = new Set(parts.map((part) => part.PartCategory))
        arrCategories = Array.from(arrCategories)
        arrCategories = arrCategories.map((item, i) => {
            return { Text: item }
        })
    }

    useEffect(() => {
        if (isError) {
            console.log('Part USERS useEffect error: ', message)
            navigate('/')
            toast.error(message)
            return
        }

        if (isPartsError) {
            console.log('Parts useEffect error: ', partsmessage)
            toast.error(partsmessage)
            navigate('/')
            return
        }

        if (!user || !user._id || !user.token) {
            dispatch(logout())
            dispatch(reset())
            navigate('/')
            return
        }

        try {
            console.log('Get All Data from servers')
            dispatch(getParts())
        } catch (error) {
            console.log('Parts.jsx Dispatch(all) Error: ', error)
            toast.error(error)
            return () => dispatch(reset())
        }

        return () => dispatch(reset())
    }, [navigate, user, dispatch, isError, isPartsError])

    //Collapse All
    //Just a state variable to call to trigger a rerender for collapse change
    const [getCollapseAll, setCollapseAll] = useState(false)

    const CollapseAll = () => {
        setCollapseAll(true)
    }

    useEffect(() => {
        console.log('Use Effect Collapse All')
        if (getCollapseAll) {
            setCollapseAll(false)
        }
    }, [getCollapseAll])

    //Search Parts
    const [partsSearchData, setPartsSearchData] = useState([])
    const [searchResults, setSearchResults] = useState([])
    let [searchString, setSearchString] = useState('')
    const [searchFound, setSearchFound] = useState(false)

    const SearchParts = (_searchstr) => {
        setSearchString(_searchstr)

        //console.log('search : ', _searchstr)

        if (_searchstr === '') {
            setSearchResults(parts)
            setSearchFound(true)
        } else {
            _searchstr = _searchstr.toLowerCase()

            let tempResults = []

            if (parts) {
                tempResults = parts.filter((item) => {
                    return (
                        String(item.PartCode)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        String(item.PartName)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        String(item.PartLastPrice)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        String(item.PartCategory)
                            .toLowerCase()
                            .includes(_searchstr)
                    )
                })
            }

            if (tempResults === null || tempResults.length === 0) {
                setSearchResults(parts)
                setSearchFound(false)
            } else {
                setSearchResults(tempResults)
                setSearchFound(true)
            }
        }
    }

    //Only update/redraw if search results changed. Or else it will redraw on each letter type even if results didn't change
    //used to check if customers is populated and to only search once on init
    if (
        parts != null &&
        parts.length > 0 &&
        JSON.stringify(partsSearchData) !== JSON.stringify(parts)
    ) {
        //console.log('more search')
        setPartsSearchData(parts)
        SearchParts(searchString)
    }

    //#region New Part Form Validation
    const [boolPartFormValidated, setPartFormValidated] = useState(false)

    const valPartCodeRef = useRef()
    const valPartNameRef = useRef()
    const valPartPriceRef = useRef()
    const valPartCategoryRef = useRef()

    const vaildatePartNewPartForm = (clicked = false) => {
        if (!showNewPart) return

        if (showNewPart) {
            setPartFormValidated(
                VaildateForm(
                    [
                        {
                            ref: valPartCodeRef,
                            text: 'No Special Characters',
                            form: 'New Part valPartCodeRef',
                        },
                        {
                            ref: valPartNameRef,
                            text: 'No Special Characters',
                            form: 'New Part valPartNameRef',
                        },
                        {
                            ref: valPartPriceRef,
                            text: 'Enter valid price',
                            form: 'New Part valPartPriceRef',
                        },
                        {
                            ref: valPartCategoryRef,
                            text: 'Part Category Required',
                            form: 'New Part valPartCategoryRef',
                        },
                    ],
                    clicked
                )
            )
            return
        }
    }

    // useEffect(() => {
    //     //REdraw on validation change
    //     vaildateServiceForm()
    //     //Do validation on ROCombobox changes Service Rep ID
    // }, [txtServiceRepID])
    //#endregion

    return (
        <>
            <section className='pageHead container clearfix'>
                <div className='pageFunctions row header sticky-top py-3'>
                    <div className='col-4'>
                        <h4>
                            Your Parts &nbsp;
                            <ROIcon
                                icon={showNewPart ? 'ROicoClose' : 'ROicoPlus'}
                                size={24}
                                className='icoSVG'
                                textbefore={showNewPart ? 'Cancel' : ' '}
                                tooltip={
                                    showNewPart ? 'Cancel' : 'Add New Part '
                                }
                                onClickCallback={() =>
                                    setShowNewPart((prev) => !prev)
                                }
                            />
                        </h4>
                    </div>
                    {/* SEARCH */}
                    <div className='interfSearch align-middle col-4 me-auto'>
                        {/* <label htmlFor='search'>Search</label> */}
                        <input
                            id='search'
                            placeholder='Enter your search here'
                            value={searchString}
                            onChange={(e) => SearchParts(e.target.value)}
                        />
                        {searchFound ? (
                            ''
                        ) : (
                            <p className='danger'>
                                Search doens't match any part
                            </p>
                        )}
                    </div>
                    <div className='align-middle col-auto'>
                        <ROIcon
                            icon='ROicoUp'
                            size={24}
                            className='icoSVG'
                            tooltip='Collapse All'
                            onClickCallback={() => CollapseAll()}
                        />
                        <ROIcon
                            icon='ROicoHelp'
                            size={24}
                            className='icoSVG'
                            tooltip='Help'
                        />
                    </div>

                    {showNewPart ? (
                        <>
                            <div className='container p-2 pt-0 bg-light rounded-2'>
                                Add New Parts:
                                <div className='row' /*PartInfo Expanded*/>
                                    <div className='ROmaintxt col-3'>
                                        <input
                                            className='txtBox'
                                            value={txtPartCode}
                                            placeholder='Product Code'
                                            onChange={(e) => {
                                                setTxtPartCode(e.target.value)
                                                vaildatePartNewPartForm()
                                            }}
                                            ref={valPartCodeRef}
                                            title='Part Code'
                                            pattern='[a-zA-Z0-9@ (),.!&/\\\-_]+'
                                            required
                                        />
                                    </div>
                                    <div className='col-auto me-auto px-0'>
                                        <>
                                            {boolPartFormValidated ? (
                                                <ROIcon
                                                    icon='ROicoCheck'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Add Part'
                                                    onClickCallback={() =>
                                                        AddNewPart()
                                                    }
                                                />
                                            ) : (
                                                <ROIcon
                                                    icon='ROicoCheck'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Add Part'
                                                    onClickCallback={() =>
                                                        vaildatePartNewPartForm(
                                                            true
                                                        )
                                                    }
                                                    usefill={true}
                                                    color1='#ff0000'
                                                />
                                            )}

                                            <ROIcon
                                                icon='ROicoClose'
                                                size={24}
                                                className='icoSVG'
                                                tooltip='Cancel'
                                                onClickCallback={() =>
                                                    setShowNewPart(
                                                        (prev) => !prev
                                                    )
                                                }
                                            />
                                        </>
                                    </div>
                                </div>
                                <div className='row' /*PartInfo Expanded*/>
                                    <div className='ROnormaltxt col-6 fs-5'>
                                        {' '}
                                        <input
                                            className='txtBox'
                                            value={txtPartName}
                                            placeholder='Product Name'
                                            onChange={(e) => {
                                                setTxtPartName(e.target.value)
                                                vaildatePartNewPartForm()
                                            }}
                                            ref={valPartNameRef}
                                            title='Part Name'
                                            pattern='[a-zA-Z0-9@ (),.!&/\\\-_]+'
                                            required
                                        />
                                    </div>
                                    <div className='ROnormaltxt col-2 fs-5'>
                                        {' '}
                                        <input
                                            className='txtBox'
                                            value={txtPartPrice}
                                            placeholder='Latest Price'
                                            onChange={(e) => {
                                                setTxtPartPrice(
                                                    e.target.value.replace(
                                                        /[^0-9\.]+/g,
                                                        ''
                                                    )
                                                )
                                                vaildatePartNewPartForm()
                                            }}
                                            ref={valPartPriceRef}
                                            title='Part Price'
                                            pattern='^[0-9]\d*(\.\d+)?$'
                                            required
                                        />
                                    </div>
                                    <div className='ROnormaltxt col-4 fs-5'>
                                        {' '}
                                        <ROComboBox
                                            dataArray={arrCategories}
                                            placeholdertext='Category'
                                            myValueCallback={setTxtPartCategory}
                                            textVal={txtPartCategory}
                                            ref={valPartCategoryRef}
                                            required={true}
                                            validationCallBack={() =>
                                                vaildatePartNewPartForm()
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </section>
            <div className='pageContent container'>
                {searchResults.map((part, i) => {
                    return (
                        <PartItem
                            key={myUUid()}
                            part={part}
                            arrCategories={arrCategories}
                            collapse={getCollapseAll}
                            containerBackColor={
                                (i + 1) % 2 === 0 ? 'bg-white' : 'bg-secondary'
                            }
                        />
                    )
                })}
            </div>
        </>
    )
}

export default Parts
