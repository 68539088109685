/* ******************************************

    BASIC TEMPLATE LAYOUT FOR NEW PAGES

    DO NOT USE OR MODIFY - MAKE COPY

****************************************** */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { toast } from 'react-toastify'
import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'
import AllReports from '../components/reports/AllReports'

//Import Customers Redux State/Global Var and Axiox database calls
import { getCustomers, resetCust } from '../features/cust/custSlice'

//Import Franchise User Redux State/Global Var and Axiox database calls
import { getUsers, resetusers } from '../features/users/usersSlice'

//Import Customer Locations Redux State/Global Var and Axiox database calls
import {
    getCustomersLoc,
    resetLocations,
} from '../features/custloc/custlocSlice'

//Import Customer Location Contacts Redux State/Global Var and Axiox database calls
import {
    getCustomerLocContacts,
    resetLocContacts,
} from '../features/custloccontacts/custloccontactsSlice'

//Import Customer Location Equipment Contacts Redux State/Global Var and Axiox database calls
import { getEquipment, resetequip } from '../features/equip/equipSlice'

import { getParts, resetparts } from '../features/parts/partsSlice'

//import equipcustlocService from '../features/equipcustloc/equipcustlocService'
import { getEquipCustLoc } from '../features/equipcustloc/equipcustlocSlice'

//Import Services Redux State/Global Var and Axiox database calls
import {
    getServices,
    resetservices,
} from '../features/services/servicesSlice.js'

const Reports = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user, isError, message } = useSelector((state) => state.auth)

    const { isCustError, custmessage } = useSelector((state) => state.customers)

    const { isLocError, locmessage } = useSelector(
        (state) => state.loccustomers
    )

    const { isErrorLocContacts, custloccontmessage } = useSelector(
        (state) => state.loccustcontacts
    )

    const { isPartsError, partsmessage } = useSelector((state) => state.parts)

    const { isEquipError, equipmessage } = useSelector(
        (state) => state.equipment
    )

    const { isEquipCustLocError, equipcustlocmessage } = useSelector(
        (state) => state.equipcustloc
    )

    const { isServicesError, servicesmessage } = useSelector(
        (state) => state.service
    )

    const { isFUsersError, franusersmessage } = useSelector(
        (state) => state.franusers
    )

    useEffect(() => {
        if (isError) {
            console.log('Reports useEffect error: ', message)
            toast.error(message)
            navigate('/')
            return
        }

        if (isCustError) {
            console.log('Reports Customer useEffect error: ', custmessage)
            toast.error(custmessage)
            navigate('/')
            return
        }

        if (isLocError) {
            console.log('Reports Location useEffect error: ', locmessage)
            toast.error(locmessage)
            navigate('/')
            return
        }

        if (isErrorLocContacts) {
            console.log(
                'Reports Loc Contact useEffect error: ',
                custloccontmessage
            )
            toast.error(custloccontmessage)
            navigate('/')
            return
        }

        if (isPartsError) {
            console.log('Reports Part useEffect error: ', partsmessage)
            toast.error(partsmessage)
            navigate('/')
            return
        }

        if (isEquipError) {
            console.log('Reports equipment useEffect error: ', equipmessage)
            toast.error(equipmessage)
            navigate('/')
            return
        }

        if (isEquipCustLocError) {
            console.log('Reports useEffect error: ', equipcustlocmessage)
            toast.error(equipcustlocmessage)
            navigate('/')
            return
        }

        if (isServicesError) {
            console.log('Reports useEffect error: ', servicesmessage)
            toast.error(servicesmessage)
            navigate('/')
            return
        }

        if (isFUsersError) {
            console.log('Reports useEffect error: ', franusersmessage)
            toast.error(franusersmessage)
            navigate('/')
            return
        }

        if (!user || !user._id || !user.token) {
            dispatch(logout())
            dispatch(reset())
            navigate('/')
        }

        try {
            console.log('Get All Data from servers')
            dispatch(getUsers())
            dispatch(getCustomers())
            dispatch(getCustomersLoc())
            dispatch(getCustomerLocContacts())
            dispatch(getEquipment())
            dispatch(getEquipCustLoc())
            dispatch(getParts())
            dispatch(getServices())
        } catch (error) {
            console.log('Reports.jsx Dispatch(all) Error: ', error)
            toast.error(error)
            return () => dispatch(resetCust())
        }
    }, [
        user,
        isError,
        isCustError,
        isLocError,
        isErrorLocContacts,
        isPartsError,
        isEquipError,
        isEquipCustLocError,
        isServicesError,
        isFUsersError,
    ])

    return (
        <>
            <section className='pageHead container'>
                {/* <h6>Welcome {user && user.name}</h6> */}
                <h4>Your Reports</h4>
                <section className='pageFunctions container'></section>
            </section>
            <div className='pageContent container'>
                <AllReports />
            </div>
        </>
    )
}

export default Reports
