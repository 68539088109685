import React from 'react'
import { useState } from 'react'
import ROIcon from '../../components/ROIcon'
import RepServiceOverdueItem from './RepServiceOverdueItem'
import { daysDiff } from '../../features/globalFunctions'

//import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import myUUid from '../../features/myUUid'

const RepServiceOverdue = () => {
    //Get State user from redux
    const { user } = useSelector((state) => state.auth)
    //Get State users / staff from redux
    const { franusers } = useSelector((state) => state.franusers)
    //Get State customers from redux
    const { customers } = useSelector((state) => state.customers)
    //Get State customer locations from redux
    const { loccustomers } = useSelector((state) => state.loccustomers)
    //Get State customer location contacts from redux
    const { custloccontacts } = useSelector((state) => state.loccustcontacts)
    //Get State equipment from redux
    const { equipment } = useSelector((state) => state.equipment)
    //Get State parts from redux
    const { parts } = useSelector((state) => state.parts)
    //Get State location equipment from redux
    const { equipcustloc } = useSelector((state) => state.equipcustloc)
    //#region All Services
    const { services } = useSelector((state) => state.service)

    const [toggleAllRecentServices, setAllRecentServices] = useState(false)
    //let arrServices = {CustomerID: 0, CustomerName: 'CustName', CustomerTel: '084', CustomerPrimaryContact: 'PriCont', CustomerPrimaryEmail: 'Pri@email'}

    let arrServOver = [] //{EquipCustLocID: 0, CustID: 0, CustLocID: 0, equipServices: []}

    try {
        //Sort Services....
        //First Group by EquipCustLocID
        //Then check if there are more than 3 services remove the older services (shift)
        if (
            services &&
            services.length > 0 &&
            loccustomers &&
            loccustomers.length > 0 &&
            equipcustloc &&
            equipcustloc.length > 0 &&
            equipment &&
            equipment.length > 0
        ) {
            console.log('Services: ', services)

            services.map((item, i) => {
                let tempItem = arrServOver.find(
                    (e) => e.EquipCustLocID === item.EquipCustLocID
                )

                let lastServiceDate = item.ServiceDate

                if (tempItem) {
                    //A Equipment on Location with a service is already added to the array
                    //Just update the array with new service info

                    // console.log(
                    //     'Already added to array: ',
                    //     arrServOver,
                    //     '  tempItem: ',
                    //     tempItem
                    // )

                    if (tempItem.equipServices.length < 3) {
                        tempItem.equipServices.push(item)
                        if (tempItem.equipServices.length === 1) {
                            tempItem.LastServiceRecorded = lastServiceDate
                        }

                        //shifted when the services was ascending by date but we changes it to descending by date in die backend
                        //tempItem.equipServices.shift()
                    }

                    // arrServOver = [
                    //     ...arrServOver,
                    //     { ...tempItem, LastServiceRecorded: lastServiceDate },
                    // ]

                    //Double check last service date for in case a new service was deleted and the EquipCustLoc Last Service date didn't update correctly... (need to fix)
                } else {
                    let locItem = loccustomers.find(
                        (e) => e.CustLocID === item.CustLocID
                    )
                    let equipItem = equipcustloc.find(
                        (e) => e.EquipCustLocID === item.EquipCustLocID
                    )
                    let contacts = custloccontacts.filter((e) => {
                        if (e.CustLocID === item.CustLocID) {
                            return e
                        } else {
                            return null
                        }
                    })

                    if (!equipItem.EquipID) {
                        console.log(
                            '***********  Error equipItem.EquipID is undefined/null equipItem: ',
                            equipItem
                        )
                    }

                    let equipInfo = equipment.find(
                        (e) => e.EquipID === equipItem.EquipID
                    )
                    // let equipInfo = equipment.find(
                    //     (e) =>
                    //         e.EquipID ===
                    //         (equipItem.EquipID ? equipItem.EquipID : -1)
                    // )

                    console.log('contacts: ', contacts)

                    arrServOver.push({
                        EquipCustLocID: item.EquipCustLocID,
                        CustID: item.CustID,
                        CustLocID: item.CustLocID,
                        EquipID: item.EquipID,
                        CustName: locItem.CustName,
                        CustLocName: locItem.CustLocName,
                        CustLocStreetAddress: locItem.CustLocStreetAddress,
                        CustLocSuburb: locItem.CustLocSuburb,
                        CustLocCity: locItem.CustLocCity,
                        CustLocPostalCode: locItem.CustLocPostalCode,
                        CustLocCountry: locItem.CustLocCountry,
                        CustPriContactName: contacts
                            ? contacts.find(
                                  (e) => e.CustLocContactPrimary === 1
                              ).CustLocContactName
                            : console.log('contacts empty: ', custloccontacts),
                        CustPriContactEmail: contacts.find(
                            (e) => e.CustLocContactPrimary === 1
                        ).CustLocContactEmail,
                        CustPriContactNumber: contacts.find(
                            (e) => e.CustLocContactPrimary === 1
                        ).CustLocContactNumber,
                        LastServiceRecorded: lastServiceDate,
                        EquipCustLoc: equipItem,
                        Contacts: contacts,
                        EquipInfo: equipInfo,
                        equipServices: [item],
                    })
                }
            })

            //Filter to show all services overdue by interval amount and current date
            if (!toggleAllRecentServices) {
                arrServOver = arrServOver.filter((item) => {
                    if (
                        daysDiff(new Date(), item.LastServiceRecorded) >
                        item.EquipCustLoc.EquipCustLocDaysTillNextService
                    ) {
                        return item
                    }
                })
            }

            console.log('arrServOver: ', arrServOver)
        } else {
            //TO DO REMOVE THIS TESTING FEEDBACK LOGS
            //if (services && loccustomers && equipcustloc && equipment) {
            console.log('Services: ', services)
            console.log('loccustomers: ', loccustomers)
            console.log('equipcustloc: ', equipcustloc)
            console.log('equipment: ', equipment)
        }

        //Group Equipment by CustID
        let arrServOverByCustID = []

        arrServOver.map((item) => {
            let tempItem = arrServOverByCustID.find(
                (e) => e.CustID === item.CustID
            )

            if (tempItem) {
                tempItem.Equipment.push(item)
            } else {
                arrServOverByCustID.push({
                    CustLocID: item.CustLocID,
                    EquipCustLocID: item.EquipCustLocID,
                    CustID: item.CustID,
                    EquipID: item.EquipID,
                    CustName: item.CustName,
                    CustLocName: item.CustLocName,
                    CustLocStreetAddress: item.CustLocStreetAddress,
                    CustLocSuburb: item.CustLocSuburb,
                    CustLocCity: item.CustLocCity,
                    CustLocPostalCode: item.CustLocPostalCode,
                    CustLocCountry: item.CustLocCountry,
                    CustPriContactName: item.CustPriContactName,
                    CustPriContactEmail: item.CustPriContactEmail,
                    CustPriContactNumber: item.CustPriContactNumber,
                    LastServiceRecorded: item.LastServiceRecorded,
                    Contacts: item.Contacts,

                    Equipment: [item],
                })
            }
        })

        console.log('arrServOverByCustID: ', arrServOverByCustID)

        let arrServOverByCustLocID = []

        arrServOver.map((item) => {
            let tempItem = arrServOverByCustLocID.find(
                (e) => e.CustLocID === item.CustLocID
            )

            if (tempItem) {
                tempItem.Equipment.push(item)
            } else {
                arrServOverByCustLocID.push({
                    CustLocID: item.CustLocID,
                    EquipCustLocID: item.EquipCustLocID,
                    CustID: item.CustID,
                    EquipID: item.EquipID,
                    CustName: item.CustName,
                    CustLocName: item.CustLocName,
                    CustLocStreetAddress: item.CustLocStreetAddress,
                    CustLocSuburb: item.CustLocSuburb,
                    CustLocCity: item.CustLocCity,
                    CustLocPostalCode: item.CustLocPostalCode,
                    CustLocCountry: item.CustLocCountry,
                    CustPriContactName: item.CustPriContactName,
                    CustPriContactEmail: item.CustPriContactEmail,
                    CustPriContactNumber: item.CustPriContactNumber,
                    LastServiceRecorded: item.LastServiceRecorded,
                    Contacts: item.Contacts,

                    Equipment: [item],
                })
            }
        })

        console.log('arrServOverByCustLocID: ', arrServOverByCustLocID)
        //arrServOverByCustLocID = arrServOver

        //Sort Contacts to put Primary on top
        arrServOverByCustLocID.sort((a, b) => {
            return a.CustID === b.CustID ? 0 : a.CustID < b.CustID ? -1 : 1
        })

        return (
            <>
                <div className='row text-primary mb-0 mt-3'>
                    <div className='col-auto me-auto '>
                        <h5>Services Overdue Report</h5>
                    </div>
                </div>
                <ROIcon
                    icon={
                        toggleAllRecentServices ? 'ROicoCheckbox' : 'ROicoBox'
                    }
                    className='icoSVG p-0 m-0'
                    size={32}
                    onClickCallback={() =>
                        setAllRecentServices((prev) => !prev)
                    }
                />{' '}
                Show All Services
                {arrServOverByCustLocID.map((line, i) => {
                    return (
                        <RepServiceOverdueItem
                            _LocDetails={line}
                            key={myUUid()}
                            containerBackColor={
                                (i + 1) % 2 === 0 ? 'bg-white' : 'bg-secondary'
                            }
                        />
                    )
                })}
            </>
        )
    } catch (error) {
        console.log('ERROR!!!! in RepServicesOverdue: ', error)
        console.log('Services: ', services)
        console.log('loccustomers: ', loccustomers)
        console.log('equipcustloc: ', equipcustloc)
        console.log('equipment: ', equipment)
        throw new Error()
    }
}
export default RepServiceOverdue
