import axios from 'axios'

const API_URL = '/api/users/'

//Register User
const register = async (userData) => {
    const response = await axios.post(API_URL, userData)

    console.log('Login User API response: ', response)

    return response.data
}

//Login User
const login = async (userLoginData) => {
    const response = await axios.post(API_URL + 'login', userLoginData)

    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    console.log('User logged in: ', response.data)

    return response.data
}

// Logout user by removing token from localStorage
const logout = async () => {
    console.log('authService logout remove user')
    localStorage.removeItem('user')
    return true
}

const authService = {
    register,
    logout,
    login,
}

export default authService
