const icons = {
    marknew: [
        {
            type: 'path',
            data: 'M18.4692,16.587c.3417-.2649,.6946-.5076,1.0677-.7297,.0768-.0423,.1404-.1087,.2183-.1491l.4493-.2332,.0085,.0149c1.1556-.5778,2.4239-.9346,3.7714-1.0516-3.9414-.6899-8.1299-.0551-10.9863,1.5853-1.8578,2.4843-2.9981,5.5328-2.9981,8.8662,0,2.3086,.5731,4.4692,1.5151,6.4212-.0972-5.2018,2.4652-11.5465,6.9541-14.7241Z',
            fill: '#43a1bf',
            fillID: 1,
            viewBox: '0 0 49.778 49.778',
        },
        {
            type: 'path',
            data: 'M30.5422,16.0207c.4455,.2851,.8518,.6171,1.2483,.9639,.0738,.0687,.1685,.1149,.2404,.1855l.0828,.0811c1.0086,.9545,1.8314,2.0959,2.4024,3.3817-.5897-3.8944-2.4563-7.5992-4.8576-9.7784-1.5055-.5138-3.0915-.8543-4.7687-.8543-3.9922,0-7.6049,1.5994-10.2813,4.1662,4.8236-1.6823,11.5061-1.2679,15.9337,1.8544Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M34.9631,27.8265c-.1457,.4976-.304,.9865-.5182,1.4506l-.0074,.0206-.2264,.4527-.0189-.0087c-.6154,1.1741-1.4338,2.2103-2.4267,3.0728,3.6448-1.7823,6.6903-4.8164,8.0065-7.8649,0-.0204,.006-.0394,.006-.0598,0-5.6373-3.1857-10.4939-7.8168-13.0212,3.1174,4.1539,4.7585,10.7649,3.0019,15.9578Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M25.3459,35.4569c-.1385,0-.2734-.0251-.4112-.03-.0153,0-.0296,.0045-.0449,.0045-.0957,0-.1844-.0257-.2797-.0283-.3212-.0202-.6452-.0232-.9626-.0704l-.5021-.0749,.0004-.0028c-1.3145-.2208-2.5352-.6893-3.6378-1.35,2.772,2.8494,6.504,4.7823,9.7597,5.1406,5.244-1.6283,9.2534-6.0251,10.2435-11.5165-2.9466,4.2373-8.6982,7.9266-14.1653,7.9278Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M15.1303,28.8401c-.2838-.6984-.478-1.4375-.6067-2.2005l-.034-.2044,.0132-.0026c-.0753-.5076-.1561-1.0145-.1561-1.5428,0-.742,.0811-1.4647,.2276-2.1637-1.8057,3.5127-2.4626,7.6157-1.7793,10.7834,2.703,3.7799,7.1039,6.2685,12.0949,6.2685,.6635,0,1.2966-.1115,1.9374-.1959-4.8376-1.4853-9.9259-5.6824-11.6969-10.7419Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'circle',
            data: { cx: '24.8888', cy: '24.8889', r: '7.9256' },
            fill: '#4fb5be',
            fillID: 2,
        },
    ],
    ROicoAdd: [
        {
            type: 'path',
            data: 'M366,115.7c10.09,0,18.3,8.21,18.3,18.3v175.7h175.7c10.09,0,18.3,8.21,18.3,18.3v32c0,10.09-8.21,18.3-18.3,18.3h-175.7v175.7c0,10.09-8.21,18.3-18.3,18.3h-32c-10.09,0-18.3-8.21-18.3-18.3v-175.7H140c-10.09,0-18.3-8.21-18.3-18.3v-32c0-10.09,8.21-18.3,18.3-18.3h175.7V134c0-10.09,8.21-18.3,18.3-18.3h32m0-31.7h-32c-27.61,0-50,22.39-50,50v144H140c-27.61,0-50,22.39-50,50v32c0,27.61,22.39,50,50,50h144v144c0,27.61,22.39,50,50,50h32c27.61,0,50-22.39,50-50v-144h144c27.61,0,50-22.39,50-50v-32c0-27.61-22.39-50-50-50h-144V134c0-27.61-22.39-50-50-50h0Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoAddchat: [
        {
            type: 'path',
            data: 'M542,94H158c-35.3,0-64,28.7-64,64V446c0,35.3,28.7,64,64,64h96v84c0,7.1,5.8,12,12,12,2.4,0,4.9-.7,7.1-2.4l124.9-93.6h144c35.3,0,64-28.7,64-64V158c0-35.3-28.7-64-64-64Zm32,352c0,17.6-14.4,32-32,32h-154.7l-8.5,6.4-92.8,69.6v-76H158c-17.6,0-32-14.4-32-32V158c0-17.6,14.4-32,32-32h384c17.6,0,32,14.4,32,32V446Zm-136-160h-72v-72c0-4.4-3.6-8-8-8h-16c-4.4,0-8,3.6-8,8v72h-72c-4.4,0-8,3.6-8,8v16c0,4.4,3.6,8,8,8h72v72c0,4.4,3.6,8,8,8h16c4.4,0,8-3.6,8-8v-72h72c4.4,0,8-3.6,8-8v-16c0-4.4-3.6-8-8-8Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoAllchat: [
        {
            type: 'path',
            data: 'M574,254h-96v-96c0-35.3-28.7-64-64-64H126c-35.3,0-64,28.7-64,64v160c0,35.3,28.7,64,64,64h32v52c0,7.1,5.8,12,12,12,2.4,0,4.9-.7,7.1-2.4l76.9-43.5v77.9c0,35.3,28.7,64,64,64h96l108.9,61.6c2.2,1.6,4.7,2.4,7.1,2.4,6.2,0,12-4.9,12-12v-52h32c35.3,0,64-28.7,64-64v-160c0-35.3-28.7-64-64-64ZM126,350c-17.6,0-32-14.4-32-32V158c0-17.6,14.4-32,32-32H414c17.6,0,32,14.4,32,32v160c0,17.6-14.4,32-32,32h-136.4l-7.3,4.2-80.3,45.4v-49.6H126Zm480,128c0,17.6-14.4,32-32,32h-64v49.6l-80.2-45.4-7.3-4.2h-104.5c-17.6,0-32-14.4-32-32v-96h128c35.3,0,64-28.7,64-64v-32h96c17.6,0,32,14.4,32,32v160Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoBox: [
        {
            type: 'path',
            data: 'M526,126H174c-26.51,0-48,21.49-48,48v352c0,26.51,21.49,48,48,48h352c26.51,0,48-21.49,48-48V174c0-26.51-21.49-48-48-48Zm0,32c8.82,0,16,7.18,16,16v352c0,8.82-7.18,16-16,16H174c-8.82,0-16-7.18-16-16V174c0-8.82,7.18-16,16-16h352',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoCheck: [
        {
            type: 'path',
            data: 'M539.51,185.95L259.49,465.97l-99-98.99c-4.69-4.69-12.28-4.69-16.97,0l-11.31,11.31c-4.69,4.69-4.69,12.28,0,16.97l118.79,118.79c4.69,4.69,12.28,4.69,16.97,0L567.79,214.24c4.69-4.69,4.69-12.28,0-16.97l-11.31-11.31c-4.69-4.69-12.28-4.69-16.97,0h0Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoCheckbox: [
        {
            type: 'path',
            data: 'M526,126H174c-26.51,0-48,21.49-48,48v352c0,26.51,21.49,48,48,48h352c26.51,0,48-21.49,48-48V174c0-26.51-21.49-48-48-48Zm0,32c8.82,0,16,7.18,16,16v352c0,8.82-7.18,16-16,16H174c-8.82,0-16-7.18-16-16V174c0-8.82,7.18-16,16-16h352m-34.3,98.29l-8.45-8.52c-4.67-4.71-12.27-4.74-16.97-.07l-163.44,162.13-68.98-69.53c-4.67-4.71-12.26-4.74-16.97-.07l-8.52,8.45c-4.71,4.67-4.74,12.26-.07,16.97l85.88,86.57c4.67,4.7,12.26,4.74,16.97,.07l180.48-179.03c4.7-4.67,4.73-12.26,.07-16.97h0Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoClose: [
        {
            type: 'path',
            data: 'M348.37,350l149.77,149.77c2.73,2.73,2.73,7.17,0,9.9l-12.73,12.73c-2.73,2.73-7.17,2.73-9.9,0l-149.77-149.77-149.77,149.77c-2.73,2.73-7.17,2.73-9.9,0l-12.73-12.73c-2.73-2.73-2.73-7.17,0-9.9l149.77-149.77L153.34,200.23c-2.73-2.73-2.73-7.17,0-9.9l12.73-12.73c2.73-2.73,7.17-2.73,9.9,0l149.77,149.77,149.77-149.77c2.73-2.73,7.17-2.73,9.9,0l12.73,12.73c2.73,2.73,2.73,7.17,0,9.9l-149.77,149.77Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoComments: [
        {
            type: 'path',
            data: 'M542,94H158c-35.3,0-64,28.7-64,64V446c0,35.3,28.7,64,64,64h96v84c0,7.1,5.8,12,12,12,2.4,0,4.9-.7,7.1-2.4l124.9-93.6h144c35.3,0,64-28.7,64-64V158c0-35.3-28.7-64-64-64Zm32,352c0,17.6-14.4,32-32,32h-154.7l-8.5,6.4-92.8,69.6v-76H158c-17.6,0-32-14.4-32-32V158c0-17.6,14.4-32,32-32h384c17.6,0,32,14.4,32,32V446Zm-144-246.6c-12.5-12.5-32.8-12.5-45.2,0l-126.1,126c-2,2-3.4,4.5-4.2,7.3l-16,61.2c-1.4,5.5,.1,11.3,4.2,15.4,3,3,7.1,4.7,11.3,4.7,1.3,0,2.7-.2,4-.5l61.2-16c2.8-.7,5.3-2.2,7.3-4.2l126.1-126.1c12.5-12.5,12.5-32.8,0-45.2l-22.6-22.6Zm-123,168.2l-30.6,8,8-30.6,75-75,22.6,22.6-75,75Zm97.6-97.6l-22.6-22.6,25.4-25.4,22.6,22.6-25.4,25.4h0Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoCust: [
        {
            type: 'path',
            data: 'M574,318c44.2,0,80-35.8,80-80s-35.8-80-80-80-80,35.8-80,80,35.8,80,80,80Zm0-128c26.5,0,48,21.5,48,48s-21.5,48-48,48-48-21.5-48-48,21.5-48,48-48Zm-224,160c61.9,0,112-50.1,112-112s-50.1-112-112-112-112,50.1-112,112,50.1,112,112,112Zm0-192c44.1,0,80,35.9,80,80s-35.9,80-80,80-80-35.9-80-80,35.9-80,80-80Zm244,192h-40c-15.2,0-29.3,4.8-41.1,12.9,9.4,6.4,17.9,13.9,25.4,22.4,4.9-2.1,10.2-3.3,15.7-3.3h40c24.2,0,44,21.5,44,48,0,8.8,7.2,16,16,16s16-7.2,16-16c0-44.1-34.1-80-76-80Zm-468-32c44.2,0,80-35.8,80-80s-35.8-80-80-80-80,35.8-80,80,35.8,80,80,80Zm0-128c26.5,0,48,21.5,48,48s-21.5,48-48,48-48-21.5-48-48,21.5-48,48-48Zm304.1,180c-33.4,0-41.7,12-80.1,12s-46.7-12-80.1-12c-36.3,0-71.6,16.2-92.3,46.9-12.4,18.4-19.6,40.5-19.6,64.3v44.8c0,26.5,21.5,48,48,48H494c26.5,0,48-21.5,48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3-20.7-30.7-56-46.9-92.3-46.9h0Zm79.9,156c0,8.8-7.2,16-16,16H206c-8.8,0-16-7.2-16-16v-44.8c0-16.6,4.9-32.7,14.1-46.4,13.8-20.5,38.4-32.8,65.7-32.8s37.2,12,80.2,12,52.8-12,80.1-12,51.9,12.3,65.7,32.8c9.2,13.7,14.1,29.8,14.1,46.4v44.8h.1ZM187.1,362.9c-11.9-8.1-26-12.9-41.1-12.9h-40c-41.9,0-76,35.9-76,80,0,8.8,7.2,16,16,16s16-7.2,16-16c0-26.5,19.8-48,44-48h40c5.5,0,10.8,1.2,15.7,3.3,7.5-8.5,16.1-16,25.4-22.4h0Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoDash: [
        {
            type: 'path',
            data: 'M518,446h16c4.4,0,8-3.6,8-8V198c0-4.4-3.6-8-8-8h-16c-4.4,0-8,3.6-8,8v240c0,4.4,3.6,8,8,8Zm-96,0h16c4.4,0,8-3.6,8-8v-144c0-4.4-3.6-8-8-8h-16c-4.4,0-8,3.6-8,8v144c0,4.4,3.6,8,8,8Zm-192,0h16c4.4,0,8-3.6,8-8v-80c0-4.4-3.6-8-8-8h-16c-4.4,0-8,3.6-8,8v80c0,4.4,3.6,8,8,8Zm96,0h16c4.4,0,8-3.6,8-8V230c0-4.4-3.6-8-8-8h-16c-4.4,0-8,3.6-8,8v208c0,4.4,3.6,8,8,8Zm272,64H126V166c0-4.42-3.58-8-8-8h-16c-4.42,0-8,3.58-8,8v360c0,8.84,7.16,16,16,16h488c4.42,0,8-3.58,8-8v-16c0-4.42-3.58-8-8-8Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoDate: [
        {
            type: 'path',
            data: 'M526,158h-48v-56c0-4.4-3.6-8-8-8h-16c-4.4,0-8,3.6-8,8v56H254v-56c0-4.4-3.6-8-8-8h-16c-4.4,0-8,3.6-8,8v56h-48c-26.5,0-48,21.5-48,48v352c0,26.5,21.5,48,48,48h352c26.5,0,48-21.5,48-48V206c0-26.5-21.5-48-48-48Zm16,400c0,8.8-7.2,16-16,16H174c-8.8,0-16-7.2-16-16V286h384v272Zm0-304H158v-48c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16v48ZM238,478h96c8.8,0,16-7.2,16-16v-96c0-8.8-7.2-16-16-16h-96c-8.8,0-16,7.2-16,16v96c0,8.8,7.2,16,16,16Zm16-96h64v64h-64v-64Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoDel: [
        {
            type: 'path',
            data: 'M422,526h16c4.42,0,8-3.58,8-8V246c0-4.42-3.58-8-8-8h-16c-4.42,0-8,3.58-8,8v272c0,4.42,3.58,8,8,8Zm-160,0h16c4.42,0,8-3.58,8-8V246c0-4.42-3.58-8-8-8h-16c-4.42,0-8,3.58-8,8v272c0,4.42,3.58,8,8,8ZM566,158h-104l-33.6-44.8c-9.07-12.09-23.29-19.2-38.4-19.2h-80c-15.11,0-29.33,7.11-38.4,19.2l-33.6,44.8h-104c-4.42,0-8,3.58-8,8v16c0,4.42,3.58,8,8,8h24v368c0,26.51,21.49,48,48,48H494c26.51,0,48-21.49,48-48h0V190h24c4.42,0,8-3.58,8-8v-16c0-4.42-3.58-8-8-8Zm-268.8-25.6c3.03-4.02,7.77-6.38,12.8-6.4h80c5.03,.02,9.77,2.38,12.8,6.4l19.2,25.6h-144l19.2-25.6Zm212.8,425.6c0,8.84-7.16,16-16,16H206c-8.84,0-16-7.16-16-16V190H510v368Zm-168-32h16c4.42,0,8-3.58,8-8V246c0-4.42-3.58-8-8-8h-16c-4.42,0-8,3.58-8,8v272c0,4.42,3.58,8,8,8Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoDown: [
        {
            type: 'path',
            data: 'M569.56,239.5l-7.1-7.1c-4.7-4.7-12.3-4.7-17,0l-195.4,195.5L154.56,232.4c-4.7-4.7-12.3-4.7-17,0l-7.1,7.1c-4.7,4.7-4.7,12.3,0,17l211,211.1c4.7,4.7,12.3,4.7,17,0l211-211.1c4.8-4.7,4.8-12.3,.1-17Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoEdit: [
        {
            type: 'path',
            data: 'M587.25,150.26l-37.51-37.51c-12.49-12.5-28.87-18.75-45.25-18.75s-32.76,6.25-45.26,18.74L106.85,465.12l-12.7,114.22c-1.6,14.38,9.73,26.66,23.8,26.66,.89,0,1.78-.05,2.69-.15l114.14-12.61L587.26,240.76c24.99-24.99,24.99-65.51-.01-90.5h0ZM220.09,562.68l-93.03,10.31,10.36-93.17L401.31,215.93l82.77,82.77L220.09,562.68ZM564.63,218.14l-57.93,57.93-82.77-82.77,57.93-57.93c6.04-6.04,14.08-9.37,22.63-9.37s16.58,3.33,22.63,9.37l37.51,37.51c12.47,12.48,12.47,32.78,0,45.26h0Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoEquip: [
        {
            type: 'rect',
            data: {
                x: '201.69',
                y: '269.56',
                width: '32',
                height: '274.7',
                rx: '7',
                ry: '7',
            },
            viewBox: '0 0 700 700',
        },
        {
            type: 'rect',
            data: {
                x: '466.31',
                y: '269.56',
                width: '32',
                height: '274.7',
                rx: '7',
                ry: '7',
            },
        },
        {
            type: 'path',
            data: 'M593.82,74.63H106.18c-27.61,0-50,22.39-50,50v91.82c0,5.52,4.48,10,10,10h31.87l11.03,347.03c.91,28.86,49.54,51.89,108.61,51.89s107.69-23.03,108.61-51.89l11.03-347.03h25.34l11.03,347.03c.92,28.86,49.54,51.89,108.61,51.89s107.7-23.03,108.61-51.89l11.03-347.03h31.87c5.52,0,10-4.48,10-10V124.63c0-27.61-22.39-50-50-50Zm-299.47,496.66c-1.44,2.1-7.13,7.53-20.38,12.68-15.58,6.06-35.57,9.4-56.28,9.4s-40.7-3.34-56.29-9.4c-13.24-5.15-18.94-10.58-20.38-12.68l-10.95-344.84h175.24l-10.96,344.84Zm264.63,0c-1.44,2.1-7.14,7.53-20.38,12.68-15.59,6.06-35.58,9.4-56.29,9.4s-40.7-3.34-56.28-9.4c-13.25-5.15-18.94-10.58-20.38-12.68l-10.96-344.84h175.24l-10.95,344.84Zm52.84-376.84H88.18V124.63c0-9.92,8.08-18,18-18h487.64c9.92,0,18,8.08,18,18v69.82Z',
        },
    ],
    ROicoFeed: [
        {
            type: 'path',
            data: 'M350,606c106.04,0,192-85.96,192-192,0-77.41-26.97-99.03-172.27-309.67-9.53-13.77-29.93-13.77-39.46,0-145.3,210.64-172.27,232.26-172.27,309.67,0,106.04,85.96,192,192,192Zm0-473.93c139.29,201.44,160,217.44,160,281.93,0,42.74-16.64,82.92-46.86,113.14-30.22,30.22-70.4,46.86-113.14,46.86s-82.92-16.64-113.14-46.86c-30.22-30.22-46.86-70.4-46.86-113.14,0-64.49,20.69-80.47,160-281.93Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoFPres: [
        {
            type: 'path',
            data: 'M350,606c106.04,0,192-85.96,192-192,0-77.41-26.97-99.03-172.27-309.67-9.53-13.77-29.93-13.77-39.46,0-145.3,210.64-172.27,232.26-172.27,309.67,0,106.04,85.96,192,192,192Zm0-473.93c139.29,201.44,160,217.44,160,281.93,0,42.74-16.64,82.92-46.86,113.14s-70.4,46.86-113.14,46.86-82.92-16.64-113.14-46.86c-30.22-30.22-46.86-70.4-46.86-113.14,0-64.49,20.69-80.47,160-281.93Z',
            viewBox: '0 0 700 700',
        },
        {
            type: 'path',
            data: 'M434.52,509.4h-169.04c-6.24,0-12.21-2.69-16.36-7.39-21.78-24.62-33.77-56.29-33.77-89.18,0-74.25,60.4-134.66,134.65-134.66s134.65,60.41,134.65,134.66c0,32.89-11.99,64.57-33.76,89.18-4.16,4.7-10.12,7.39-16.37,7.39Zm-165.46-29.6h161.88c15.57-18.8,24.11-42.45,24.11-66.97,0-57.93-47.13-105.06-105.05-105.06s-105.05,47.13-105.05,105.06c0,24.52,8.54,48.17,24.11,66.97Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M402.38,341.55c0-5.55-3.14-10.87-8.49-13.4-7.39-3.48-16.21-.32-19.7,7.07l-20.33,43.11c-1.27-.12-2.56-.18-3.86-.18-23.74,0-43.05,19.31-43.05,43.05s19.31,43.05,43.05,43.05,43.05-19.31,43.05-43.05c0-11.77-4.75-22.45-12.43-30.23l20.34-43.12c.96-2.04,1.42-4.19,1.42-6.3Zm-35.36,79.65c0,9.38-7.63,17.02-17.02,17.02s-17.02-7.64-17.02-17.02c0-5.74,2.85-10.83,7.22-13.91,2.77-1.97,6.15-3.12,9.8-3.12,8.96,0,16.31,6.95,16.97,15.74,.03,.43,.05,.86,.05,1.29Z',
            fill: '#43a1bf',
            fillID: 1,
        },
    ],
    ROicoHelp: [
        {
            type: 'path',
            data: 'M351.12,94c-76.31,0-130.58,31.6-172.15,93.3-14.21,21.1-9.46,49.67,10.83,65.05l42.03,31.87c20.71,15.7,50.35,12.16,66.68-8.51,21.47-27.18,28.37-31.96,46.13-31.96,10.22,0,25.29,7,25.29,18.24,0,25.73-109.3,20.74-109.3,122.25v13.75c0,16.01,7.88,30.2,19.96,38.92-20.68,17.62-33.82,43.84-33.82,73.08,0,52.93,43.07,96,96,96s96-43.07,96-96c0-29.23-13.14-55.45-33.82-73.08,12.08-8.73,19.96-22.92,19.96-38.92v-4.7c25.39-18.99,104.29-44.5,104.29-139.42s-90.08-159.87-178.09-159.87Zm-8.34,480c-35.29,0-64-28.71-64-64s28.71-64,64-64,64,28.71,64,64-28.71,64-64,64Zm50.15-186.41v10.41c0,8.84-7.16,16-16,16h-68.29c-8.84,0-16-7.16-16-16v-13.75c0-86.78,109.3-57.33,109.3-122.25,0-32-31.68-50.24-57.29-50.24-33.78,0-49.17,16.18-71.24,44.12-5.4,6.84-15.28,8.12-22.24,2.85l-42.03-31.87c-6.76-5.12-8.36-14.64-3.62-21.68,34.14-50.68,77.62-79.18,145.61-79.18s146.09,55.16,146.09,127.87c0,96.55-104.29,98.04-104.29,133.72Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoIn: [
        {
            type: 'path',
            data: 'M278,177.5l164.5,164c4.7,4.7,4.7,12.3,0,17l-164.5,164c-4.7,4.7-12.3,4.7-17,0l-7.1-7.1c-4.7-4.7-4.7-12.3,0-17l132-131.4H106c-6.6,0-12-5.4-12-12v-10c0-6.6,5.4-12,12-12H385.9L254,201.6c-4.7-4.7-4.7-12.3,0-17l7.1-7.1c4.6-4.7,12.2-4.7,16.9,0Zm328,316.5V206c0-26.5-21.5-48-48-48h-132c-6.6,0-12,5.4-12,12v8c0,6.6,5.4,12,12,12h132c8.8,0,16,7.2,16,16V494c0,8.8-7.2,16-16,16h-132c-6.6,0-12,5.4-12,12v8c0,6.6,5.4,12,12,12h132c26.5,0,48-21.5,48-48Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoInfo: [
        {
            type: 'path',
            data: 'M350,134c118.62,0,216,96.07,216,216s-96.61,216-216,216-216-96.56-216-216,96.6-216,216-216m0-32c-136.96,0-248,111.08-248,248s111.04,248,248,248,248-111,248-248-111.04-248-248-248Zm-11.49,120h22.98c6.82,0,12.27,5.68,11.99,12.5l-7,168c-.27,6.43-5.56,11.5-11.99,11.5h-8.98c-6.43,0-11.72-5.07-11.99-11.5l-7-168c-.28-6.82,5.17-12.5,11.99-12.5h0Zm11.49,212c-15.46,0-28,12.54-28,28s12.54,28,28,28,28-12.54,28-28-12.54-28-28-28Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoLocation: [
        {
            type: 'path',
            data: 'M350,94c-106.04,0-192,85.96-192,192,0,77.41,26.97,99.03,172.27,309.67,9.53,13.77,29.93,13.77,39.46,0,145.3-210.64,172.27-232.26,172.27-309.67,0-106.04-85.96-192-192-192Zm0,473.93c-139.29-201.44-160-217.44-160-281.93,0-42.74,16.64-82.92,46.86-113.14,30.22-30.22,70.4-46.86,113.14-46.86s82.92,16.64,113.14,46.86c30.22,30.22,46.86,70.4,46.86,113.14,0,64.49-20.69,80.47-160,281.93Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoLogo: [
        {
            type: 'path',
            data: 'M66.658,29.4134l6.8876,18.077,6.9298-18.077h2.3882l-8.0428,20.4669h-2.6686l-8.0429-20.4669h2.5487Z',
            viewBox: '0 0 199.3376 59.9597',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M99.3948,47.6103c0,.9025-.1652,1.5068-.4974,1.8115-.3321,.3062-.9883,.4585-1.9702,.4585h-6.5312c-2.7334,0-4.6987-.478-5.8928-1.4339-1.1942-.956-1.792-2.5082-1.792-4.6582v-.5574c0-2.2295,.6043-3.8497,1.8114-4.8575,1.2087-1.0094,3.166-1.5133,5.8734-1.5133,1.8325,0,3.2923,.1717,4.3811,.5169,1.0872,.3451,1.8844,.823,2.3883,1.4339v-2.3899c0-.9559-.0924-1.7579-.2787-2.4093-.1863-.6497-.5169-1.1666-.9948-1.5522-.478-.384-1.1423-.6627-1.9913-.836-.8506-.1718-1.9249-.2593-3.2259-.2593-.9819,0-2.0172,.0065-3.106,.0195-1.0888,.0146-2.0707,.047-2.9456,.1004l.4374-2.0706c.7437-.026,1.6187-.0454,2.6281-.06,1.0094-.0129,2.0042-.0194,2.9861-.0194,1.5927,0,2.9407,.1264,4.0425,.3775,1.1001,.2527,1.9961,.6513,2.688,1.1957,.6886,.5444,1.1941,1.2476,1.5133,2.1096,.3175,.8636,.4763,1.9184,.4763,3.1659v11.4276Zm-14.4542-3.8626c0,.7177,.0859,1.3286,.2593,1.8325,.1717,.5038,.465,.9284,.8765,1.2735,.4116,.3451,.9689,.5978,1.6721,.7566,.7032,.1588,1.5862,.2398,2.6475,.2398h6.7694v-4.7781c0-.8506-.1394-1.5473-.4181-2.0917-.2786-.5428-.6837-.9754-1.2135-1.293-.5315-.3192-1.1893-.5444-1.9719-.6772-.7825-.1329-1.6785-.1993-2.6879-.1993h-.5169c-1.9653,0-3.3588,.3256-4.1818,.9754-.8231,.6513-1.2347,1.7984-1.2347,3.4446v.5169Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M102.217,49.8803v-13.6587c0-1.1406,.1199-2.176,.3597-3.1044,.2382-.93,.6756-1.7126,1.314-2.3493,.6368-.6384,1.5052-1.1293,2.607-1.4744,1.1018-.3451,2.5162-.5169,4.2418-.5169s3.1384,.1653,4.2402,.4974c1.1017,.3322,1.9637,.8166,2.5891,1.4534,.6222,.6367,1.0548,1.4209,1.293,2.3493,.2398,.9301,.358,1.9784,.358,3.1449v13.6587h-2.3088v-13.4189c0-.9284-.06-1.745-.1799-2.4498-.1182-.7015-.3904-1.2929-.8149-1.7709-.4262-.478-1.05-.8361-1.8714-1.0759-.8231-.2381-1.9265-.358-3.3053-.358-1.408,0-2.5227,.1264-3.3458,.3791-.8231,.2511-1.4469,.6157-1.8714,1.0937-.4245,.4779-.6967,1.0693-.8166,1.7725-.1183,.7032-.1782,1.5068-.1782,2.4093v13.4189h-2.3105Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M122.042,24.2367l2.3105-.4375v5.6142h8.4009l-.4374,1.9912h-7.9635v11.507c0,.9835,.0664,1.792,.1993,2.4288,.1328,.6383,.3775,1.1422,.7356,1.5133,.3597,.3726,.8506,.6319,1.4744,.7761,.6222,.1474,1.4388,.2203,2.4482,.2203h3.0671l-.4391,2.0302h-2.628c-1.3529,0-2.4887-.0989-3.4042-.2981-.9154-.1993-1.6526-.5574-2.21-1.0759-.5573-.5168-.9559-1.22-1.1941-2.1095-.2398-.8896-.3597-2.0383-.3597-3.4447V24.2367Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M149.7889,47.6103c0,.9025-.1652,1.5068-.4974,1.8115-.3321,.3062-.9883,.4585-1.9702,.4585h-6.5312c-2.7333,0-4.6987-.478-5.8928-1.4339-1.1941-.956-1.792-2.5082-1.792-4.6582v-.5574c0-2.2295,.6043-3.8497,1.8114-4.8575,1.2087-1.0094,3.166-1.5133,5.8734-1.5133,1.8325,0,3.2923,.1717,4.3812,.5169,1.0871,.3451,1.8843,.823,2.3882,1.4339v-2.3899c0-.9559-.0924-1.7579-.2787-2.4093-.1863-.6497-.5168-1.1666-.9948-1.5522-.478-.384-1.1423-.6627-1.9913-.836-.8506-.1718-1.9249-.2593-3.2259-.2593-.9819,0-2.0172,.0065-3.106,.0195-1.0888,.0146-2.0707,.047-2.9456,.1004l.4375-2.0706c.7436-.026,1.6186-.0454,2.628-.06,1.0094-.0129,2.0042-.0194,2.9861-.0194,1.5927,0,2.9407,.1264,4.0425,.3775,1.1001,.2527,1.9961,.6513,2.688,1.1957,.6886,.5444,1.1941,1.2476,1.5133,2.1096,.3176,.8636,.4763,1.9184,.4763,3.1659v11.4276Zm-14.4541-3.8626c0,.7177,.0858,1.3286,.2592,1.8325,.1717,.5038,.465,.9284,.8765,1.2735,.4116,.3451,.9689,.5978,1.6721,.7566s1.5863,.2398,2.6475,.2398h6.7694v-4.7781c0-.8506-.1394-1.5473-.418-2.0917-.2787-.5428-.6838-.9754-1.2136-1.293-.5314-.3192-1.1893-.5444-1.9718-.6772-.7826-.1329-1.6786-.1993-2.688-.1993h-.5169c-1.9653,0-3.3587,.3256-4.1818,.9754-.8231,.6513-1.2346,1.7984-1.2346,3.4446v.5169Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M169.5126,43.0315c0,1.2476-.1588,2.3024-.4764,3.166-.3192,.8619-.8247,1.5651-1.5133,2.1095-.6919,.5444-1.5878,.943-2.688,1.1958-1.1018,.2511-2.4498,.3775-4.0425,.3775-.9819,0-1.9767-.0065-2.9861-.0195-1.0094-.0145-1.8844-.034-2.628-.0599l-.4375-2.0707c.8749,.0535,1.8568,.0859,2.9456,.1005,1.0888,.0129,2.1241,.0194,3.106,.0194,1.3011,0,2.3753-.0875,3.2259-.2592,.849-.1734,1.5133-.4521,1.9913-.8361,.478-.3856,.8085-.9024,.9948-1.5522,.1863-.6513,.2787-1.4533,.2787-2.4093v-2.3898c-.5039,.6108-1.3011,1.0888-2.3882,1.4339-1.0888,.3451-2.5487,.5168-4.3812,.5168-2.7074,0-4.6647-.5038-5.8734-1.5133-1.207-1.0077-1.8114-2.628-1.8114-4.8574v-.5574c0-2.1501,.5979-3.7023,1.792-4.6582,1.1941-.956,3.1595-1.4339,5.8928-1.4339h6.5312c.9819,0,1.6381,.1523,1.9702,.4585,.3322,.3046,.4975,.909,.4975,1.8114v11.4276Zm-14.4542-7.048c0,1.6461,.4115,2.7933,1.2346,3.4446,.8231,.6497,2.2165,.9754,4.1818,.9754h.5169c1.0094,0,1.9054-.0665,2.688-.1993,.7826-.1329,1.4404-.3581,1.9718-.6773,.5298-.3175,.9349-.7501,1.2136-1.2929,.2787-.5444,.418-1.2411,.418-2.0918v-4.7781h-6.7694c-1.0612,0-1.9443,.081-2.6475,.2398-.7031,.1588-1.2605,.4116-1.6721,.7567-.4115,.3451-.7048,.7696-.8765,1.2735-.1734,.5039-.2592,1.1147-.2592,1.8325v.5169Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M174.6436,40.2447c0,1.5117,.094,2.7609,.2787,3.7428,.1863,.9818,.5184,1.766,.9964,2.3493s1.1342,.9948,1.9702,1.2346c.8361,.2382,1.9184,.3581,3.2454,.3581,1.1422,0,2.244-.0065,3.3053-.0211,1.0612-.0129,2.2035-.0324,3.4252-.0583l-.4391,2.0302c-.9025,.0259-1.9184,.0453-3.0461,.0599-1.1277,.013-2.21,.0195-3.2453,.0195-1.7515,0-3.1984-.1993-4.3407-.5963-1.1406-.3986-2.0366-.9899-2.6863-1.7725-.6514-.7826-1.1099-1.758-1.374-2.9262-.2657-1.1682-.3986-2.5227-.3986-4.0619v-2.5082c0-1.6737,.1653-3.0995,.4974-4.2807,.3322-1.1811,.8491-2.1435,1.5539-2.8872,.7031-.7437,1.5975-1.2881,2.6863-1.6332s2.3769-.5169,3.8627-.5169c2.9731,0,5.1167,.6432,6.4307,1.9313s1.9719,3.3248,1.9719,6.1116v.9559c0,.5574-.0341,.9949-.1005,1.3141-.0664,.3191-.1782,.5638-.337,.7372-.1604,.1717-.3791,.2851-.6578,.337-.2787,.0534-.6173,.081-1.0159,.081h-12.5828Zm6.2525-9.5173c-1.1147,0-2.0707,.1069-2.8678,.3192-.7956,.2122-1.4469,.5962-1.9508,1.1536-.5039,.5573-.8701,1.3286-1.0953,2.3105-.2252,.9818-.3386,2.2424-.3386,3.7816h12.5439v-1.3529c0-1.1941-.1199-2.1906-.3581-2.9861-.2398-.7972-.6108-1.4339-1.1163-1.9119-.5039-.478-1.1536-.8166-1.9508-1.0159-.7955-.1993-1.7515-.2981-2.8662-.2981Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'polygon',
            data: '32.1117 49.7242 27.7192 49.7242 20.9132 35.0559 25.3058 35.0559 32.1117 49.7242',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M14.3926,38.3184v11.6273h-4.3926V20.1661h11.2104c3.6523,0,6.2732,.6842,7.8627,2.0491,1.5896,1.3666,2.3843,3.4021,2.3843,6.1064v1.8414c0,2.8711-.8373,4.9491-2.5103,6.2306-1.673,1.2832-4.2513,1.9248-7.7368,1.9248h-6.8178Zm12.6723-9.3281c0-1.0024-.1038-1.8244-.3131-2.4677-.2093-.6399-.5429-1.142-1.0041-1.5045-.4595-.3625-1.0603-.6076-1.7972-.7318-.7403-.1259-1.6525-.1889-2.74-.1889h-6.8178v10.2896h6.8178c1.0875,0,1.9997-.063,2.74-.1889,.7369-.1259,1.3377-.3693,1.7972-.7318,.4612-.3625,.7948-.8646,1.0041-1.5062,.2093-.6399,.3131-1.4636,.3131-2.4677v-.5021Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M41.6562,26.587c.3417-.2649,.6946-.5076,1.0677-.7297,.0768-.0423,.1404-.1087,.2183-.1491l.4493-.2332,.0085,.0149c1.1556-.5778,2.4239-.9346,3.7714-1.0516-3.9414-.6899-8.1299-.0551-10.9863,1.5853-1.8578,2.4843-2.9981,5.5328-2.9981,8.8662,0,2.3086,.5731,4.4692,1.5151,6.4212-.0972-5.2018,2.4652-11.5465,6.9541-14.7241Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M53.7292,26.0207c.4455,.2851,.8518,.6171,1.2483,.9639,.0738,.0687,.1685,.1149,.2404,.1855l.0828,.0811c1.0086,.9545,1.8314,2.0959,2.4024,3.3817-.5897-3.8944-2.4563-7.5992-4.8576-9.7784-1.5055-.5138-3.0915-.8543-4.7687-.8543-3.9922,0-7.6049,1.5994-10.2813,4.1662,4.8236-1.6823,11.5061-1.2679,15.9337,1.8544Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M58.1501,37.8265c-.1457,.4976-.304,.9865-.5182,1.4506l-.0074,.0206-.2264,.4527-.0189-.0087c-.6154,1.1741-1.4338,2.2103-2.4267,3.0728,3.6448-1.7823,6.6903-4.8164,8.0065-7.8649,0-.0204,.006-.0394,.006-.0598,0-5.6373-3.1857-10.4939-7.8168-13.0212,3.1174,4.1539,4.7585,10.7649,3.0019,15.9578Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M48.5329,45.4569c-.1385,0-.2734-.0251-.4112-.03-.0153,0-.0296,.0045-.0449,.0045-.0957,0-.1844-.0257-.2797-.0283-.3212-.0202-.6452-.0232-.9626-.0704l-.5021-.0749,.0004-.0028c-1.3145-.2208-2.5352-.6893-3.6378-1.35,2.772,2.8494,6.504,4.7823,9.7597,5.1406,5.244-1.6283,9.2534-6.0251,10.2435-11.5165-2.9466,4.2373-8.6982,7.9266-14.1653,7.9278Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M38.3173,38.8401c-.2838-.6984-.478-1.4375-.6067-2.2005l-.034-.2044,.0132-.0026c-.0753-.5076-.1561-1.0145-.1561-1.5428,0-.742,.0811-1.4647,.2276-2.1637-1.8057,3.5127-2.4626,7.6157-1.7793,10.7834,2.703,3.7799,7.1039,6.2685,12.0949,6.2685,.6635,0,1.2966-.1115,1.9374-.1959-4.8376-1.4853-9.9259-5.6824-11.6969-10.7419Z',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'circle',
            data: { cx: '48.0758', cy: '34.8889', r: '7.9256' },
            fill: '#4fb5be',
            fillID: 2,
        },
    ],
    ROicoMinus: [
        {
            type: 'path',
            data: 'M565,316c9.93,0,18,8.07,18,18v32c0,9.93-8.07,18-18,18H135c-9.93,0-18-8.07-18-18v-32c0-9.93,8.07-18,18-18h430m0-32H135c-27.61,0-50,22.39-50,50v32c0,27.61,22.39,50,50,50h430c27.61,0,50-22.39,50-50v-32c0-27.61-22.39-50-50-50h0Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoMobile: [
        {
            type: 'path',
            data: 'M382,510c0,17.7-14.3,32-32,32s-32-14.3-32-32,14.3-32,32-32,32,14.3,32,32ZM510,142v416c0,26.5-21.5,48-48,48H238c-26.5,0-48-21.5-48-48V142c0-26.5,21.5-48,48-48h224c26.5,0,48,21.5,48,48Zm-32,0c0-8.8-7.2-16-16-16H238c-8.8,0-16,7.2-16,16v416c0,8.8,7.2,16,16,16h224c8.8,0,16-7.2,16-16V142Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoNote: [
        {
            type: 'path',
            data: 'M574,442.11V174.01c0-26.51-21.49-48-48-48H174c-26.51,0-48,21.49-48,48v351.99c0,26.51,21.49,48,48,48H442.12c12.73,0,24.94-5.06,33.94-14.06l83.88-83.88c9-9,14.06-21.21,14.06-33.94Zm-120.57,95.2c-2.05,2.05-4.62,3.5-7.43,4.2v-95.51h95.51c-.69,2.81-2.14,5.38-4.2,7.43l-83.88,83.88Zm88.57-363.3v239.99h-104c-13.26,0-24,10.74-24,24v104H174c-8.84,0-16-7.16-16-16V174.01c0-8.84,7.16-16,16-16h352c8.84,0,16,7.16,16,16Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoOffice: [
        {
            type: 'path',
            data: 'M414,414h-32c-17.66,0-32,14.36-32,32v32c0,17.64,14.34,32,32,32h32c17.66,0,32-14.36,32-32v-32c0-17.64-14.34-32-32-32Zm0,64h-32v-32h32v32Zm0-192h-32c-17.66,0-32,14.36-32,32v32c0,17.64,14.34,32,32,32h32c17.66,0,32-14.36,32-32v-32c0-17.64-14.34-32-32-32Zm0,64h-32v-32h32v32Zm128,64h-32c-17.66,0-32,14.36-32,32v32c0,17.64,14.34,32,32,32h32c17.66,0,32-14.36,32-32v-32c0-17.64-14.34-32-32-32Zm0,64h-32v-32h32v32Zm0-192h-32c-17.66,0-32,14.36-32,32v32c0,17.64,14.34,32,32,32h32c17.66,0,32-14.36,32-32v-32c0-17.64-14.34-32-32-32Zm0,64h-32v-32h32v32Zm32-224H299.06c-6.61-18.6-24.19-32-45.06-32h-64c-20.87,0-38.45,13.4-45.06,32h-18.94c-35.35,0-64,28.65-64,64v352c0,35.35,28.65,64,64,64h448c35.35,0,64-28.65,64-64V190c0-35.35-28.65-64-64-64Zm-192,32h160v64h-160v-64Zm-208-16c0-8.82,7.18-16,16-16h64c8.82,0,16,7.18,16,16V478c0,8.82-7.18,16-16,16h-64c-8.82,0-16-7.18-16-16V142Zm432,400c0,17.64-14.36,32-32,32H126c-17.64,0-32-14.36-32-32V190c0-17.64,14.36-32,32-32h16V478c0,26.51,21.49,48,48,48h64c26.51,0,48-21.49,48-48V158h48v64c0,17.64,14.34,32,32,32h160c17.66,0,32-14.36,32-32v-64c17.64,0,32,14.36,32,32v352Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoOut: [
        {
            type: 'path',
            data: 'M141.94,158h132c6.6,0,12,5.4,12,12v8c0,6.6-5.4,12-12,12H141.94c-8.8,0-16,7.2-16,16V494c0,8.8,7.2,16,16,16h132c6.6,0,12,5.4,12,12v8c0,6.6-5.4,12-12,12H141.94c-26.5,0-48-21.5-48-48V206c0-26.5,21.5-48,48-48Zm279,19.5l-7.1,7.1c-4.7,4.7-4.7,12.3,0,17l132,131.4H265.94c-6.6,0-12,5.4-12,12v10c0,6.6,5.4,12,12,12h279.9l-131.9,131.4c-4.7,4.7-4.7,12.3,0,17l7.1,7.1c4.7,4.7,12.3,4.7,17,0l164.5-164c4.7-4.7,4.7-12.3,0-17l-164.6-164c-4.7-4.7-12.3-4.7-17,0Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoParts: [
        {
            type: 'path',
            data: 'M325.74,74.63c-67.71,0-122.6,26.22-122.6,58.57v433.6c0,32.35,54.89,58.57,122.6,58.57s122.6-26.22,122.6-58.57V133.2c0-32.35-54.89-58.57-122.6-58.57Zm-67.38,43.6c18.38-7.48,42.31-11.6,67.38-11.6s49,4.12,67.39,11.6c15.02,6.11,21.18,12.44,22.86,14.97-1.68,2.53-7.84,8.86-22.86,14.97-18.39,7.48-42.32,11.6-67.39,11.6s-49-4.12-67.38-11.6c-15.03-6.11-21.19-12.44-22.87-14.97,1.68-2.53,7.84-8.86,22.87-14.97Zm157.98,447.99c-1,1.98-6.85,8.89-23.21,15.55-18.39,7.48-42.32,11.6-67.39,11.6s-49-4.12-67.39-11.6c-16.36-6.66-22.21-13.57-23.21-15.55V172.67c22.42,11.73,54.71,19.1,90.6,19.1s68.18-7.37,90.6-19.1v393.55Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoPhone: [
        {
            type: 'path',
            data: 'M581.8,118.1l-100.8-23.3c-14.7-3.4-29.8,4.2-35.8,18.1l-46.5,108.5c-5.5,12.7-1.8,27.7,8.9,36.5l53.9,44.1c-34,69.2-90.3,125.6-159.6,159.6l-44.1-53.9c-8.8-10.7-23.8-14.4-36.5-8.9l-108.4,46.5c-13.9,6-21.5,21-18.1,35.7l23.2,100.7c3.3,14.3,15.9,24.3,30.5,24.3,252.2,0,457.5-204.2,457.5-457.5,0-14.6-10-27.2-24.2-30.4ZM149.1,574l-23-99.6,107.4-46,59.5,72.8c103.6-48.6,159.7-104.9,208.1-208.1l-72.8-59.5,46-107.4,99.6,23c-.2,234.5-190.3,424.5-424.8,424.8Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoPlus: [
        {
            type: 'path',
            data: 'M366,115.7c10.09,0,18.3,8.21,18.3,18.3v175.7h175.7c10.09,0,18.3,8.21,18.3,18.3v32c0,10.09-8.21,18.3-18.3,18.3h-175.7v175.7c0,10.09-8.21,18.3-18.3,18.3h-32c-10.09,0-18.3-8.21-18.3-18.3v-175.7H140c-10.09,0-18.3-8.21-18.3-18.3v-32c0-10.09,8.21-18.3,18.3-18.3h175.7V134c0-10.09,8.21-18.3,18.3-18.3h32m0-31.7h-32c-27.61,0-50,22.39-50,50v144H140c-27.61,0-50,22.39-50,50v32c0,27.61,22.39,50,50,50h144v144c0,27.61,22.39,50,50,50h32c27.61,0,50-22.39,50-50v-144h144c27.61,0,50-22.39,50-50v-32c0-27.61-22.39-50-50-50h-144V134c0-27.61-22.39-50-50-50h0Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoReport: [
        {
            type: 'path',
            data: 'M527.9,191.95l-83.9-83.9c-9-9-21.2-14.1-33.9-14.1H206c-26.5,.1-48,21.6-48,48.1v416c0,26.5,21.5,48,48,48H494c26.5,0,48-21.5,48-48V225.95c0-12.7-5.1-25-14.1-34Zm-22.6,22.7c2.1,2.1,3.5,4.6,4.2,7.4h-95.5V126.55c2.8,.7,5.3,2.1,7.4,4.2l83.9,83.9Zm-11.3,359.4H206c-8.8,0-16-7.2-16-16V142.05c0-8.8,7.2-16,16-16h176v104c0,13.3,10.7,24,24,24h104v304c0,8.8-7.2,16-16,16Zm-48-244v8c0,6.6-5.4,12-12,12h-168c-6.6,0-12-5.4-12-12v-8c0-6.6,5.4-12,12-12h168c6.6,0,12,5.4,12,12Zm0,64v8c0,6.6-5.4,12-12,12h-168c-6.6,0-12-5.4-12-12v-8c0-6.6,5.4-12,12-12h168c6.6,0,12,5.4,12,12Zm0,64v8c0,6.6-5.4,12-12,12h-168c-6.6,0-12-5.4-12-12v-8c0-6.6,5.4-12,12-12h168c6.6,0,12,5.4,12,12Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoRO: [
        {
            type: 'path',
            data: 'M356.4,77.79c-59.2,.34-106.66,49.27-106.66,108.47v194.55h-34.36c-3.86,0-7,3.13-7,7v179.98c0,3.87,3.14,7,7,7h99.62c3.87,0,7-3.13,7-7v-65.49h52.39c3.87,0,7-3.13,7-7v-91.42c0-3.87-3.13-7-7-7h-15.69c-3.87,0-7,3.13-7,7v68.73h-29.7v-84.8c0-3.87-3.13-7-7-7h-33.26V185.08c0-41.76,34.18-75.69,76.03-75.28,41.45,.4,74.54,34.79,74.54,76.24v48.89c0,3.86,3.14,7,7,7h18c3.87,0,7-3.14,7-7v-49.85c0-59.37-48.46-107.63-107.91-107.29Zm-66.4,335.02v129.98h-49.62v-129.98h49.62Z',
            viewBox: '0 0 700 700',
            fill: '#43a1bf',
            fillID: 1,
        },
        {
            type: 'path',
            data: 'M416.31,348.81c0,17.67,14.33,32,32,32s32-14.33,32-32-32-64-32-64c0,0-32,46.33-32,64Z',
            fill: '#43a1bf',
            fillID: 1,
        },
    ],
    ROicoService: [
        {
            type: 'path',
            data: 'M601.42,208.49c-2.34-9.47-9.66-16.98-19.06-19.61-9.47-2.61-19.65,0-26.65,6.98l-63.87,63.87-44.25-7.36-7.38-44.24,63.87-63.87c6.94-6.92,9.62-17.09,7-26.54-2.62-9.47-10.19-16.83-19.75-19.2-51.73-12.83-105.38,2.02-143.19,39.78-37.84,37.87-52.21,92.52-38.62,144.7L116.19,476.29c-29.59,29.63-29.59,77.83,0,107.45,14.35,14.35,33.44,22.26,53.75,22.26s39.37-7.91,53.71-22.26l193.14-193.11c52.03,13.73,106.8-.72,144.89-38.82,37.81-37.81,52.68-91.39,39.74-143.32h0Zm-62.36,120.7c-31.87,31.81-78.43,42.63-121.77,28.23l-9.38-3.14-206.88,206.84c-16.62,16.62-45.59,16.62-62.21,0-17.12-17.14-17.12-45.06,0-62.21l207.01-206.98-3.09-9.34c-14.31-43.45-3.56-90.06,28.03-121.67,22.71-22.72,52.67-34.92,83.79-34.92,6.87,0,13.81,.59,20.72,1.81l-69.31,69.35,13.81,83.02,83.06,13.82,69.3-69.35c6.72,38.25-5.34,76.79-33.08,104.54ZM174,510c-8.84,0-16,7.16-16,16s7.16,16,16,16,16-7.16,16-16-7.16-16-16-16Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoSettings: [
        {
            type: 'path',
            data: 'M576.7,393.28l-32.61-18.83c2.05-16.24,2.05-32.66,0-48.9l32.61-18.83c9.58-5.53,14.2-16.9,11.05-27.5-11.21-37.75-31.17-71.73-57.54-99.6-7.63-8.07-19.82-9.84-29.44-4.28l-32.56,18.8c-13.02-9.91-27.25-18.14-42.34-24.48v-37.61c0-11.13-7.65-20.8-18.48-23.37-37.64-8.91-77.12-8.91-114.77,0-10.83,2.56-18.48,12.24-18.48,23.37v37.61c-15.09,6.34-29.31,14.57-42.34,24.48l-32.56-18.8c-9.62-5.55-21.8-3.79-29.44,4.28-26.36,27.87-46.32,61.85-57.53,99.6-3.15,10.6,1.47,21.97,11.05,27.5l32.61,18.83c-2.05,16.24-2.05,32.66,0,48.9l-32.61,18.83c-9.58,5.53-14.19,16.9-11.05,27.5,11.21,37.75,31.17,71.73,57.53,99.6,7.63,8.07,19.82,9.84,29.44,4.28l32.56-18.8c13.02,9.91,27.25,18.14,42.34,24.48v37.61c0,11.13,7.65,20.8,18.48,23.37,37.65,8.91,77.12,8.91,114.77,0,10.83-2.56,18.48-12.24,18.48-23.37v-37.61c15.09-6.34,29.31-14.57,42.34-24.48l32.56,18.8c9.62,5.55,21.8,3.79,29.44-4.28,26.36-27.87,46.32-61.85,57.54-99.6,3.15-10.6-1.47-21.97-11.05-27.5h0Zm-65.48,100.46l-46.31-26.74c-26.99,23.07-36.56,28.88-71.04,41.06v53.48c-28.94,5.97-58.8,5.97-87.74,0v-53.48c-33.62-11.88-43.35-17.39-71.04-41.06l-46.31,26.74c-19.71-22.09-34.69-47.99-43.93-75.96l46.33-26.74c-6.54-35.42-6.54-46.64,0-82.08l-46.33-26.74c9.24-27.97,24.22-53.87,43.93-75.97l46.31,26.76c27.38-23.43,37.06-29.07,71.04-41.07v-53.48c28.94-5.98,58.8-5.98,87.74,0v53.48c33.98,12,43.67,17.64,71.04,41.07l46.31-26.76c19.71,22.1,34.69,48,43.93,75.97l-46.33,26.74c6.54,35.43,6.54,46.64,0,82.08l46.33,26.74c-9.24,27.97-24.22,53.87-43.93,75.96h0Zm-161.22-239.74c-52.93,0-96,43.07-96,96s43.07,96,96,96,96-43.07,96-96-43.07-96-96-96Zm0,160c-35.29,0-64-28.71-64-64s28.71-64,64-64,64,28.71,64,64-28.71,64-64,64Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoSign: [
        {
            type: 'path',
            data: 'M598,158H190v-56c0-4.4-3.6-8-8-8h-16c-4.4,0-8,3.6-8,8v56h-56c-4.4,0-8,3.6-8,8v16c0,4.4,3.6,8,8,8h56v408c0,4.4,3.6,8,8,8h16c4.4,0,8-3.6,8-8V190h96v48h-16c-17.7,0-32,14.3-32,32v176c0,17.7,14.3,32,32,32h288c17.7,0,32-14.3,32-32v-176c0-17.7-14.3-32-32-32h-16v-48h56c4.4,0,8-3.6,8-8v-16c0-4.4-3.6-8-8-8Zm-40,288H270v-176h288v176Zm-48-208h-192v-48h192v48Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoStar: [
        {
            type: 'path',
            data: 'M590.1,265.48l-146.1-21.3-65.3-132.4c-11.7-23.6-45.6-23.9-57.4,0l-65.3,132.4-146.1,21.3c-26.2,3.8-36.7,36.1-17.7,54.6l105.7,103-25,145.5c-4.5,26.3,23.2,46,46.4,33.7l130.7-68.7,130.7,68.7c23.2,12.2,50.9-7.4,46.4-33.7l-25-145.5,105.7-103c19-18.5,8.5-50.8-17.7-54.6h0Zm-122.3,146.4l27.8,162-145.6-76.4-145.5,76.5,27.8-162-117.8-114.9,162.7-23.6,72.8-147.5,72.8,147.5,162.7,23.6-117.7,114.8h0Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoSupport: [
        {
            type: 'path',
            data: 'M446,446h-4.7c-12.16,0-24,2.9-35.5,6.8-36.2,12.28-75.44,12.28-111.64,0-11.48-3.9-23.29-6.78-35.42-6.78h-4.74c-70.68,0-127.99,57.3-128,127.98,0,17.67,14.33,32,32,32h384c17.67,0,32-14.33,32-32,0-70.69-57.31-128-128-128Zm-288,128c.06-53,43-95.94,96-96h4.74c6.92,0,14.92,1.62,25.16,5.09,42.85,14.53,89.31,14.53,132.16,0,10.31-3.49,18.33-5.11,25.24-5.11h4.7c53,.06,95.94,43,96,96l-384,.02Zm16-256c8.84,0,16-7.16,16-16v-16c0-88.22,71.78-160,160-160s160,71.78,160,160v16c-.05,44.16-35.84,79.95-80,80h-32c0-17.67-14.33-32-32-32h-32c-17.67,0-32,14.33-32,32s14.33,32,32,32h96c61.82-.08,111.92-50.18,112-112v-16c0-105.88-86.12-192-192-192s-192,86.12-192,192v16c0,8.84,7.16,16,16,16Zm176-128c52.78-.24,95.76,42.36,96,95.14,.11,23.88-8.73,46.93-24.77,64.62,0,.09,.13,.15,.18,.24h8.59c16.5-.04,31.83-8.57,40.55-22.58,4.45-13.06,7.45-26.84,7.45-41.42,0-70.69-57.31-128-128-128s-128,57.31-128,128c0,40.42,19.1,76,48.35,99.47-.06-1.17-.35-2.28-.35-3.47,.07-11.27,3.16-22.32,8.93-32-35.58-39-32.81-99.45,6.19-135.03,17.73-16.17,40.89-25.09,64.88-24.97Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoTdown: [
        {
            type: 'path',
            data: 'M540.67,231.63c9.34,0,17.68,5.57,21.25,14.2,3.57,8.63,1.62,18.47-4.98,25.07l-190.75,190.75c-8.97,8.97-23.56,8.97-32.53,0l-190.59-190.59c-6.6-6.6-8.56-16.44-4.99-25.06,3.57-8.62,11.91-14.2,21.24-14.2l381.34-.16h0Zm-190.75,201.02l169.01-169.01-337.88,.14,168.87,168.87Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoTpres: [
        {
            type: 'path',
            data: 'M449.21,120.03h-83.44v-35.03c0-3.87-3.13-7-7-7h-17.54c-3.87,0-7,3.13-7,7v35.03h-83.44c-52.27,0-94.8,42.52-94.8,94.8V507c0,52.27,42.53,94.8,94.8,94.8h198.42c52.27,0,94.8-42.53,94.8-94.8V214.83c0-52.28-42.53-94.8-94.8-94.8Zm65.2,386.97c0,35.95-29.25,65.2-65.2,65.2H250.79c-35.95,0-65.2-29.25-65.2-65.2V214.83c0-35.95,29.25-65.2,65.2-65.2h198.42c35.95,0,65.2,29.25,65.2,65.2V507Z',
            viewBox: '0 0 700 700',
        },
        {
            type: 'path',
            data: 'M402.38,341.55c0-5.55-3.14-10.87-8.49-13.4-7.39-3.48-16.21-.32-19.7,7.07l-20.33,43.11c-1.27-.12-2.56-.18-3.86-.18-23.74,0-43.05,19.31-43.05,43.05s19.31,43.05,43.05,43.05,43.05-19.31,43.05-43.05c0-11.77-4.75-22.45-12.43-30.23l20.34-43.12c.96-2.04,1.42-4.19,1.42-6.3Zm-52.38,96.67c-9.39,0-17.02-7.64-17.02-17.02,0-5.74,2.85-10.83,7.22-13.91,2.77-1.97,6.15-3.12,9.8-3.12,8.96,0,16.31,6.95,16.97,15.74,.03,.43,.05,.86,.05,1.29,0,9.38-7.63,17.02-17.02,17.02Z',
        },
        {
            type: 'path',
            data: 'M350,278.17c-74.25,0-134.65,60.41-134.65,134.66,0,32.89,11.99,64.57,33.77,89.18,4.16,4.7,10.12,7.39,16.36,7.39h169.04c6.24,0,12.2-2.69,16.36-7.39,21.78-24.61,33.77-56.29,33.77-89.18,0-74.25-60.4-134.66-134.65-134.66Zm80.94,201.63h-161.88c-15.57-18.8-24.11-42.45-24.11-66.97,0-57.93,47.13-105.06,105.05-105.06s105.05,47.13,105.05,105.06c0,24.52-8.54,48.17-24.11,66.97Z',
        },
    ],
    ROicoTup: [
        {
            type: 'path',
            data: 'M159.33,468.37c-9.34,0-17.68-5.57-21.25-14.2-3.57-8.63-1.62-18.47,4.98-25.07l190.75-190.75c8.97-8.97,23.56-8.97,32.53,0l190.59,190.59c6.6,6.6,8.56,16.44,4.99,25.06-3.57,8.62-11.91,14.2-21.24,14.2l-381.34,.16h0Zm190.75-201.02l-169.01,169.01,337.88-.14-168.87-168.87Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoUp: [
        {
            type: 'path',
            data: 'M130.44,460.5l7.1,7.1c4.7,4.7,12.3,4.7,17,0l195.4-195.5,195.5,195.5c4.7,4.7,12.3,4.7,17,0l7.1-7.1c4.7-4.7,4.7-12.3,0-17l-211-211.1c-4.7-4.7-12.3-4.7-17,0l-211,211.1c-4.8,4.7-4.8,12.3-.1,17Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoUser: [
        {
            type: 'path',
            data: 'M431.34,382c-28.7,0-42.5,16-89.6,16s-60.8-16-89.6-16c-74.2,0-134.4,60.2-134.4,134.4v41.6c0,26.5,21.5,48,48,48h352c26.5,0,48-21.5,48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4Zm102.4,176c0,8.8-7.2,16-16,16H165.74c-8.8,0-16-7.2-16-16v-41.6c0-56.5,45.9-102.4,102.4-102.4,19.6,0,39.1,16,89.6,16s70-16,89.6-16c56.5,0,102.4,45.9,102.4,102.4v41.6Zm-192-208c70.7,0,128-57.3,128-128s-57.3-128-128-128-128,57.3-128,128,57.3,128,128,128Zm0-224c52.9,0,96,43.1,96,96s-43.1,96-96,96-96-43.1-96-96,43.1-96,96-96Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoUsers: [
        {
            type: 'path',
            data: 'M350,102c-137,0-248,111-248,248s111,248,248,248,248-111,248-248-111-248-248-248Zm128,421.6c-35.9,26.5-80.1,42.4-128,42.4s-92.1-15.9-128-42.4v-13.6c0-35.3,28.7-64,64-64,11.1,0,27.5,11.4,64,11.4s52.8-11.4,64-11.4c35.3,0,64,28.7,64,64v13.6Zm30.6-27.5c-6.8-46.4-46.3-82.1-94.6-82.1-20.5,0-30.4,11.4-64,11.4s-43.4-11.4-64-11.4c-48.3,0-87.8,35.7-94.6,82.1-35.5-38.5-57.4-89.7-57.4-146.1,0-119.1,96.9-216,216-216s216,96.9,216,216c0,56.4-21.9,107.6-57.4,146.1ZM350,214c-48.6,0-88,39.4-88,88s39.4,88,88,88,88-39.4,88-88-39.4-88-88-88Zm0,144c-30.9,0-56-25.1-56-56s25.1-56,56-56,56,25.1,56,56-25.1,56-56,56Z',
            viewBox: '0 0 700 700',
        },
    ],
    ROicoWarn: [
        {
            type: 'path',
            data: 'M332.19,254h35.5c3.4,0,6.1,2.8,6,6.2l-7.5,196c-.1,3.2-2.8,5.8-6,5.8h-20.5c-3.2,0-5.9-2.5-6-5.8l-7.5-196c-.1-3.4,2.6-6.2,6-6.2h0Zm17.8,228c-15.5,0-28,12.5-28,28s12.5,28,28,28,28-12.5,28-28-12.5-28-28-28Zm281.5,52L391.59,118c-18.4-32-64.7-32-83.2,0L68.49,534c-18.4,31.9,4.6,72,41.6,72h479.9c36.8,0,60-40,41.5-72Zm-41.5,40H109.99c-12.3,0-20-13.3-13.9-24L336.09,134c6.1-10.6,21.6-10.7,27.7,0l240,416c6.2,10.6-1.5,24-13.8,24h0Z',
            viewBox: '0 0 700 700',
        },
    ],
}

export default icons
