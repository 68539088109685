import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import custService from './custService'

const initialState = {
    customers: [],
    isCustError: false,
    isCustSuccess: false,
    isCustLoading: false,
    custmessage: '',
}

// Create New Customer
export const createCustomer = createAsyncThunk(
    'cust/create',
    async (custData, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token

            return await custService.createCustomer(custData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update Customer
export const updateCustomer = createAsyncThunk(
    'cust/update',
    async (custData, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token
            console.log('custSlice: cust / update: ', custData)
            return await custService.updateCustomer(custData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Get fran customers
export const getCustomers = createAsyncThunk(
    'cust/getAll',
    async (_, thunkAPI) => {
        try {
            //console.log('thunkAPI state: ', thunkAPI.getState())
            //console.log('Get Customers from SERVER!!!')
            let token = ''
            let userCred = ''

            if (thunkAPI.getState() != null) {
                //console.log('Assign token from thunkAPI')
                //token = thunkAPI.getState().auth.user.token
                userCred = thunkAPI.getState().auth.user
            }

            console.log('userCred: ', userCred)

            if (userCred.token) {
                //console.log('Send thunkAPI token to server')
                //const returnData = await custService.getCustomers(token)
                const returnData = await custService.getCustomers(userCred)
                //console.log('getCustomers ReturnData: ', returnData)
                return returnData
            }
            return thunkAPI.rejectWithValue('No user token found')
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            console.log('thunkAPI GetCust error: ', message)
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Test Redux Function
// export const testCustomers = () => {
//     console.log('Dispatch test')
// }

// export const testCustomers = createAsyncThunk('cust/test', async () => {
//     console.log('Dispatch test')
// })

// Delete customer
export const deleteCustomer = createAsyncThunk(
    'cust/delete',
    async (id, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token
            return await custService.deleteCustomer(id, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const custSlice = createSlice({
    name: 'cust',
    initialState,
    reducers: {
        resetCust: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(createCustomer.pending, (state) => {
                state.isCustSuccess = false
                state.isCustLoading = true
                state.isCustError = false
            })
            .addCase(createCustomer.fulfilled, (state, action) => {
                state.isCustLoading = false
                state.isCustSuccess = true
                state.isCustError = false
                //This is what redux helps to do. Change immutable values
                // state.customers = JSON.parse(JSON.stringify(state.customers))
                // console.log('Cust State Before: ', state.customers)
                // console.log('aciont payload: ', action.payload)
                if (!state.customers) {
                    state.customers = []
                }
                state.customers.push(action.payload)
            })
            .addCase(createCustomer.rejected, (state, action) => {
                state.isCustLoading = false
                state.isCustError = true
                //This is what redux helps to do. Change immutable values
                state.custmessage = action.payload
            })
            .addCase(updateCustomer.pending, (state) => {
                state.isCustSuccess = false
                state.isCustLoading = true
                state.isCustError = false
            })
            .addCase(updateCustomer.fulfilled, (state, action) => {
                state.isCustLoading = false
                state.isCustSuccess = true
                state.isCustError = false
                //This is what redux helps to do. Change immutable values
                //state.customers.push(action.payload)
                //state.customers = JSON.parse(JSON.stringify(state.customers))
                state.customers = state.customers.map((cust) => {
                    if (cust.CustID === action.payload.CustID) {
                        return action.payload
                    } else {
                        return cust
                    }
                })
            })
            .addCase(updateCustomer.rejected, (state, action) => {
                state.isCustLoading = false
                state.isCustError = true
                //This is what redux helps to do. Change immutable values
                state.custmessage = action.payload
            })
            .addCase(getCustomers.pending, (state) => {
                state.isCustLoading = true
                state.isCustError = false
            })
            .addCase(getCustomers.fulfilled, (state, action) => {
                state.isCustLoading = false
                state.isCustSuccess = true
                state.isCustError = false
                //This is what redux helps to do. Change immutable values
                //console.log('Customer State Before: ', state.customers)
                state.customers = action.payload
            })
            .addCase(getCustomers.rejected, (state, action) => {
                state.isCustLoading = false
                state.isCustError = true
                //This is what redux helps to do. Change immutable values
                state.custmessage = action.payload
            })
            .addCase(deleteCustomer.pending, (state) => {
                state.isCustSuccess = false
                state.isCustLoading = true
                state.isCustError = false
            })
            .addCase(deleteCustomer.fulfilled, (state, action) => {
                state.isCustLoading = false
                state.isCustSuccess = true
                state.isCustError = false
                //Need to alter state and trigger to remove from UI
                // state.customers = JSON.parse(JSON.stringify(state.customers))
                // console.log('Cust State Before: ', state.customers)
                // console.log('aciont payload: ', action.payload)
                state.customers = state.customers.filter(
                    (cust) => cust.CustID !== action.payload.id
                )
                // console.log('Cust State After: ', state.customers)
            })
            .addCase(deleteCustomer.rejected, (state, action) => {
                state.isCustLoading = false
                state.isCustError = true
                //This is what redux helps to do. Change immutable values
                state.custmessage = action.payload
            })
    },
})

export const { resetCust } = custSlice.actions
export default custSlice.reducer
