import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import myUUid from '../features/myUUid'
import { toast } from 'react-toastify'
import ContentBox from './ContentBox'
import ServiceItem from './ServiceItem'
import ROIcon from './ROIcon'
import { formatInputDate } from '../features/formatTime'
import { getParts, resetparts } from '../features/parts/partsSlice'
import { VaildateForm } from '../features/globalFunctions'

//Import Redux State/Global Var and Axiox database calls
import {
    createService,
    getServices,
    resetservices,
} from '../features/services/servicesSlice.js'

import ROComboBox from './ROComboBox'
import { formatLongDate } from '../features/formatTime'

const AllServicesItem = ({
    equipcustlocitem,
    editOnly = false,
    containerBackColor,
    initDisplayAmount = 2,
    collapse,
    _expanedState,
    _editState,
}) => {
    const dispatch = useDispatch()

    //Get State user from redux
    const { user } = useSelector((state) => state.auth)

    console.log('equipcustlocitem: ', equipcustlocitem)
    console.log('All Services DRAW')

    //Default Values
    containerBackColor = null ? 'bg-secondary' : containerBackColor
    _expanedState = null ? false : _expanedState //Expanded or not
    _editState = null ? false : _editState //Editing item or not

    //States
    const [toggleState, setToggleState] = useState(_expanedState)

    //#region All Services
    const { services, isServicesError, servicesmessage } = useSelector(
        (state) => state.service
    )

    // New Service
    const [showNewService, setShowNewService] = useState(false)

    const [txtServiceDate, setTxtServiceDate] = useState(
        formatInputDate(Date())
    )

    const [txtServiceRepID, setTxtServiceRepID] = useState(-1)
    const [txtServiceRef, setTxtServiceRef] = useState('')
    const [txtTDSSystem, setTxtTDSSystem] = useState('')
    const [txtTDSFeed, setTxtTDSFeed] = useState('')
    const [txtFeedPressure, setTxtFeedPressure] = useState('')
    const [txtTankPressure, setTxtTankPressure] = useState('')
    const [boolControls, setBoolControls] = useState(false)
    const [boolSuccessful, setBoolSuccessful] = useState(false)
    const [txtComments, setTxtComments] = useState('')

    //Max Amount of service history to show
    const [displayAmount, setDisplayAmount] = useState(initDisplayAmount)

    const [arrServiceParts, setArrServiceParts] = useState([])

    //#region New Service Form Validation
    const [boolServiceFormValidated, setServiceFormValidated] = useState(false)

    const valServiceRef = useRef()
    const valServiceDate = useRef()
    const valServiceTechnician = useRef()
    const valServiceTDSFeed = useRef()
    const valServiceTDSSystem = useRef()
    const valServiceFeedPressure = useRef()
    const valServiceTankPressure = useRef()

    const vaildateServiceForm = (clicked = false) => {
        if (!showNewService) return

        setServiceFormValidated(
            VaildateForm(
                [
                    {
                        ref: valServiceRef,
                        text: 'No Special Characters',
                        form: 'New Service valServiceRef',
                    },
                    {
                        ref: valServiceDate,
                        text: 'Invalid Date',
                        form: 'New Service valServiceRef',
                    },
                    {
                        ref: valServiceTechnician,
                        text: 'Invalid Technician',
                        form: 'New Service valServiceTechnician',
                    },
                    {
                        ref: valServiceTDSFeed,
                        text: 'Invalid Value',
                        form: 'New Service valServiceTDSFeed',
                    },
                    {
                        ref: valServiceTDSSystem,
                        text: 'Invalid Value',
                        form: 'New Service valServiceTDSSystem',
                    },
                    {
                        ref: valServiceFeedPressure,
                        text: 'Invalid Value',
                        form: 'New Service valServiceFeedPressure',
                    },
                    {
                        ref: valServiceTankPressure,
                        text: 'Invalid Value',
                        form: 'New Service valServiceTankPressure',
                    },
                ],
                clicked
            )
        )
    }

    useEffect(() => {
        //REdraw on validation change
        vaildateServiceForm()
        //Do validation on ROCombobox changes Service Rep ID
    }, [txtServiceRepID])
    //#endregion

    //Services
    const ShowNewService = (show = false) => {
        setShowNewService(show)
        setServiceFormValidated(false)
        if (show) {
            //console.log('getParts')
        } else {
            resetForm()
        }
    }

    const resetForm = () => {
        setTxtServiceDate(formatInputDate(Date()))
        setTxtServiceRef('')
        setTxtTDSSystem('')
        setTxtTDSFeed('')
        setTxtFeedPressure('')
        setTxtTankPressure('')
        setBoolControls(false)
        setBoolSuccessful(false)
        setTxtComments('')
        setArrServiceParts([])
    }

    const [serviceTotal, setServiceTotal] = useState(0)

    const AddNewService = () => {
        //console.log('user._id: ', user._id)
        const newService = {
            p_EquipCustLocID: equipcustlocitem.EquipCustLocID,
            p_UserID: txtServiceRepID,
            p_ServiceWorkRef: txtServiceRef,
            p_ServiceDate: txtServiceDate,
            p_ServiceTDSSystem: txtTDSSystem,
            p_ServiceTDSFeed: txtTDSFeed,
            p_ServiceFeedPressure: txtFeedPressure,
            p_ServiceTankPressure: txtTankPressure,
            p_ServiceControls: boolControls,
            p_ServiceNotes: txtComments,
            p_ServiceSuccessful: boolSuccessful,
            p_ServiceCustom1: '',
            p_ServiceCustom2: '',
            p_ServiceCustom3: '',
            p_json: JSON.stringify(arrServiceParts),
        }

        console.log('AddNewService:', newService)

        ShowNewService(false)

        dispatch(createService(newService))
    }

    /*Parts*/
    //Get State parts from redux
    const { parts, isPartsError, isPartsSuccess, partsmessage } = useSelector(
        (state) => state.parts
    )

    const addPart = (partID) => {
        let serviceParts = [...arrServiceParts]

        let newPart = parts.find((part) => part.PartID === partID)

        let newServiceItem = {
            ServicePartID: newPart.partID,
            ServicePartCode: newPart.PartCode,
            ServicePartName: newPart.PartName.replace('"', '\\"'),
            ServicePartSerialNR: '',
            ServicePartQty: 1,
            ServicePartPrice: newPart.PartLastPrice,
            ServicePartCategory: newPart.PartCategory.replace('"', '\\"'),
            ServicePartNotes: '',
            ServicePartFlags: '',
        }

        // console.log(
        //     'newPart.PartCategory: ',
        //     newPart.PartCategory.replace('"', '\\"')
        // )

        serviceParts.push(newServiceItem)
        setArrServiceParts(serviceParts)

        console.log('arrServiceParts: ', arrServiceParts)

        console.log('call total')
        calcTotalService(serviceParts)
    }

    const updateServicePart = (i, newpriceobj) => {
        let serviceParts = [...arrServiceParts]

        serviceParts[i] = { ...serviceParts[i], ...newpriceobj }
        setArrServiceParts(serviceParts)
        console.log('arrServiceParts: ', arrServiceParts)
        calcTotalService(serviceParts)
    }

    const removeServicePart = (i) => {
        let serviceParts = [...arrServiceParts]
        serviceParts.splice(i, 1)

        setArrServiceParts(serviceParts)
        console.log('arrServiceParts: ', arrServiceParts)
        calcTotalService(serviceParts)
    }

    const calcTotalService = (partslist) => {
        let total = 0

        console.log('partslist: ', partslist)

        partslist.map(
            (part) => (total += part.ServicePartPrice * part.ServicePartQty)
        )

        console.log('total: ', total)
        setServiceTotal(total)
    }

    let arrParts = []
    if (parts) {
        arrParts = parts.map((part) => {
            return {
                Text:
                    '(' +
                    part.PartCode +
                    ') ' +
                    part.PartName +
                    ' - ' +
                    part.PartCategory,
                ID: part.PartID,
            }
        })

        //console.log('arrParts: ', arrParts)
    }

    useEffect(() => {
        try {
            console.log('*** All Services Use Effect Main ***')
            if (isServicesError) {
                console.log('All Services useEffect error: ', servicesmessage)
                toast.error(servicesmessage)
                return
            }

            if (isPartsError) {
                console.log(
                    'All Services Parts useEffect error: ',
                    partsmessage
                )
                toast.error(partsmessage)
                return
            }

            dispatch(getServices())
            dispatch(getParts())

            return () => dispatch(resetservices())
        } catch (error) {
            console.log('AllServices.jsx Dispatch(all) Error: ', error)
            toast.error(error)
        }
    }, [isServicesError, isPartsError])

    //Get State users / staff from redux
    const { franusers, isFUsersError, isFUsersSuccess, franusersmessage } =
        useSelector((state) => state.franusers)

    let arrSalesReps = []
    if (franusers) {
        arrSalesReps = new Set(
            franusers.map((reps) => {
                return { name: reps.name, ID: reps._id }
            })
        )
        arrSalesReps = Array.from(arrSalesReps)
        arrSalesReps = arrSalesReps.map((reps) => {
            return {
                Text: reps.name,
                ID: reps.ID,
            }
        })
    }

    const getRepName = (id) => {
        let retVal = arrSalesReps.find((rep) => {
            console.log('Rep id: ', rep, id)
            if (rep.ID === id) {
                console.log('Choosen Rep id: ', rep)
                return rep.Text
            }
        })

        console.log('getRepName: ', retVal)

        if (retVal) {
            return retVal.Text
        } else {
            return ''
        }
    }

    //Build Array with display amount of services to show.
    //WIP
    const showServices = []

    services.map((serviceitem) => {
        if (serviceitem.EquipCustLocID === equipcustlocitem.EquipCustLocID) {
            showServices.push(serviceitem)

            if (showServices.length > displayAmount) {
                showServices.shift()
            }
        }
    })

    if (collapse && toggleState) {
        setToggleState(false)
        collapse = false
    }

    return (
        <ContentBox
            classTag={
                toggleState
                    ? `custBox content border border-primary p-0 m-0 mb-1 rounded-2 bg-primary`
                    : `custBox content border border-primary p-0 m-0 mb-1 rounded-2 bg-primary`
            }
        >
            {/*Create or Add New Service */}
            <div className='container px-3 pt-0 rounded-2 bg-primary'>
                <div className='row' /*Service Create or Add*/>
                    <div className='ROmaintxt col-12 fs-5'>
                        {showNewService ? (
                            <>
                                <div className='container p-0 m-0 bg-serviceswhite rounded-2'>
                                    Add New Service:
                                    <div className='row' /*Service Date:*/>
                                        <div className='ROnormaltxt col-2 m-0 p-0 ps-2 pt-1'>
                                            Service Date:
                                        </div>
                                        <div className='col-3 m-0 p-0'>
                                            <input
                                                className='txtBox'
                                                value={txtServiceDate}
                                                type='date'
                                                onChange={(e) => {
                                                    setTxtServiceDate(
                                                        e.target.value
                                                    )
                                                    vaildateServiceForm()
                                                }}
                                                ref={valServiceDate}
                                                title='Service Date'
                                                pattern='\d{4}-\d{2}-\d{2}'
                                                required
                                            />
                                        </div>
                                        <div className='ROnormaltxt col-2 m-0 p-0 ps-2 pt-1'>
                                            Service Ref:
                                        </div>
                                        <div className='col-3'>
                                            <input
                                                className='txtBox'
                                                value={txtServiceRef}
                                                placeholder='Service Reference'
                                                onChange={(e) => {
                                                    setTxtServiceRef(
                                                        e.target.value
                                                    )
                                                    vaildateServiceForm()
                                                }}
                                                ref={valServiceRef}
                                                title='Service Reference'
                                                pattern='[a-zA-Z0-9]+'
                                                required
                                            />
                                        </div>
                                        <div className='col-auto me-auto  m-0 p-0 px-0'>
                                            {' '}
                                            <>
                                                {boolServiceFormValidated ? (
                                                    <ROIcon
                                                        icon='ROicoCheck'
                                                        size={24}
                                                        className='icoSVG'
                                                        tooltip='Add Service'
                                                        onClickCallback={() =>
                                                            AddNewService()
                                                        }
                                                        usefill='true'
                                                        color1='#ffffff'
                                                    />
                                                ) : (
                                                    <ROIcon
                                                        icon='ROicoCheck'
                                                        size={24}
                                                        className='icoSVG'
                                                        onClickCallback={() =>
                                                            vaildateServiceForm(
                                                                true
                                                            )
                                                        }
                                                        usefill='true'
                                                        color1='#ff0000'
                                                    />
                                                )}
                                                <ROIcon
                                                    icon='ROicoClose'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Cancel'
                                                    onClickCallback={() =>
                                                        ShowNewService(false)
                                                    }
                                                    usefill='true'
                                                    color1='#ffffff'
                                                />
                                            </>
                                        </div>
                                    </div>
                                    {/* Technician ID */}
                                    <div className='row'>
                                        <div className='ROnormaltxt col-auto fs-5 me-auto '>
                                            <ROComboBox
                                                dataArray={arrSalesReps}
                                                placeholdertext={
                                                    'Search Technician'
                                                }
                                                textVal={getRepName(
                                                    txtServiceRepID
                                                )}
                                                myIDCallback={
                                                    setTxtServiceRepID
                                                }
                                                ref={valServiceTechnician}
                                                required={true}
                                                validationCallBack={() =>
                                                    vaildateServiceForm()
                                                }
                                                allowCustomValue={false}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className='row' /*Service Equip Info:*/
                                    >
                                        <div className='col-2 p-0 m-0 fs-6'>
                                            <input
                                                className='txtBox'
                                                value={txtTDSFeed}
                                                placeholder='TDS Feed'
                                                ref={valServiceTDSFeed}
                                                title='TDS Feed'
                                                pattern='[0-9]+'
                                                onChange={(e) =>
                                                    setTxtTDSFeed(
                                                        e.target.value.replace(
                                                            /[^0-9]+/g,
                                                            ''
                                                        )
                                                    )
                                                }
                                                required
                                            />
                                        </div>
                                        <div className='col-2 p-0 ps-1 m-0 fs-6'>
                                            <input
                                                className='txtBox'
                                                value={txtTDSSystem}
                                                placeholder='TDS System'
                                                ref={valServiceTDSSystem}
                                                title='TDS System'
                                                pattern='[0-9]+'
                                                onChange={(e) =>
                                                    setTxtTDSSystem(
                                                        e.target.value.replace(
                                                            /[^0-9]+/g,
                                                            ''
                                                        )
                                                    )
                                                }
                                                required
                                            />
                                        </div>
                                        <div className='col-2 p-0 ps-1 m-0 fs-6'>
                                            <input
                                                className='txtBox'
                                                value={txtFeedPressure}
                                                placeholder='Feed Pressure'
                                                ref={valServiceFeedPressure}
                                                title='Feed Pressure'
                                                pattern='^[0-9]\d*(\.\d+)?$'
                                                onChange={(e) =>
                                                    setTxtFeedPressure(
                                                        e.target.value.replace(
                                                            /[^0-9\.]+/g,
                                                            ''
                                                        )
                                                    )
                                                }
                                                required
                                            />
                                        </div>
                                        <div className='col-2 p-0 ps-1 m-0 fs-6'>
                                            <input
                                                className='txtBox'
                                                value={txtTankPressure}
                                                ref={valServiceTankPressure}
                                                title='Tank Pressure'
                                                pattern='^[0-9]\d*(\.\d+)?$'
                                                placeholder='Tank Pressure'
                                                onChange={(e) =>
                                                    setTxtTankPressure(
                                                        e.target.value.replace(
                                                            /[^0-9\.]+/g,
                                                            ''
                                                        )
                                                    )
                                                }
                                                required
                                            />
                                        </div>

                                        {/* <ROIcon CheckBox /> */}
                                        <div className='col-2 p-0 ps-2 m-0 fs-6'>
                                            Controls
                                            <ROIcon
                                                icon={
                                                    boolControls
                                                        ? 'ROicoCheckbox'
                                                        : 'ROicoBox'
                                                }
                                                size={24}
                                                className='icoSVG'
                                                tooltip='Controls Tested'
                                                usefill='true'
                                                color1='#ffffff'
                                                onClickCallback={() =>
                                                    setBoolControls(
                                                        (prev) => !prev
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className='col-2 p-0 ps-2 m-0 fs-6'>
                                            Successfull
                                            <ROIcon
                                                icon={
                                                    boolSuccessful
                                                        ? 'ROicoCheckbox'
                                                        : 'ROicoBox'
                                                }
                                                size={24}
                                                className='icoSVG'
                                                tooltip='Service Successful'
                                                usefill='true'
                                                color1='#ffffff'
                                                onClickCallback={() =>
                                                    setBoolSuccessful(
                                                        (prev) => !prev
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className='row' /*Service User + Comments*/
                                    >
                                        <div className='col-12 p-0 ps-0 m-0 fs-6'>
                                            <input
                                                className='txtBox'
                                                value={txtComments}
                                                placeholder='Service Comments'
                                                onChange={(e) =>
                                                    setTxtComments(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className='row' /*Service Parts / Items*/
                                    >
                                        {parts ? (
                                            <div className='col-12 p-0 ps-0 m-0 fs-6'>
                                                <ROComboBox
                                                    dataArray={arrParts}
                                                    classname='ROdropdownFull'
                                                    placeholdertext='Search Parts to Add'
                                                    myIDCallback={addPart}
                                                    clearTextOnSelect={true}
                                                    allowCustomValue={false}
                                                />
                                            </div>
                                        ) : (
                                            'Loading Parts'
                                        )}

                                        {/* New Service Parts / Items List */}
                                        {arrServiceParts.map((part, i) => (
                                            <>
                                                <div className='row'>
                                                    <div className='col-2 p-0 m-0'>
                                                        {part.ServicePartCode}
                                                    </div>
                                                    <div className='col-3 p-0 m-0'>
                                                        {part.ServicePartName}
                                                    </div>
                                                    <div className='col-2 p-0 m-0'>
                                                        {' '}
                                                        {/* SERIAL NR */}
                                                        <input
                                                            className='txtBox'
                                                            value={
                                                                part.ServicePartSerialNR ||
                                                                ''
                                                            }
                                                            placeholder='Serial Nr'
                                                            onChange={(e) =>
                                                                updateServicePart(
                                                                    i,
                                                                    {
                                                                        ServicePartSerialNR:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className='col-1 p-0 m-0 ms-1'>
                                                        {' '}
                                                        {/* QTY */}
                                                        <input
                                                            className='txtBox'
                                                            value={
                                                                part.ServicePartQty ||
                                                                ''
                                                            }
                                                            placeholder='Qty'
                                                            onChange={(e) =>
                                                                updateServicePart(
                                                                    i,
                                                                    {
                                                                        ServicePartQty:
                                                                            parseInt(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            ),
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className='col-1 p-0 m-0 ms-1'>
                                                        {/* Price */}
                                                        <input
                                                            className='txtBox'
                                                            value={
                                                                part.ServicePartPrice ||
                                                                ''
                                                            }
                                                            placeholder='Price'
                                                            onChange={(e) =>
                                                                updateServicePart(
                                                                    i,
                                                                    {
                                                                        ServicePartPrice:
                                                                            parseInt(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            ),
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className='col-1 p-0 m-0 ms-2 pt-1'>
                                                        R
                                                        {part.ServicePartPrice *
                                                            part.ServicePartQty ||
                                                            0}
                                                    </div>
                                                    <div className='col-1 p-0 m-0 ms-2 pt-1'>
                                                        <ROIcon
                                                            icon='ROicoDel'
                                                            size={24}
                                                            className='icoSVG'
                                                            tooltip='Remove'
                                                            onClickCallback={() =>
                                                                removeServicePart(
                                                                    i
                                                                )
                                                            }
                                                            usefill='true'
                                                            color1='#f00'
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                    <div className='row'>
                                        <div className='col-10 p-0 m-0'>
                                            &nbsp;
                                        </div>
                                        <div className='col-2 p-0 m-0'>
                                            Total: R{serviceTotal}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                Services
                                {!editOnly ? (
                                    <ROIcon
                                        icon='ROicoPlus'
                                        className='icoSVG'
                                        tooltip='Add Service'
                                        usefill='true'
                                        color1='#ffffff'
                                        onClickCallback={() =>
                                            ShowNewService(true)
                                        }
                                    />
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* Show Location Equipment Services */}
            <div className='row p-4 pt-0 mt-0'>
                {services.map((serviceitem) => {
                    if (
                        serviceitem.EquipCustLocID ===
                        equipcustlocitem.EquipCustLocID
                    ) {
                        return (
                            <ServiceItem
                                //containerBackColor={containerBackColor}
                                containerBackColor={'bg-primary'}
                                service={serviceitem}
                            />
                        )
                    }
                })}
            </div>
        </ContentBox>
    )
}

export default AllServicesItem
