import { toast } from 'react-toastify'

export const getServiceInterval = (data, equipcustlocitem) => {
    let txtInterval = ''
    if (data.EquipCustLocServiceIntervalType > 30) {
        txtInterval = 'Year'
    } else if (data.EquipCustLocServiceIntervalType > 7) {
        txtInterval = 'Months'
    } else if (data.EquipCustLocServiceIntervalType > 1) {
        txtInterval = 'Weeks'
    } else {
        txtInterval = 'Days'
    }

    return (
        data.EquipCustLocDaysTillNextService /
            data.EquipCustLocServiceIntervalType +
        ' ' +
        txtInterval +
        ' Interval'
    )
}

export const daysDiff = (date_1, date_2) => {
    let date1 = new Date(date_1)
    let date2 = new Date(date_2)

    // let difference = date1.getTime() - date2.getTime()
    // let TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
    // return TotalDays

    let date1mil = date1.getTime()
    let date2mil = date2.getTime()

    let difference = date1mil - date2mil

    let TotalDays = difference / 86400000

    return Math.ceil(TotalDays)
}

export const addErrorLog = (msg, user) => {
    let errorLog = JSON.parse(localStorage.getItem('ROerrorLog'))

    let foundItem = errorLog.find((e) => e.msg === msg)

    if (foundItem) {
        foundItem.count++
        foundItem.DateTime = Date.now()
    } else {
        let newLogEntry = {
            msg: msg,
            DateTime: Date.now(),
            user: user,
            count: 0,
        }

        errorLog.push(newLogEntry)
    }

    localStorage.setItem('ROerrorLog', JSON.stringify(errorLog))
}

//#region Validation

export const VaildateForm = (refArray, clicked = false) => {
    try {
        let validForm = true

        //console.log('refArray: ', refArray)

        if (refArray && refArray.length > 0) {
            refArray.forEach((item) => {
                if (item.ref.current) {
                    //console.log('Validating item: ', item)
                    validForm = ValidateFormObj(item.ref, clicked, item.text)
                        ? validForm
                        : false
                } else if (!item.ref.current && item.text !== '') {
                    // console.log(
                    //     'Incorrect element passed for validation refArray: ',
                    //     item,
                    //     refArray
                    // )
                    toast.error(
                        item.form +
                            ' Error: Incorrect element passed for validation'
                    )
                    validForm = false
                }
            })
        }

        //console.log('VaildateForm return: ', validForm)

        return validForm
    } catch (error) {
        console.log('globalFunctions.js(vaildateServiceForm): ', error)
        toast.error('globalFunctions.js(vaildateServiceForm): ' + error)
    }
}

const ValidateFormObj = (formObj, clicked, customMsg = '') => {
    //console.log('validateObj: ', formObj)

    let titleTxt = formObj.current.title ? formObj.current.title + ': ' : ''

    if (!formObj.current.validity.valid) {
        if (clicked) {
            if (formObj.current.validity.badInput) {
                toast.error(titleTxt + formObj.current.validationMessage)
            } else if (formObj.current.validity.valueMissing) {
                toast.error(titleTxt + 'Required')
            } else {
                if (customMsg !== '') {
                    toast.error(titleTxt + customMsg)
                } else {
                    toast.error(titleTxt + formObj.current.validationMessage)
                }
            }
        }
        return false
    }
    return true
}

//#endregion
