import React from 'react'
import ContentBox from '../ContentBox'
import { useState } from 'react'
import ROIcon from '../../components/ROIcon'
import ServiceItem from '../ServiceItem'

import { getServiceInterval, daysDiff } from '../../features/globalFunctions'
import { formatShortDate } from '../../features/formatTime'

const RepServiceOverdueItem = ({
    _LocDetails,
    classTag,
    containerBackColor,
    collapse,
}) => {
    const [toggleState, setToggleState] = useState(false)

    const changeToggleState = () => {
        setToggleState((prev) => !prev)
    }

    console.log('_LocDetails: ', _LocDetails)

    return (
        <ContentBox
            classTag={
                toggleState
                    ? `custBox content border border-primary p-0 m-0 mb-1 rounded-2 ${containerBackColor}`
                    : `custBox content border border-light p-0 m-0 mb-1 rounded-2 ${containerBackColor}`
            }
        >
            {/*Container Div*/}
            <div
                className='container p-2 pt-0 rounded-2'
                onClick={() => changeToggleState()}
            >
                <div className='row' /*CustInfo*/>
                    {/*Customer Name*/}
                    <div className='custName col-4 pl-2 fs-3'>
                        {_LocDetails.CustName +
                            ' (' +
                            _LocDetails.CustLocName +
                            ')'}
                    </div>
                    {/*Contact Number*/}
                    <div className='custName col-3 pl-0 fs-3'>
                        {_LocDetails.CustPriContactNumber +
                            ' (' +
                            _LocDetails.CustPriContactName +
                            ')'}
                    </div>
                    <div className='custName col-4 px-0'>
                        <ROIcon
                            icon='ROicoLocation'
                            size={32}
                            className='icoSVG'
                        />
                        {_LocDetails.CustLocStreetAddress +
                            ', ' +
                            _LocDetails.CustLocSuburb +
                            ', ' +
                            _LocDetails.CustLocCity}
                    </div>
                    {/* Contact Email
                    <div className='custName col-3 pl-2 fs-3'>
                        {_LocDetails.CustPriContactEmail}
                    </div> */}
                    {/*Customer Expande/Collapse Icon*/}
                    <div className='col-auto px-2'>
                        <ROIcon
                            icon={toggleState ? 'ROicoTup' : 'ROicoTdown'}
                            className='icoSVG'
                            //onClickCallback={changeToggleState}
                        />
                    </div>
                </div>

                <div className='row'></div>
                {toggleState
                    ? _LocDetails.Contacts.map((contact) => {
                          if (contact.CustLocContactPrimary !== 1) {
                              return (
                                  <>
                                      <div className='row '>
                                          {/* Blank Spacer Col */}
                                          <div className=' col-4 fs-5 m-0 p-0'></div>
                                          <div className=' col-3 fs-5 m-0 p-0'>
                                              <ROIcon
                                                  icon='ROicoUser'
                                                  size={24}
                                                  className='icoSVG'
                                              />
                                              {contact.CustLocContactName}{' '}
                                              <br />{' '}
                                          </div>
                                          <div className=' col-2 fs-5 m-0 p-0'>
                                              {contact.CustLocContactNumber}
                                          </div>
                                          <div className=' col-3 fs-5 m-0 p-0'>
                                              {contact.CustLocContactEmail}
                                          </div>
                                      </div>
                                  </>
                              )
                          }
                      })
                    : ''}

                {_LocDetails.Equipment.map((item) => {
                    let daysFromLastService = daysDiff(
                        new Date(),
                        item.LastServiceRecorded
                    )

                    console.log('daysFromLastService: ', daysFromLastService)

                    return (
                        <>
                            <div className='row px-5'>
                                <hr className='services p-0 m-0' />
                                {/*Big Equipment Icon */}
                                <div className='col-auto px-1'>
                                    <ROIcon
                                        icon='ROicoEquip'
                                        className='icoSVG p-0 m-0'
                                        size={32}
                                    />
                                </div>
                                <div className='custName col-3 fs-5 m-0 p-0'>
                                    {item.EquipInfo.EquipCode} <br />
                                    <span className='fs-6 fw-light '>
                                        {item.EquipInfo.EquipName}
                                    </span>
                                </div>
                                <div className='custName col-5 fs-5 m-0 p-0'>
                                    <span className='fs-6 fw-light '>
                                        {
                                            item.EquipCustLoc
                                                .EquipCustLocRoomPlace
                                        }
                                    </span>
                                </div>
                                <div className='custName col-3 fs-5 m-0 p-0'>
                                    <ROIcon
                                        icon='ROicoService'
                                        size={32}
                                        className='icoSVG'
                                    />
                                    {/*Service Overdue by *?* days*/}
                                    {daysFromLastService >
                                    item.EquipCustLoc
                                        .EquipCustLocDaysTillNextService
                                        ? 'Service Overdue by ' +
                                          (daysFromLastService -
                                              item.EquipCustLoc
                                                  .EquipCustLocDaysTillNextService) +
                                          ' days'
                                        : daysFromLastService +
                                          ' days since last service'}
                                    <br />
                                    <span className='fs-6 fw-light '>
                                        {getServiceInterval(item.EquipCustLoc) +
                                            ' (' +
                                            formatShortDate(
                                                item.LastServiceRecorded
                                            ) +
                                            ')'}
                                    </span>
                                </div>
                            </div>

                            <div
                                className='row text-white  px-5 p-2' /*EquipInfo Expanded*/
                            >
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* TODO Change Background of service to make it more print friendly */}
                                {/* <AllServicesItem
                                    containerBackColor='bg-white'
                                    editOnly={true}
                                    equipcustlocitem={item.EquipCustLoc}
                                /> */}
                                {item.equipServices.map((service) => {
                                    return (
                                        <ServiceItem
                                            containerBackColor={
                                                containerBackColor
                                            }
                                            small={true}
                                            service={service}
                                        />
                                    )
                                })}

                                {console.log(
                                    'item.Equipment: ',
                                    item.equipServices
                                )}
                            </div>
                        </>
                    )
                })}
            </div>
        </ContentBox>
    )
}

export default RepServiceOverdueItem
