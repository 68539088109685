import { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import CustItem from '../components/CustItem'
import { toast } from 'react-toastify'
import myUUid from '../features/myUUid'
import ROIcon from '../components/ROIcon'

import { getCustomers, resetCust } from '../features/cust/custSlice'

//Import Franchise User Redux State/Global Var and Axiox database calls
import { getUsers, resetusers } from '../features/users/usersSlice'

//Import Customer Locations Redux State/Global Var and Axiox database calls
import {
    getCustomersLoc,
    resetLocations,
} from '../features/custloc/custlocSlice'

//Import Customer Location Contacts Redux State/Global Var and Axiox database calls
import {
    getCustomerLocContacts,
    resetLocContacts,
} from '../features/custloccontacts/custloccontactsSlice'

//Import Customer Location Equipment Contacts Redux State/Global Var and Axiox database calls
import { getEquipment, resetequip } from '../features/equip/equipSlice'

const Customers = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    console.log('*** Cust Main Draw ***')

    //Get State user from redux
    const { user, message, isError } = useSelector((state) => state.auth)

    //Get State users / staff from redux
    // const { franusers, isFUsersError, isFUsersSuccess, franusersmessage } =
    //     useSelector((state) => state.franusers)

    //Get State customers from redux
    const { customers, isCustError, isCustSuccess, custmessage } = useSelector(
        (state) => state.customers
    )

    //Get State customer locations from redux
    const { loccustomers, isLocError, isLocSuccess, locmessage } = useSelector(
        (state) => state.loccustomers
    )

    //Get State customer location contacts from redux
    const { custloccontacts, isErrorLocContacts, custloccontmessage } =
        useSelector((state) => state.loccustcontacts)

    /*Parts*/
    //Get State parts from redux
    // const { parts, isPartsError, isPartsSuccess, partsmessage } = useSelector(
    //     (state) => state.parts
    // )

    // const { isSuccessContacts } = useSelector((state) => state.custcontacts)
    // const { isLocSuccess } = useSelector((state) => state.loccustomers)
    // const { isSuccessLocContacts } = useSelector(
    //     (state) => state.loccustcontacts
    // )

    const [newCustToggle, setNewCustToggle] = useState(false)
    let [searchString, setSearchString] = useState('')
    const [searchFound, setSearchFound] = useState(false)
    const [newCustID, setNewCustID] = useState(-1)

    let searchResults = [...customers]

    //Just a state variable to call to trigger a rerender for collapse change
    const [getCollapseAll, setCollapseAll] = useState(false)

    const AddNewCustToggle = () => {
        console.log('AddNewCustToggle')
        setNewCustToggle((prev) => !prev)
    }

    const CollapseAll = () => {
        setCollapseAll(true)
    }

    const RefreshCustDetails = (queryDB = true, getNewCustID = -1) => {
        setNewCustToggle(false)
        console.log('New Cust Added - RESET FORM')

        if (getNewCustID !== -1) {
            setNewCustID(getNewCustID)
        }

        if (queryDB) {
            console.log('QueryDB')
            dispatch(getCustomers())
            dispatch(getCustomersLoc())
            dispatch(getCustomerLocContacts())
        }
    }

    // if (
    //     newCustID !== -1 &&
    //     loccustomers &&
    //     loccustomers.some((loccust) => loccust.CustID !== newCustID)
    // ) {
    //     console.log('newCustID: ', newCustID)
    //     RefreshCustDetails(true, newCustID)
    // } else {
    //     if (newCustID !== -1) {
    //         setNewCustID(-1)
    //     }
    // }

    const SearchCustomers = () => {
        //console.log('SearchCustomers')
        //setSearchString(_searchstr)

        if (searchString !== '') {
            const _searchstr = searchString.toLowerCase()

            // console.log('Search String: ', _searchstr)
            // console.log('Search item: ', customers)

            let custContIDArray = []

            custContIDArray = custloccontacts.map((cont) => {
                if (
                    String(cont.CustLocContactName)
                        .toLowerCase()
                        .includes(_searchstr) ||
                    String(cont.CustLocContactNumber)
                        .toLowerCase()
                        .includes(_searchstr) ||
                    String(cont.CustLocContactEmail)
                        .toLowerCase()
                        .includes(_searchstr)
                ) {
                    return cont.CustLocID
                } else {
                    return null
                }
            })

            let custIDArray = []

            custIDArray = []

            custIDArray = loccustomers.map((loc) => {
                if (
                    String(loc.CustLocName)
                        .toLowerCase()
                        .includes(_searchstr) ||
                    String(loc.CustLocStreetAddress)
                        .toLowerCase()
                        .includes(_searchstr) ||
                    String(loc.CustLocSuburb)
                        .toLowerCase()
                        .includes(_searchstr) ||
                    String(loc.CustLocCity)
                        .toLowerCase()
                        .includes(_searchstr) ||
                    String(loc.CustLocPostalCode)
                        .toLowerCase()
                        .includes(_searchstr) ||
                    String(loc.CustLocNotes)
                        .toLowerCase()
                        .includes(_searchstr) ||
                    custContIDArray.includes(loc.CustLocID)
                ) {
                    return loc.CustID
                } else {
                    return null
                }
            })

            console.log('custIDArry: ', custIDArray)

            if (customers) {
                searchResults = customers.filter((item) => {
                    return (
                        String(item.CustName)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        String(item.CustStreetAddress)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        String(item.CustSuburb)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        String(item.CustCity)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        String(item.CustEmail)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        String(item.CustPostalCode)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        String(item.CustNotes)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        String(item.CustCompanyReg)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        String(item.CustCompanyTax)
                            .toLowerCase()
                            .includes(_searchstr) ||
                        custIDArray.includes(item.CustID)
                    )
                })
            }
        }
    }

    useEffect(() => {
        console.log('*** Customers Use Effect Main ***')

        if (isError) {
            console.log('Customers USERS useEffect error: ', message)
            navigate('/')
            toast.error(message)
            return
        }

        if (isCustError) {
            console.log('Customers useEffect error: ', custmessage)
            toast.error('Customer Error:  ' + custmessage)
            navigate('/')
            return
        }

        if (isLocError) {
            console.log('Customers location useEffect error: ', locmessage)
            toast.error('CustLoc Error:  ' + locmessage)
            navigate('/')
            return
        }

        if (isErrorLocContacts) {
            console.log(
                'Customers location contact useEffect error: ',
                custloccontmessage
            )
            toast.error('CustLoc Contact Error:  ' + custloccontmessage)
            navigate('/')
            return
        }

        try {
            //if user is null do not try to load customers.
            if (!user || !user._id || !user.token) {
                console.log('LOGOUT!!!')
                //toast.error('Logged out')
                dispatch(logout())
                dispatch(reset())
                navigate('/')
                return
            }
        } catch (error) {
            console.log('Customers.jsx Dispatch(reset) Error: ', error)
            toast.error('Customers Error: ' + error)
            dispatch(reset())
            navigate('/login')
        }

        try {
            console.log('Get All Data from servers')
            // console.log('Get All Data from servers(getUsers!!!)')
            dispatch(getUsers())
            //console.log('Get All Data from servers(getCustomers!!!)')
            dispatch(getCustomers())

            if (customers && customers.length > 0 && !isError && !isCustError) {
                //console.log('Get All Data from servers(getCustomersLoc!!!)')
                dispatch(getCustomersLoc())
                // console.log(
                //     'Get All Data from servers(getCustomerLocContacts!!!)'
                // )
                dispatch(getCustomerLocContacts())
                //console.log('Get All Data from servers(getEquipment!!!)')
                dispatch(getEquipment())
            }
        } catch (error) {
            console.log('Customers.jsx Dispatch(all) Error: ', error)
            toast.error(error)
            return () => {
                dispatch(resetCust())
                dispatch(resetLocations())
                dispatch(resetLocContacts())
            }
        }

        return () => {
            dispatch(resetCust())
            dispatch(resetLocations())
            dispatch(resetLocContacts())
        }
    }, [user, isError, isCustError, isLocError, isErrorLocContacts]) //dispatch
    //, isError, navigate, dispatch, message, customersData

    useEffect(() => {
        if (customers && customers.length > 0) {
            console.log('Use Effect Main Cust Customers Changed')
            //dispatch(getCustomers())
            dispatch(getCustomersLoc())
            dispatch(getCustomerLocContacts())
        }
    }, [customers])

    useEffect(() => {
        console.log('Use Effect Collapse All')
        if (getCollapseAll) {
            setCollapseAll(false)
        }
    }, [getCollapseAll])

    //Only update/redraw if search results changed. Or else it will redraw on each letter type even if results didn't change
    //used to check if customers is populated and to only search once on init
    // if (
    //     customers != null &&
    //     customers.length > 0 &&
    //     JSON.stringify(customersData) !== JSON.stringify(customers)
    // ) {
    //     console.log('more search')
    //     setCustomersData(customers)
    //     SearchCustomers(searchString)
    // }

    useMemo(() => {
        console.log('Call Customer Search Function')
        //return () => SearchCustomers()
        SearchCustomers()
    }, [searchString, customers])

    return (
        <>
            <section className='pageHead container clearfix '>
                {/* <h6>Welcome {user && user.name}</h6> */}
                <div className='pageFunctions row header sticky-top py-3'>
                    <div className='col-4'>
                        <h4>
                            Your Customers &nbsp;
                            {/* New Customer */}
                            <ROIcon
                                icon={
                                    newCustToggle ? 'ROicoClose' : 'ROicoPlus'
                                }
                                size={24}
                                className='icoSVG'
                                textbefore={newCustToggle ? 'Cancel' : ' '}
                                tooltip={
                                    newCustToggle
                                        ? 'Cancel'
                                        : 'Add New Customer'
                                }
                                onClickCallback={() => AddNewCustToggle()}
                            />
                        </h4>
                    </div>
                    <div className='interfSearch align-middle col-4 me-auto'>
                        {/* <label htmlFor='search'>Search</label> */}
                        <input
                            id='search'
                            placeholder='Enter your search here'
                            value={searchString}
                            //onChange={(e) => SearchCustomers(e.target.value)}
                            onChange={(e) => setSearchString(e.target.value)}
                        />
                    </div>
                    {searchFound ? (
                        ''
                    ) : (
                        <p className='danger'>
                            Search doens't match any customer
                        </p>
                    )}

                    <div className='col-auto'>
                        <ROIcon
                            icon='ROicoUp'
                            size={24}
                            className='icoSVG'
                            tooltip='Collapse All'
                            onClickCallback={() => CollapseAll()}
                        />
                        <ROIcon
                            icon='ROicoHelp'
                            size={24}
                            className='icoSVG'
                            tooltip='Help'
                        />
                    </div>

                    {newCustToggle ? (
                        <CustItem
                            key={myUUid()}
                            newItem={true}
                            UserID={user._id}
                            expanedState={true}
                            refreshCustCallback={RefreshCustDetails}
                        />
                    ) : (
                        ''
                    )}
                </div>
                <div className='pageContent container'>
                    {/*searchResults.map((cust, i) => console.log(cust))*/}
                    {/* Display items by search results. (If no search will display all customers) */}
                    {/*console.log('Draw CustItems!!!')*/}
                    {/*console.log('draw search results', searchResults)*/}
                    {searchResults &&
                    searchResults.length > 0 &&
                    searchResults[0].CustID
                        ? searchResults.map((cust, i) => (
                              <CustItem
                                  key={'cust' + cust.CustID}
                                  //key={myUUid()}
                                  UserID={user._id}
                                  containerBackColor={
                                      (i + 1) % 2 === 0
                                          ? 'bg-white m-0 p-0'
                                          : 'bg-secondary m-0 p-0'
                                  }
                                  ChildOjb={''}
                                  refreshCustCallback={RefreshCustDetails}
                                  expanedState={false}
                                  collapse={getCollapseAll}
                                  CustItemsList={searchResults}
                                  _CustDetails={{
                                      CustID: cust.CustID,
                                      CustName: cust.CustName,
                                      CustEmail: cust.CustEmail,
                                      CustReg: cust.CustCompanyReg,
                                      CustTax: cust.CustCompanyTax,
                                      //   CustStreet: cust.CustStreetAddress,
                                      //   CustSub: cust.CustSuburb,
                                      //   CustCity: cust.CustCity,
                                      //   CustPostal: cust.CustPostalCode,
                                      //   CustCountry: cust.CustCountry,
                                      // CustContacts:
                                      //     cust.CustNotes[0] != null
                                      //         ? cust.CustNotes
                                      //         : '',
                                  }}
                              />
                          ))
                        : ''}
                </div>
            </section>
            <br />
        </>
    )
}

export default Customers
