import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import myUUid from '../features/myUUid'
import ContentBox from './ContentBox'
import ROIcon from './ROIcon'
import { formatInputDate } from '../features/formatTime'
import { VaildateForm } from '../features/globalFunctions'

//Import Redux State/Global Var and Axiox database calls
import {
    updateService,
    deleteService,
} from '../features/services/servicesSlice.js'

import ROComboBox from './ROComboBox'
import { formatTime, formatShortDate } from '../features/formatTime'

const ServiceItem = ({
    service,
    arrCategories,
    small = false,
    containerBackColor,
    collapse,
    _expanedState,
    _editState,
}) => {
    const dispatch = useDispatch()

    // if (service) {
    //     console.log(`*** Service  ${service.ServiceWorkRef}  Draw ***`, service)
    // }

    console.log(`*** Service Draw ***`)

    /* JSON Service Items / Parts */

    //console.log('service.servicejson from allServices: ', service.servicejson)

    const jsonparts = service.servicejson ? JSON.parse(service.servicejson) : []

    const [serviceTotal, setServiceTotal] = useState(0)

    let partsListStr = ''

    //partListStr the quick string to show which parts are in the service on compact view
    jsonparts.map((part, i) => {
        part.ServicePartCategory = part.ServicePartCategory.replace('"', '\\"')
        part.ServicePartName = part.ServicePartName.replace('"', '\\"')

        if (i < jsonparts.length - 1) {
            partsListStr += part.ServicePartCode + ', '
        } else {
            partsListStr += part.ServicePartCode
        }
    })

    //Default Values
    containerBackColor = null ? 'bg-secondary' : containerBackColor
    _expanedState = null ? false : _expanedState //Expanded or not
    _editState = null ? false : _editState //Editing item or not

    //States
    const [toggleState, setToggleState] = useState(_expanedState)
    const [editState, setEditState] = useState(_editState)
    const [confirmDelete, setDeleteState] = useState(false)

    //Edit Service
    const [txtServiceDate, setTxtServiceDate] = useState(
        service ? formatInputDate(service.ServiceDate) : formatInputDate(Date())
    )

    const [txtServiceTechID, setTxtServiceTechID] = useState(
        service ? service.UserID : -1
    )

    const [txtServiceRef, setTxtServiceRef] = useState(
        service ? service.ServiceWorkRef : ''
    )

    const [txtTDSSystem, setTxtTDSSystem] = useState(
        service ? service.ServiceTDSSystem : ''
    )
    const [txtTDSFeed, setTxtTDSFeed] = useState(
        service ? service.ServiceTDSFeed : ''
    )
    const [txtFeedPressure, setTxtFeedPressure] = useState(
        service ? service.ServiceFeedPressure : ''
    )
    const [txtTankPressure, setTxtTankPressure] = useState(
        service ? service.ServiceTankPressure : ''
    )
    const [boolControls, setBoolControls] = useState(
        service ? service.ServiceControls : false
    )
    const [boolSuccessful, setBoolSuccessful] = useState(
        service ? service.ServiceSuccessful : false
    )
    const [txtComments, setTxtComments] = useState(
        service ? service.ServiceNotes : ''
    )

    const [arrServiceParts, setArrServiceParts] = useState(
        service ? jsonparts : []
    )

    //console.log('ServiceItem On LOAD - txtServiceDate: ', txtServiceDate)
    // console.log(
    //     txtServiceRef + ' - ServiceItem On LOAD - arrServiceParts: ',
    //     arrServiceParts
    // )

    // console.log(
    //     txtServiceRef + ' - ServiceItem On LOAD - jsonparts: ',
    //     jsonparts
    // )

    const editService = (edit = true) => {
        if (!edit) {
            //Reset values to prev if edit is canceled
            setEditState((prev) => !prev)
            setTxtServiceDate(
                service.ServiceDate
                    ? formatInputDate(service.ServiceDate)
                    : formatInputDate(Date())
            )

            console.log('editService setTxtServiceDate: ', txtServiceDate)

            setTxtServiceTechID(service.UserID ? service.UserID : -1)

            setTxtServiceRef(
                service.ServiceWorkRef ? service.ServiceWorkRef : ''
            )

            setTxtTDSSystem(
                service.ServiceTDSSystem ? service.ServiceTDSSystem : ''
            )
            setTxtTDSFeed(service.ServiceTDSFeed ? service.ServiceTDSFeed : '')
            setTxtFeedPressure(
                service.ServiceFeedPressure ? service.ServiceFeedPressure : ''
            )
            setTxtTankPressure(
                service.ServiceTankPressure ? service.ServiceTankPressure : ''
            )
            setBoolControls(
                service.ServiceControls ? service.ServiceControls : false
            )
            setBoolSuccessful(
                service.ServiceSuccessful ? service.ServiceSuccessful : false
            )
            setTxtComments(service.ServiceNotes ? service.ServiceNotes : '')

            console.log(
                txtServiceRef +
                    ' - ServiceItem On Edit Before Assign - arrServiceParts: ',
                arrServiceParts
            )

            setArrServiceParts(jsonparts ? jsonparts : [])

            return
        }

        setArrServiceParts(jsonparts ? jsonparts : [])

        if (editState) {
            const updService = {
                serviceID: service.ServiceID,
                p_EquipCustLocID: service.EquipCustLocID,
                p_UserID: txtServiceTechID,
                p_ServiceWorkRef: txtServiceRef,
                p_ServiceDate: txtServiceDate,
                p_ServiceTDSSystem: txtTDSSystem,
                p_ServiceTDSFeed: txtTDSFeed,
                p_ServiceFeedPressure: txtFeedPressure,
                p_ServiceTankPressure: txtTankPressure,
                p_ServiceControls: boolControls,
                p_ServiceNotes: txtComments,
                p_ServiceSuccessful: boolSuccessful,
                p_ServiceCustom1: '',
                p_ServiceCustom2: '',
                p_ServiceCustom3: '',
                p_json: JSON.stringify(arrServiceParts),
            }

            console.log('updateService(updService): ', updService)

            dispatch(updateService(updService))
            //editService(false)
            //updateCustomerDetails(CustID)
            //refreshCustCallback()
        } else {
            //console.log('Edit EquipName: ', txtEquipName)
        }
        setEditState((prev) => !prev)
    }

    //Delete Service
    const confirmDeleteService = (setState) => {
        if (confirmDelete && setState) {
            console.log('Delete!!!! ServiceID: ', service.ServiceID)
            deleteServiceById(service.ServiceID)
        }
        setDeleteState(setState)
    }

    const deleteServiceById = (_id) => {
        console.log('Delete!!!! Service_ID: ', _id)
        dispatch(deleteService(_id))
        confirmDeleteService(false)
    }

    /*Parts*/
    //Get State parts from redux
    const { parts, isPartsError, isPartsSuccess, partsmessage } = useSelector(
        (state) => state.parts
    )

    const addPart = (partID) => {
        let serviceParts = [...arrServiceParts]

        let newPart = parts.find((part) => part.PartID === partID)

        let newServiceItem = {
            ServicePartID: newPart.partID,
            ServicePartCode: newPart.PartCode,
            ServicePartName: newPart.PartName.replace('"', '\\"'),
            ServicePartSerialNR: '',
            ServicePartQty: 1,
            ServicePartPrice: newPart.PartLastPrice,
            ServicePartCategory: newPart.PartCategory.replace('"', '\\"'),
            ServicePartNotes: '',
            ServicePartFlags: '',
        }

        serviceParts.push(newServiceItem)
        setArrServiceParts(serviceParts)

        console.log('arrServiceParts: ', arrServiceParts)

        console.log('call total')
        calcTotalService(serviceParts)
    }

    const updateServicePart = (i, newpriceobj) => {
        let serviceParts = [...arrServiceParts]

        serviceParts[i] = { ...serviceParts[i], ...newpriceobj }

        console.log(
            'updateServicePart: ',
            newpriceobj,
            ' arrServiceParts: ',
            arrServiceParts
        )

        setArrServiceParts(serviceParts)
        console.log('after arrServiceParts: ', arrServiceParts)
        calcTotalService(serviceParts)
    }

    const removeServicePart = (i) => {
        let serviceParts = [...arrServiceParts]
        serviceParts.splice(i, 1)
        setArrServiceParts(serviceParts)
        console.log('arrServiceParts: ', arrServiceParts)
        calcTotalService(serviceParts)
    }

    const calcTotalService = (partslist) => {
        let total = 0

        console.log('partslist: ', partslist)

        partslist.map(
            (part) => (total += part.ServicePartPrice * part.ServicePartQty)
        )

        console.log('total: ', total)
        setServiceTotal(total)
    }

    //#region  Form Validation
    const [boolServiceFormValidated, setServiceFormValidated] = useState(false)

    const valServiceRef = useRef()
    const valServiceDate = useRef()
    const valServiceTechnician = useRef()
    const valServiceTDSFeed = useRef()
    const valServiceTDSSystem = useRef()
    const valServiceFeedPressure = useRef()
    const valServiceTankPressure = useRef()

    const vaildateServiceForm = (clicked = false) => {
        console.log('editState: ', editState)
        if (!editState) return

        setServiceFormValidated(
            VaildateForm(
                [
                    {
                        ref: valServiceRef,
                        text: 'No Special Characters',
                        form: 'Edit Service valServiceRef',
                    },
                    {
                        ref: valServiceDate,
                        text: 'Invalid Date',
                        form: 'Edit Service valServiceDate',
                    },
                    {
                        ref: valServiceTechnician,
                        text: 'Invalid Technicia',
                        form: 'Edit Service valServiceSalesRep',
                    },
                    {
                        ref: valServiceTDSFeed,
                        text: 'Invalid Value',
                        form: 'Edit Service valServiceTDSFeed',
                    },
                    {
                        ref: valServiceTDSSystem,
                        text: 'Invalid Value',
                        form: 'Edit Service valServiceTDSSystem',
                    },
                    {
                        ref: valServiceFeedPressure,
                        text: 'Invalid Value',
                        form: 'Edit Service valServiceFeedPressure',
                    },
                    {
                        ref: valServiceTankPressure,
                        text: 'Invalid Value',
                        form: 'Edit Service valServiceTankPressure',
                    },
                ],
                clicked
            )
        )
    }

    useEffect(() => {
        //REdraw on validation change
        vaildateServiceForm()
        //Do validation on ROCombobox changes Service Rep ID
    }, [txtServiceTechID])
    //#endregion

    useEffect(() => {
        calcTotalService(jsonparts)

        return () => {
            //second
        }
    }, [])

    let arrParts = []
    if (parts) {
        arrParts = parts.map((part) => {
            return {
                Text:
                    '(' +
                    part.PartCode +
                    ') ' +
                    part.PartName +
                    ' - ' +
                    part.PartCategory,
                ID: part.PartID,
            }
        })
    }

    //Get State users / staff from redux
    const { franusers, isFUsersError, isFUsersSuccess, franusersmessage } =
        useSelector((state) => state.franusers)

    let arrSalesReps = []
    if (franusers) {
        arrSalesReps = new Set(
            franusers.map((reps) => {
                return { name: reps.name, ID: reps._id }
            })
        )
        arrSalesReps = Array.from(arrSalesReps)
        arrSalesReps = arrSalesReps.map((reps) => {
            return {
                Text: reps.name,
                ID: reps.ID,
            }
        })
    }

    const getRepName = (id) => {
        let retVal = arrSalesReps.find((rep) => {
            console.log('Rep id: ', rep, id)
            if (rep.ID === id) {
                console.log('Choosen Rep id: ', rep)
                return rep.Text
            }
        })

        console.log('getRepName: ', retVal)

        if (retVal) {
            return retVal.Text
        } else {
            return ''
        }
    }

    if (collapse && toggleState) {
        setToggleState(false)
        collapse = false
    }

    return (
        //bg-primary to "simulate" or create space between services child container.
        <ContentBox
            classTag={
                toggleState
                    ? `serviceBox content p-0 m-0 col-12 rounded-2  ${containerBackColor}`
                    : `serviceBox content p-0 m-0 ` +
                      (small ? 'col-4' : 'col-6') +
                      ` rounded-2 ${containerBackColor}`
            }
        >
            <div className='content  p-1 m-0'>
                {toggleState ? (
                    /*Toggled On/True EXPANDED State*/
                    /*Toggled On/True EXPANDED State*/
                    //#region Expanded

                    !editState ? (
                        <div
                            className={`container p-2 rounded-2 bg-servicewhite`}
                        >
                            <div
                                className='row' /*Service First Line Expanded*/
                            >
                                <div className='ROmaintxt col-2 m-0 p-0 ps-3 fs-5'>
                                    <div className='row'>
                                        <div className='col-2 m-0 p-0 ps-2'>
                                            <ROIcon
                                                icon='ROicoDate'
                                                size={24}
                                                className='icoSVG'
                                                tooltip='Update Equipment'
                                                onClickCallback={() =>
                                                    editService()
                                                }
                                            />
                                        </div>
                                        <div className='col-10 m-0 p-0'>
                                            {formatShortDate(
                                                Date.parse(service.ServiceDate)
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/*Interface Buttons*/}
                                <div className='col-auto me-auto m-0 p-0 '>
                                    <div>
                                        {editState ? (
                                            <>
                                                <ROIcon
                                                    icon='ROicoCheck'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Update Service'
                                                    onClickCallback={() =>
                                                        editService()
                                                    }
                                                    usefill='true'
                                                    color1='#ffffff'
                                                />
                                                <ROIcon
                                                    icon='ROicoClose'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Cancel'
                                                    onClickCallback={() =>
                                                        editService(false)
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <ROIcon
                                                icon='ROicoEdit'
                                                size={24}
                                                className='icoSVG'
                                                tooltip='Edit Service'
                                                onClickCallback={() =>
                                                    editService()
                                                }
                                            />
                                        )}

                                        {!editState ? (
                                            <>
                                                {confirmDelete ? (
                                                    <ROIcon
                                                        icon='ROicoDel'
                                                        size={24}
                                                        className='icoSVG danger'
                                                        tooltip='Delete Service'
                                                        onClickCallback={() =>
                                                            confirmDeleteService(
                                                                true
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    ' '
                                                )}
                                                {!confirmDelete ? (
                                                    <ROIcon
                                                        icon='ROicoDel'
                                                        size={24}
                                                        className='icoSVG'
                                                        tooltip='Delete Service'
                                                        authlvl={5}
                                                        onClickCallback={() =>
                                                            confirmDeleteService(
                                                                true
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {!editState ? (
                                            confirmDelete ? (
                                                <ROIcon
                                                    icon='ROicoClose'
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Cancel Delete'
                                                    onClickCallback={() =>
                                                        confirmDeleteService(
                                                            false
                                                        )
                                                    }
                                                />
                                            ) : (
                                                ''
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                {/*Toggle Icon*/}
                                <div className='col-auto px-2'>
                                    {/*Expande/Collapse Icon*/}
                                    {editState ? (
                                        ''
                                    ) : (
                                        <ROIcon
                                            icon='ROicoUp'
                                            className='icoSVG'
                                            onClickCallback={() =>
                                                setToggleState((prev) => !prev)
                                            }
                                        />
                                    )}
                                </div>
                            </div>

                            <div className='row'>
                                <div className='ROnormaltxt col-2 fs-5'>
                                    <ROIcon
                                        icon='ROicoFeed'
                                        className='icoSVG'
                                        tooltip='TDS Feed'
                                    />
                                    {service.ServiceTDSFeed} tds
                                </div>
                                <div className='ROnormaltxt col-2 fs-5'>
                                    <ROIcon
                                        icon='ROicoRO'
                                        className='icoSVG'
                                        tooltip='TDS System'
                                    />
                                    {service.ServiceTDSSystem} tds
                                </div>
                                <div className='ROnormaltxt col-2 fs-5'>
                                    <ROIcon
                                        icon='ROicoFPres'
                                        className='icoSVG'
                                        tooltip='Feed Pressure'
                                    />
                                    {service.ServiceFeedPressure} ba
                                </div>
                                <div className='ROnormaltxt col-2 fs-5'>
                                    <ROIcon
                                        icon='ROicoTpres'
                                        className='icoSVG'
                                        tooltip='Tank Pressure'
                                    />
                                    {service.ServiceTankPressure} ba
                                </div>
                                <div className='ROnormaltxt col-2 fs-5'>
                                    {service.ServiceControls === 1 ? (
                                        <ROIcon
                                            icon='ROicoCheckbox'
                                            className='icoSVG'
                                            tooltip='Controls'
                                        />
                                    ) : (
                                        <ROIcon
                                            icon='ROicoBox'
                                            className='icoSVG'
                                            tooltip='Controls'
                                        />
                                    )}
                                    Controls
                                </div>
                                <div className='ROnormaltxt col-2 fs-5'>
                                    {service.ServiceSuccessful === 1 ? (
                                        <>
                                            <ROIcon
                                                icon='ROicoCheck'
                                                className='icoSVG'
                                                tooltip='Successful'
                                            />
                                            Successful
                                        </>
                                    ) : (
                                        <>
                                            <ROIcon
                                                icon='ROicoClose'
                                                className='icoSVG'
                                                tooltip='Unsuccessful'
                                            />
                                            Unsuccessful
                                        </>
                                    )}
                                </div>
                            </div>
                            <div
                                className='row' /*Service Second Line Expanded*/
                            >
                                <div className='ROnormaltxt col-2 fs-5'>
                                    <ROIcon
                                        icon='ROicoUser'
                                        className='icoSVG'
                                        tooltip='Technician'
                                    />
                                    {service.Username}
                                </div>
                                <div className='ROnormaltxt col-3 fs-5'>
                                    <ROIcon
                                        icon='ROicoReport'
                                        className='icoSVG'
                                        tooltip='Service Reference'
                                    />
                                    {service.ServiceWorkRef}
                                </div>
                                <div className='ROnormaltxt col-7 fs-5'>
                                    <ROIcon
                                        icon='ROicoComments'
                                        className='icoSVG'
                                        tooltip='Service Note'
                                    />
                                    {service.ServiceNotes}
                                </div>
                            </div>

                            <hr className='services' />

                            <div className='row px-4' /*Service Parts List*/>
                                {jsonparts.map((part, i) => {
                                    console.log(part)
                                    return (
                                        <div
                                            className={
                                                (i + 1) % 2 === 0
                                                    ? 'row mx-2 bg-serviceswhite'
                                                    : 'row mx-2 bg-secondary'
                                            }
                                        >
                                            <div className='col-2 fs-5'>
                                                {part.ServicePartCode}
                                            </div>
                                            <div className='col-6 fs-6'>
                                                {part.ServicePartName}
                                            </div>
                                            <div className='col-2 fs-6'>
                                                {part.ServicePartQty}
                                            </div>
                                            <div className='col-1 fs-6'>
                                                R{part.ServicePartPrice}
                                            </div>
                                            <div className='col-1 fs-6'>
                                                R
                                                {part.ServicePartPrice *
                                                    part.ServicePartQty}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='row ' /*Service Parts List Total*/>
                                <div className='ROmaintxt col-10 fs-5 '></div>
                                <div className='ROmaintxt col-auto fs-5 '>
                                    Total: R{serviceTotal}
                                </div>
                            </div>
                        </div>
                    ) : (
                        /*EDIT Service */
                        /*EDIT Service */
                        /*EDIT Service */
                        <div className='container  text-white px-1 pt-0 rounded-2 bg-primary'>
                            <div className='row' /*Service Create or Add*/>
                                <div className='ROmaintxt col-12 fs-5'>
                                    <div className='container p-0 m-0 rounded-2'>
                                        Edit Service:
                                        <div className='row' /*Service Date:*/>
                                            <div className='ROnormaltxt col-2 m-0 p-0 ps-2 pt-1'>
                                                Service Date:
                                            </div>
                                            <div className='col-3 m-0 p-0'>
                                                <input
                                                    className='txtBox'
                                                    value={txtServiceDate}
                                                    type='date'
                                                    onChange={(e) => {
                                                        setTxtServiceDate(
                                                            e.target.value
                                                        )
                                                        vaildateServiceForm()
                                                    }}
                                                    ref={valServiceDate}
                                                    title='Service Date'
                                                    pattern='\d{4}-\d{2}-\d{2}'
                                                    required
                                                />
                                            </div>
                                            <div className='ROnormaltxt col-2 m-0 p-0 ps-2 pt-1'>
                                                Service Ref:
                                            </div>
                                            <div className='col-3'>
                                                <input
                                                    className='txtBox'
                                                    value={txtServiceRef}
                                                    placeholder='Service Reference'
                                                    onChange={(e) => {
                                                        setTxtServiceRef(
                                                            e.target.value
                                                        )
                                                        vaildateServiceForm()
                                                    }}
                                                    ref={valServiceRef}
                                                    title='Service Reference'
                                                    pattern='[a-zA-Z0-9]+'
                                                    required
                                                />
                                            </div>
                                            <div className='col-auto me-auto  m-0 p-0 px-0'>
                                                {' '}
                                                <>
                                                    {boolServiceFormValidated ? (
                                                        <ROIcon
                                                            icon='ROicoCheck'
                                                            size={24}
                                                            className='icoSVG'
                                                            tooltip='Update Service'
                                                            onClickCallback={() =>
                                                                editService()
                                                            }
                                                            usefill='true'
                                                            color1='#ffffff'
                                                        />
                                                    ) : (
                                                        <ROIcon
                                                            icon='ROicoCheck'
                                                            size={24}
                                                            className='icoSVG'
                                                            onClickCallback={() =>
                                                                vaildateServiceForm(
                                                                    true
                                                                )
                                                            }
                                                            usefill='true'
                                                            color1='#ff0000'
                                                        />
                                                    )}
                                                    <ROIcon
                                                        icon='ROicoClose'
                                                        size={24}
                                                        className='icoSVG'
                                                        tooltip='Cancel'
                                                        onClickCallback={() =>
                                                            editService(false)
                                                        }
                                                        usefill='true'
                                                        color1='#ffffff'
                                                    />
                                                </>
                                            </div>
                                        </div>
                                        <div
                                            className='row' /*Service Equip Info:*/
                                        >
                                            <div className='col-2 p-0 m-0 fs-6'>
                                                <input
                                                    className='txtBox'
                                                    value={txtTDSFeed}
                                                    placeholder='TDS Feed'
                                                    ref={valServiceTDSFeed}
                                                    title='TDS Feed'
                                                    pattern='[0-9]+'
                                                    onChange={(e) => {
                                                        setTxtTDSFeed(
                                                            e.target.value.replace(
                                                                /[^0-9]+/g,
                                                                ''
                                                            )
                                                        )
                                                        vaildateServiceForm()
                                                    }}
                                                    required
                                                />
                                            </div>
                                            <div className='col-2 p-0 ps-1 m-0 fs-6'>
                                                <input
                                                    className='txtBox'
                                                    value={txtTDSSystem}
                                                    placeholder='TDS System'
                                                    ref={valServiceTDSSystem}
                                                    title='TDS System'
                                                    pattern='[0-9]+'
                                                    onChange={(e) => {
                                                        setTxtTDSSystem(
                                                            e.target.value.replace(
                                                                /[^0-9]+/g,
                                                                ''
                                                            )
                                                        )
                                                        vaildateServiceForm()
                                                    }}
                                                    required
                                                />
                                            </div>
                                            <div className='col-2 p-0 ps-1 m-0 fs-6'>
                                                <input
                                                    className='txtBox'
                                                    value={txtFeedPressure}
                                                    placeholder='Feed Pressure'
                                                    ref={valServiceFeedPressure}
                                                    title='Feed Pressure'
                                                    pattern='^[0-9]\d*(\.\d+)?$'
                                                    onChange={(e) => {
                                                        setTxtFeedPressure(
                                                            e.target.value.replace(
                                                                /[^0-9\.]+/g,
                                                                ''
                                                            )
                                                        )
                                                        vaildateServiceForm()
                                                    }}
                                                    required
                                                />
                                            </div>
                                            <div className='col-2 p-0 ps-1 m-0 fs-6'>
                                                <input
                                                    className='txtBox'
                                                    value={txtTankPressure}
                                                    placeholder='Tank Pressure'
                                                    ref={valServiceTankPressure}
                                                    title='Tank Pressure'
                                                    pattern='^[0-9]\d*(\.\d+)?$'
                                                    onChange={(e) => {
                                                        setTxtTankPressure(
                                                            e.target.value.replace(
                                                                /[^0-9\.]+/g,
                                                                ''
                                                            )
                                                        )
                                                        vaildateServiceForm()
                                                    }}
                                                    required
                                                />
                                            </div>

                                            {/* <ROIcon CheckBox /> */}
                                            <div className='col-2 p-0 ps-2 m-0 fs-6'>
                                                Controls
                                                <ROIcon
                                                    icon={
                                                        boolControls
                                                            ? 'ROicoCheckbox'
                                                            : 'ROicoBox'
                                                    }
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Controls Tested'
                                                    usefill='true'
                                                    color1='#ffffff'
                                                    onClickCallback={() =>
                                                        setBoolControls(
                                                            (prev) => !prev
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className='col-2 p-0 ps-2 m-0 fs-6'>
                                                Successfull
                                                <ROIcon
                                                    icon={
                                                        boolSuccessful
                                                            ? 'ROicoCheckbox'
                                                            : 'ROicoBox'
                                                    }
                                                    size={24}
                                                    className='icoSVG'
                                                    tooltip='Service Successful'
                                                    usefill='true'
                                                    color1='#ffffff'
                                                    onClickCallback={() =>
                                                        setBoolSuccessful(
                                                            (prev) => !prev
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {/* Tech Rep ID */}
                                        <div className='row'>
                                            <div className='ROnormaltxt col-auto m-0 p-0 fs-5 me-auto '>
                                                Technician:
                                                <ROComboBox
                                                    dataArray={arrSalesReps}
                                                    placeholdertext={
                                                        'Search Technician'
                                                    }
                                                    textVal={getRepName(
                                                        txtServiceTechID
                                                    )}
                                                    myIDCallback={
                                                        setTxtServiceTechID
                                                    }
                                                    ref={valServiceTechnician}
                                                    required={true}
                                                    validationCallBack={() =>
                                                        vaildateServiceForm()
                                                    }
                                                    allowCustomValue={false}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className='row' /*Service User + Comments*/
                                        >
                                            <div className='col-12 p-0 ps-0 m-0 fs-6'>
                                                <input
                                                    className='txtBox'
                                                    value={txtComments}
                                                    placeholder='Service Comments'
                                                    onChange={(e) =>
                                                        setTxtComments(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className='row' /*Service Parts / Items*/
                                        >
                                            {parts ? (
                                                <div className='col-12 p-0 ps-0 m-0 fs-6'>
                                                    <ROComboBox
                                                        dataArray={arrParts}
                                                        classname='ROdropdownFull'
                                                        placeholdertext='Search Parts to Add'
                                                        myIDCallback={addPart}
                                                        clearTextOnSelect={true}
                                                        allowCustomValue={false}
                                                    />
                                                </div>
                                            ) : (
                                                'Loading Parts'
                                            )}

                                            {/* New Service Parts / Items List */}
                                            {arrServiceParts.map((part, i) => (
                                                <>
                                                    <div className='row'>
                                                        <div className='col-2 p-0 m-0'>
                                                            {
                                                                part.ServicePartCode
                                                            }
                                                            {/* + '(' +
                                                            part.ServicePartID +
                                                            ')' */}
                                                        </div>
                                                        <div className='col-3 p-0 m-0'>
                                                            {
                                                                part.ServicePartName
                                                            }
                                                        </div>
                                                        <div className='col-2 p-0 m-0'>
                                                            {' '}
                                                            {/* SERIAL NR */}
                                                            <input
                                                                className='txtBox'
                                                                value={
                                                                    part.ServicePartSerialNR ||
                                                                    ''
                                                                }
                                                                placeholder='Serial Nr'
                                                                onChange={(e) =>
                                                                    updateServicePart(
                                                                        i,
                                                                        {
                                                                            ServicePartSerialNR:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className='col-1 p-0 m-0 ms-1'>
                                                            {' '}
                                                            {/* QTY */}
                                                            <input
                                                                className='txtBox'
                                                                value={
                                                                    part.ServicePartQty ||
                                                                    ''
                                                                }
                                                                placeholder='Qty'
                                                                onChange={(e) =>
                                                                    updateServicePart(
                                                                        i,
                                                                        {
                                                                            ServicePartQty:
                                                                                parseInt(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                ),
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className='col-1 p-0 m-0 ms-1'>
                                                            {/* Price */}
                                                            <input
                                                                className='txtBox'
                                                                value={
                                                                    part.ServicePartPrice ||
                                                                    ''
                                                                }
                                                                placeholder='Price'
                                                                onChange={(e) =>
                                                                    updateServicePart(
                                                                        i,
                                                                        {
                                                                            ServicePartPrice:
                                                                                parseInt(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                ),
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className='col-1 p-0 m-0 ms-2 pt-1'>
                                                            R
                                                            {part.ServicePartPrice *
                                                                part.ServicePartQty ||
                                                                0}
                                                        </div>
                                                        <div className='col-1 p-0 m-0 ms-2 pt-1'>
                                                            <ROIcon
                                                                icon='ROicoDel'
                                                                size={24}
                                                                className='icoSVG'
                                                                tooltip='Remove'
                                                                onClickCallback={() =>
                                                                    removeServicePart(
                                                                        i
                                                                    )
                                                                }
                                                                usefill='true'
                                                                color1='#f00'
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                        <div className='row'>
                                            <div className='col-10 p-0 m-0'>
                                                &nbsp;
                                            </div>
                                            <div className='col-2 p-0 m-0'>
                                                Total: R{serviceTotal}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    //#endregion
                    /*Toggled Off/False COMPACT State */
                    /*Toggled Off/False COMPACT State*/
                    //#region Compact
                    <div
                        className={`container p-2 pt-0 rounded-2 bg-servicewhite`}
                        onClick={() => setToggleState((prev) => !prev)}
                    >
                        <div className='row' /*Compact*/>
                            <div
                                className={
                                    (small ? '' : 'ROmaintxt') + ' col-5  fs-6'
                                }
                            >
                                <ROIcon icon='ROicoDate' className='icoSVG' />
                                {formatShortDate(
                                    Date.parse(service.ServiceDate)
                                )}
                                {service.ServiceSuccessful === 1 ? (
                                    <ROIcon
                                        icon='ROicoCheck'
                                        className='icoSVG'
                                    />
                                ) : (
                                    <ROIcon
                                        icon='ROicoClose'
                                        className='icoSVG'
                                    />
                                )}
                            </div>
                            <div className='ROnormaltxt col-3 fs-5'>
                                <ROIcon icon='ROicoFeed' className='icoSVG' />
                                {service.ServiceTDSFeed}
                            </div>
                            <div className='ROnormaltxt col-3 fs-5'>
                                <ROIcon icon='ROicoRO' className='icoSVG' />
                                {service.ServiceTDSSystem}
                            </div>
                            {/*Toggle Icon*/}
                            <div className='col-auto px-0'>
                                {/*Expande/Collapse Icon*/}
                                <ROIcon icon='ROicoDown' className='icoSVG' />
                            </div>
                        </div>
                        <div className='row small' /*Compact*/>
                            <div className='col-auto'>
                                {partsListStr}
                                <span className='fw-bold'>
                                    &nbsp;[<sup>R</sup> {serviceTotal + ']'}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ContentBox>
    )
}
export default ServiceItem
