import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import usersService from './usersService'

const initialState = {
    franusers: [],
    isFUsersError: false,
    isFUsersSuccess: false,
    isFUsersLoading: false,
    franusersmessage: '',
}

// Create New User
export const createUser = createAsyncThunk(
    'user/create',
    async (userData, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token

            console.log('createUser(usersSlice) userData: ', userData)

            return await usersService.createUser(userData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update User
export const updateUser = createAsyncThunk(
    'user/update',
    async (userData, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token
            return await usersService.updateUser(userData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Get fran users
export const getUsers = createAsyncThunk('user/getAll', async (_, thunkAPI) => {
    try {
        let userCred = ''

        console.log('Get All Franchise users')

        if (thunkAPI.getState() != null) {
            userCred = thunkAPI.getState().auth.user
        }

        if (userCred.token) {
            const returnData = await usersService.getUsers(userCred)
            return returnData
        }

        return thunkAPI.rejectWithValue('No user token found')
    } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        console.log('thunkAPI GetUsers error: ', message, error)
        return thunkAPI.rejectWithValue(message)
    }
})

// Delete user
//Do not permanently delete user just flag as suspended
export const deleteUser = createAsyncThunk(
    'user/delete',
    async (id, thunkAPI) => {
        try {
            //Send payload by thunkAPI->extraReducers(builder->register(user))
            const token = thunkAPI.getState().auth.user.token
            return await usersService.deleteUser(id, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            //Send reject payload by thunkAPI->extraReducers(builder->register(message))
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        resetusers: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(createUser.pending, (state) => {
                state.isFUsersSuccess = false
                state.isFUsersLoading = true
                state.isFUsersError = false
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.isFUsersLoading = false
                state.isFUsersSuccess = true
                state.isFUsersError = false
                if (!state.franusers) {
                    state.franusers = []
                }
                console.log(
                    'userSlice createUser action.payload: ',
                    action.payload
                )
                state.franusers.push(action.payload)
            })
            .addCase(createUser.rejected, (state, action) => {
                state.isFUsersLoading = false
                state.isFUsersError = true
                console.log(
                    'userSlice createUser rejected franusersmessage action.payload: ',
                    action.payload
                )
                state.franusersmessage = action.payload
            })
            .addCase(updateUser.pending, (state) => {
                state.isFUsersError = false
                state.isFUsersSuccess = false
                state.isFUsersLoading = true
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.isFUsersLoading = false
                state.isFUsersSuccess = true
                state.isFUsersError = false
                state.franusers = state.franusers.map((user) => {
                    if (user._id === action.payload._id) {
                        return action.payload
                    } else {
                        return user
                    }
                })
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.isFUsersLoading = false
                state.isFUsersError = true
                //This is what redux helps to do. Change immutable values
                console.log('updateUser(usersSlice) error!!', action.payload)
                state.franusersmessage = action.payload
            })
            .addCase(getUsers.pending, (state) => {
                state.isFUsersLoading = true
                state.isFUsersError = false
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.isFUsersLoading = false
                state.isFUsersSuccess = true
                state.isFUsersError = false
                //This is what redux helps to do. Change immutable values
                state.franusers = action.payload
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.isFUsersLoading = false
                state.isFUsersError = true
                //This is what redux helps to do. Change immutable values
                state.franusersmessage = action.payload
            })
            .addCase(deleteUser.pending, (state) => {
                state.isFUsersSuccess = false
                state.isFUsersLoading = true
                state.isFUsersError = false
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.isFUsersLoading = false
                state.isFUsersSuccess = true
                state.isFUsersError = false
                console.log(
                    'usersSlice.js DeleteUser action.payload: ',
                    action.payload
                )
                state.franusers = state.franusers.filter(
                    (user) => user._id !== action.payload.id
                )
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.isFUsersLoading = false
                state.isFUsersError = true
                //This is what redux helps to do. Change immutable values
                state.franusersmessage = action.payload
            })
    },
})

export const { resetusers } = usersSlice.actions
export default usersSlice.reducer
